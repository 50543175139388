import React, { Component } from "react";
import OverlayPanel from "../overlay_panel/OverlayPanel";
import { connect } from "react-redux";
import { updateLegendUrls } from "../../map_component/data/actions";
import getLayerTree from "../../helper/LayerTreeLoader";
import "./OpacityWidget.css";

class OpacityControl extends Component {
  state = {
    visibleImageUrls: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showOpacityControl !== this.props.showOpacityControl) {
      this.setState({
        visibleImageUrls: this.getLegendUrls(),
        showOpacityControl: this.props.showOpacityControl,
      });
    }
  }
  getLegendUrls = () => {
    const { mapLayers } = this.props;
    let layerTree = getLayerTree();
    let urls = [];
    for (let i = 0; i < mapLayers.length; i++) {
      if (mapLayers[i].getVisible() && mapLayers[i].type == "TILE") {
        let layer = mapLayers[i].getSource().getParams().LAYERS;
        let layerName = "";
        let layerNameValue = mapLayers[i].getProperties().name;
        layerTree.map((treeItem) => {
          if (treeItem.hasOwnProperty("children")) {
            treeItem.children.forEach((layer) => {
              if (layer.value == layerNameValue) {
                layerName = layer.label;
              }
            });
          }
        });
        var element = {};
        element.layerName = layerName;
        element.layer = layerNameValue;
        urls.push(element);
      }
    }
    this.props.updateLegendUrls(urls);
    return urls;
  };
  setOpacity = (e) => {
    const { mapLayers } = this.props;
    let layerTree = getLayerTree();
    let val = e.target.value / 10;
    for (let i = 0; i < mapLayers.length; i++) {
      let layer = mapLayers[i].getProperties().name;
      if (e.target.name == layer) {
        //console.log(e.target.name, layer);
        mapLayers[i].setOpacity(val);
      }
    }
  };
  render() {
    return (
      <OverlayPanel
        showControl={this.state.showOpacityControl}
        handleShowControl={this.props.handleShowControl}
        titleName="Opacity"
      >
        {this.state.visibleImageUrls.length === 0 && (
          <h5>There are no Layers selected</h5>
        )}

        {this.state.visibleImageUrls.map((image, index) => (
          <React.Fragment key={index}>
            <h5 className="layerName" style={{ width: "100" }}>
              <b>{image.layerName}</b>
            </h5>
            <br />
            <div class="range">
              <input
                type="range"
                className="range"
                name={image.layer}
                min="0"
                max="10"
                onChange={this.setOpacity}
              ></input>
            </div>
          </React.Fragment>
        ))}
        <br />
      </OverlayPanel>
    );
  }
}
function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateLegendUrls: (urls) => dispatch(updateLegendUrls(urls)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OpacityControl);
