import {
  UPDATE_MAP_REFERENCE,
  UPDATE_LEGEND_URLS,
  SET_WIDGET_ENABLE_DISABLE,
} from "./constants";

export const updateMapReference = (OlMap) => {

  return (dispatch) => {
    dispatch(updateMapReference(OlMap));
  };
  function updateMapReference(OlMap) {
    return { type: UPDATE_MAP_REFERENCE, OlMap };
  }
};

export const updateLegendUrls = (urls) => {
  return (dispatch) => {
    dispatch(updateLegendUrls(urls));
  };
  function updateLegendUrls(urls) {
    return { type: UPDATE_LEGEND_URLS, urls };
  }
};

export const updateWidgetEnable = (showWidget) => {
  return (dispatch) => {
    dispatch(updateWidgetEnable(showWidget));
  };
  function updateWidgetEnable(showWidget) {
    return { type: SET_WIDGET_ENABLE_DISABLE, showWidget };
  }
};
