import APIRequest from "../util/api/APIRequest";

const getDropdownData = url => {
  return APIRequest({
    url: url,
    method: "GET",
    params: {
      page: 1,
      limit: 1000
    }
  });
};

const analysisDropdown = (data) =>{
  return APIRequest({
    url: "analysis/get_distinct_data/",
    method: "POST",
    data : data
  })
}

const DropdownService = {
  getDropdownData,
  analysisDropdown
};
export default DropdownService;
