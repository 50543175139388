import React, { Component } from "react";
import { NavItem, Image, OverlayTrigger, Tooltip } from "react-bootstrap";

class MenuItemWrapper extends Component {
  render() {
    return (
      <NavItem eventKey={2}>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="menu">{this.props.tooltipText}</Tooltip>}
        >
          <Image
            src={this.props.img}
            onClick={this.props.handleClick}
            className="navIcons"
          />
        </OverlayTrigger>
      </NavItem>
    );
  }
}

export default MenuItemWrapper;
