import React, { Component } from "react";
import TableFormat from "./TableFormat";
class FloorType extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props;
    
    return (
      <TableFormat displayName="Floor Type">
        <tbody className="tablebody">
          {data.map((item, key) => {
            return (
              <React.Fragment>
                <tr key={key}>
                  <td style={{ width: "300px" }}>Floor Type</td>
                  <td style={{ width: "300px" }}>
                    {item["Floor Type"] ? item["Floor Type"] : ""}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </TableFormat>
    );
  }
}
export default FloorType;
