import React, { Component } from "react";
import DataFormat from "../../helper/DataFormat";
import TableFormat from "./TableFormat";
class PropertyArea extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props;
    const styleObj = {
      width: 400,
      textAlign: "center",
    };
    return (
      <TableFormat displayName="Property Area">
        {data.map((obj) => {
          return (
            <React.Fragment>
              <thead>
                <tr key={obj}>
                  <th colSpan="2" style={styleObj} bgcolor="#d1473d">
                    {obj["Floor Category"]}
                  </th>
                </tr>
              </thead>
              <tbody className="tablebody">
                {Object.keys(obj).map((value) => {
                  return (
                    <>
                      {!(
                        DataFormat.dataFormatOr(obj[value]) ||
                        value === "Floor Category"
                      ) && (
                        <tr>
                          <td style={{ width: "300px" }}>{value}</td>
                          <td
                            style={{
                              width: "300px",
                            }}
                          >
                            {DataFormat.dataFormat(obj[value])}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
              <br />
            </React.Fragment>
          );
        })}
      </TableFormat>
    );
  }
}
export default PropertyArea;
