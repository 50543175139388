export const IS_CONFIG_LOADING = "IS_CONFIG_LOADING";
export const CONFIG_LOADING_COMPLETE = "CONFIG_LOADING_COMPLETE";
export const GET_DASHBOARD_SUMMARY_SUCCESS = "GET_DASHBOARD_SUMMARY_SUCCESS";
export const GET_DASHBOARD_SUMMARY_FAILED = "GET_DASHBOARD_SUMMARY_FAILED";
// export const GET_DROPDOWN_DATA_SUCCESS = "GET_DROPDOWN_DATA_SUCCESS"; 
// export const GET_DROPDOWN_DATA_FAILED = "GET_DROPDOWN_DATA_FAILED";
export const FETCH_ROADLIST_REQUEST = 'FETCH_ROADLIST_REQUEST';
export const FETCH_ROADLIST_SUCCESS = 'FETCH_ROADLIST_SUCCESS';
export const FETCH_ROADLIST_FAILURE = 'FETCH_ROADLIST_FAILURE';

export const GET_WARDS_SUCCESS = "GET_WARDS_SUCCESS";
export const GET_WARDS_FAILED = "GET_WARDS_FAILED";
export const GET_DASHBOARD_FILTERS_SUCCESS = "GET_DASHBOARD_FILTERS_SUCCESS";
export const GET_DASHBOARD_FILTERS_FAILED = "GET_DASHBOARD_FILTERS_FAILED";
export const GET_MEMBER_DETAILS_SUCCESS = "GET_MEMBER_DETAILS_SUCCESS";
export const GET_MEMBER_DETAILS_SUCCESS_INIT_ACTION = "GET_MEMBER_DETAILS_SUCCESS_INIT_ACTION";
export const GET_MEMBER_DETAILS_FAILED = "GET_MEMBER_DETAILS_FAILED";
export const GET_JOB_FILTER_SUCCESS = "GET_JOB_FILTER_SUCCESS";
export const GET_JOB_FILTER_FAILED = "GET_JOB_FILTER_FAILED";
export const GET_EDUCATION_FILTER_SUCCESS = "GET_EDUCATION_FILTER_SUCCESS";
export const GET_EDUCATION_FILTER_FAILED = "GET_EDUCATION_FILTER_FAILED";

export const GET_ANALISIS_FILTER_SUCCESS = "GET_ANALISIS_FILTER_SUCCESS";
export const GET_ANALISIS_FILTER_FAILED = "GET_ANALISIS_FILTER_FAILED";
export const ACCESSIBLE_LAYER_REQUEST="ACCESSIBLE_LAYER_REQUEST";

export const MEMBER_DETAILS_DOWNLOAD_INIT_ACTION =
  "MEMBER_DETAILS_DOWNLOAD_INIT_ACTION";
export const MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_SUCEESS =
  MEMBER_DETAILS_DOWNLOAD_INIT_ACTION + "_SUCCESS";
export const MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_FAIL =
  MEMBER_DETAILS_DOWNLOAD_INIT_ACTION + "_FAIL";
  