import React, { Component } from "react";
import { Provider } from "react-redux";
import store, { history } from "../store/";
import AppRoutes from "./routes/AppRoutes";
import { ConnectedRouter } from "connected-react-router";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppRoutes />
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
