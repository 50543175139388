import {
  UPDATE_MAP_REFERENCE,
  SET_LAYERS_FROM_TREE,
  UPDATE_LEGEND_URLS,
  SET_WIDGET_ENABLE_DISABLE,
} from "./constants";
const initialState = {
  OlMap: null,
  mapLayers: [],
  legendUrls: [],
  showWidget: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_MAP_REFERENCE:
      return {
        ...state,
        OlMap: action.OlMap,
      };
    case SET_LAYERS_FROM_TREE:
      return {
        ...state,
        mapLayers: action.mapLayers,
      };
    case UPDATE_LEGEND_URLS:
      return {
        ...state,
        legendUrls: action.urls,
      };
    case SET_WIDGET_ENABLE_DISABLE:
      return {
        ...state,
        showWidget: action.showWidget,
      };

    default:
      return state;
  }
}
