import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "./constants";

export const snackbarActions = {
  showSnackbar,
  hideSnackbar,
};

function showSnackbar(snackbarMessage) {
  return { type: SHOW_SNACKBAR, snackbarMessage };
}
function hideSnackbar() {
  return { type: HIDE_SNACKBAR };
}
