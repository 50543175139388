import React, { Component } from "react";
import "./ExportPdf.css";
import ExportPdfPanel from "./ExportPdfPanel";
import { connect } from "react-redux";
import { updateWidgetEnable } from "../../map_component/data/actions";
import exportPdf from "../../../../../asset/svg/print_map_red.svg";
import Widget from "../../../../../containers/widget/widget";
class ExportPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showExportPanelControl: false,
    };
  }
  handleShowExportPanelControl = () => {
    this.setState({
      showExportPanelControl: !this.state.showExportPanelControl,
    });
    this.props.updateWidgetEnable("3");
  };

  render() {
    return (
      <React.Fragment>
        <Widget
          placement="right"
          tooltipText="Print Map"
          handleClick={this.handleShowExportPanelControl}
          img={exportPdf}
          class="extendButtonExport"
        />
        <ExportPdfPanel
          mapLayers={this.props.mapLayers}
          showExportPanelControl={this.state.showExportPanelControl}
          handleShowExportPanelControl={this.handleShowExportPanelControl}
          renderEvent={this.props.renderEvent}
          setZoom={this.props.setZoom}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (enable) => dispatch(updateWidgetEnable(enable)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ExportPdf);
