import React, { Component } from "react";
import {
  Collapse,
  Row,
  Col,
  Panel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Carousel,
  Modal,
  Badge,
  Label,
  ProgressBar,
} from "react-bootstrap";
import "./DetailedInfo.css";
import LocalStorageHandler from "../../../../../util/storage";
import closeButton from "../../../../../asset/svg/close_icon.svg";
import "jspdf-autotable";
import MemberDetails from "./MemberDetails";
import RelatedTables from "./RelatedTables";
import PropertyArea from "./PropertyArea";
import FloorType from "./FloorType";
import RoofType from "./RoofType";
import { ADMIN_URL } from "../../../../../../src/config";
import PdfReport from "./PdfRepoert";
import DataFormat from "../../helper/DataFormat";
import TableFormat from "./TableFormat";

class DetailedInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageName: null,
      show: false,
      showpdf: false,
      img: null,
      key: null,
      pdf_mgmt: false,
      pdf_doc: false,
      pdffile: null,
      total_count: 0,
      pdf_doc_file: null,
      pdf_mgmt_file: null,
      image_status: true,
      image_class: "img-responsive center-block",
      memberDetailStatus: false,
      basicDetailStatus: false,
    };
    
    this.displayImage = this.displayImage.bind(this);
    this.displayPdf = this.displayPdf.bind(this);
  }
  

  componentDidUpdate(prevProps) {
    if (
      this.props.detailedInfoContents !== null &&
      this.props.detailedInfoContents !== prevProps.detailedInfoContents
    ) {
      this.setUpPdfdoc();
      this.setUpPdfMgmt();
    }
  }
  
  setUpPdfdoc = () => {
    let pdf = null;
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];

      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key, index) => {
            if (key.includes("p_d_f_doc")) {
              this.setState({
                pdf_doc: true,
              });

              if (key == "p_d_f_doc") {
                this.setState({
                  pdf_doc_file: detailedInfoContent[key].value,
                });
              } else {
                this.setState({
                  pdf_doc_file: "filenotfound.pdf",
                });
              }
            }
          });
        }
      } catch (e) {
        console.log("Exception", e);
      }
    }
  };

  setUpPdfMgmt = () => {
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];
      let pdf = null;
      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key) => {
            if (key.includes("p_d_f_mgmt")) {
              this.setState({
                pdf_mgmt: true,
              });
              if (key == "p_d_f_mgmt") {
                this.setState({
                  pdf_mgmt_file: detailedInfoContent[key].value,
                });
              } else {
                this.setState({
                  pdf_mgmt_file: "filenotfound.pdf",
                });
              }
            }
          });
          return pdf;
        }
      } catch (e) {
        console.log("Exception", e);
      }
    }
  };
  displayImage() {
    this.setState({
      show: !this.state.show,
    });
  }
  rotateImage() {
    if (this.state.image_status) {
      this.setState({
        image_class: "img-responsive center-block image-rotate",
        image_status: !this.state.image_status,
      });
    } else {
      this.setState({
        image_class: "img-responsive center-block ",
        image_status: !this.state.image_status,
      });
    }
  }
  displayPdf(param, e) {
    this.setState({
      pdffile: param,
      showpdf: !this.state.showpdf,
    });
  }

  showUpdationStatus = () => {
    const { layerParent, status } = this.props;
    if (
      layerParent.includes("waterbody_pond") ||
      layerParent.includes("buildingasset_property")
    )
     {
      return (
        <>
          <tr>
            <td>Updation  status</td>
            <td>{DataFormat.updationStatus(status)}</td>
          </tr>
        </>
      );
      }
  };

  formatInfo() {
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];
      if (detailedInfoContent != undefined)
        return Object.keys(detailedInfoContent).map((key) => {
          if (key === "updation_status") {
            return (
              <>
                <tr key={key}>
                  <td style={{ width: "350px" }}>
                    {detailedInfoContent[key].display_name}
                  </td>
                  <td style={{ width: "350px" }}>
                    {DataFormat.updationStatus(detailedInfoContent[key].value)}
                  </td>
                </tr>
              </>
            );
          }
          if (
            !key.includes("photo") &&
            !key.includes("p_d_f_") &&
            !DataFormat.dataFormatOr(detailedInfoContent[key].value)
          ) {
            return (
              <tr key={key}>
                <td style={{ width: "300px" }}>
                  {detailedInfoContent[key].display_name}
                </td>
                <td style={{ width: "300px" }}>
                  {DataFormat.dataFormat(detailedInfoContent[key].value)}
                </td>
              </tr>
            );
          }
        });
    }
  }

  formatInfoForeign() {
    if (this.props.detailedInfoContentsForeignKey != null) {
      const detailedInfoContentForeignKey = this.props
        .detailedInfoContentsForeignKey[this.props.count];
      if (detailedInfoContentForeignKey != undefined)
        return Object.keys(detailedInfoContentForeignKey.foreignKeys).map(
          (key) => {
            // console.log('keys',key);
            if (
              !key.includes("photo") &&
              !key.includes("p_d_f_") &&
              !DataFormat.dataFormatOr(
                detailedInfoContentForeignKey.foreignKeys[key].actual_value
              )
            ) {
              return (
                <tr key={key}>
                  <td>
                    {detailedInfoContentForeignKey.foreignKeys[key].display_name
                      ? detailedInfoContentForeignKey.foreignKeys[key]
                          .display_name
                      : detailedInfoContentForeignKey.foreignKeys[key].field}
                  </td>
                  <td>
                    {DataFormat.dataFormat(
                      detailedInfoContentForeignKey.foreignKeys[key]
                        .actual_value
                    )}
                  </td>
                </tr>
              );
            }
          }
        );
    }
  }

  renderProgres() {
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];
      let prog = [];
      var element = {};
      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key) => {
            if (key.includes("c_h_p_")) {
              element = {};
              element.name = detailedInfoContent[key].display_name
                ? detailedInfoContent[key].display_name
                : detailedInfoContent[key].field;
              element.per = detailedInfoContent[key].value;
              prog.push(element);
            }
          });
        }
        return prog;
      } catch (e) {
        console.log("Exception", e);
      }
    }
  }

  renderImage = () => {
    let imageName = [];
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];
      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key) => {
            // console.log('image key',key);
            if (key.includes("photo") && detailedInfoContent[key].value != null)
              imageName.push(
                detailedInfoContent[key].value.replace("JPG", "jpg")
              );
          });
        }
        return imageName;
        
      } catch (e) {
        console.log("Exception", e);
      }
    }
  };

  render() {
    const itemsForeign = this.formatInfoForeign();
    const images = this.renderImage();
    const prog = this.renderProgres();
    const image_class = this.state.image_class;
    const img_url = LocalStorageHandler.getLocalBody().image_url;
  
    const { selectedLayer } = this.props;
    var layerName = "";
    if (selectedLayer) {
      layerName = selectedLayer.getProperties().name;
    }
    var layer_id =
      this.props.detailedInfoContentsForeignKey &&
      this.props.detailedInfoContentsForeignKey[this.props.count].id;

      
var table_Datas = []
if (
  this.props.detailedInfoContentsForeignKey &&
  this.props.count !== undefined && 
  this.props.detailedInfoContentsForeignKey[this.props.count] &&
  this.props.detailedInfoContentsForeignKey[this.props.count].related_tables
) {
  table_Datas = this.props.detailedInfoContentsForeignKey[this.props.count].related_tables;
} else {
  console.error('Invalid data or count value. Cannot access related_tables.');
}  
// console.log('table_Datas',table_Datas);
            
// const combinedItem = {
//     table_name: 'buildingasset_property',
//     related_table: 'combined',
//     index:null,
//     display_name: '',
//     fields: [],
//   };

  // for (const item of table_Datas)
  // {
  //   if(item.display_name.includes("Roof type") || item.display_name.includes("Floor type"))
  //   {
  //     combinedItem.display_name = "Form 6 Details"
  //   combinedItem.fields = combinedItem.fields.concat(item.fields);
  //   combinedItem.index = 6;
  //   }
  // }

  // if (combinedItem.display_name = "Form 6 Details") {
  //   table_Datas = table_Datas.filter((item) => !item.display_name.includes("Roof type") && !item.display_name.includes("Floor type"));
  //   table_Datas.push(combinedItem)
  // }

  var sortedData = []
  if (
      this.props.detailedInfoContentsForeignKey &&
      this.props.count !== undefined && 
      this.props.detailedInfoContentsForeignKey[this.props.count] &&
      this.props.detailedInfoContentsForeignKey[this.props.count].related_tables
    ) {
      sortedData = table_Datas.sort((a,b)=>a.index - b.index);
    }

    // console.log('proprssdasd',this.props);

    return (
      <React.Fragment>
        <Collapse
          in={this.props.isDetailedInfoVisible}
          className="featureInfoPanel"
        >
          <Panel bsStyle="success" className="detailedInfoPanel">
            <Panel.Heading>
              <Panel.Title>
                <Label className="padding" bsStyle="primary">
                  Total Features
                </Label>{" "}
                <Badge>{this.props.total_count + 1}</Badge>
              </Panel.Title>

              <ButtonToolbar className="pull-right button">
                <ButtonGroup>
                  <Button
                    onClick={this.props.onCountDecrement}
                    className="topbutton"
                    disabled={this.props.count == 0}
                  >
                    <i className="fa fa-angle-left" />
                  </Button>
                  <Button className="topbutton infobutton">
                    {this.props.count + 1}
                  </Button>
                  <Button
                    className="topbutton"
                    disabled={this.props.count == this.props.total_count}
                    onClick={this.props.onCountIncrement}
                  >
                    <i className="fa fa-angle-right" />
                  </Button>
                  <button
                    className="dpanelheadbtn"
                    onClick={this.props.onDetailedInfoClose}
                  >
                    <img src={closeButton} />
                  </button>
                </ButtonGroup>
              </ButtonToolbar>
            </Panel.Heading>
            <Modal
              className="imageModel"
              show={this.state.show}
              bsSize="large"
              aria-labelledby="contained-modal-title-lg"
              onHide={this.displayImage}
            >
              <Modal.Header className="imageModelHeader">
                <Modal.Title id="contained-modal-title-lg">
                  Photographs
                  <ButtonToolbar className="pull-right button">
                    <img
                      src={closeButton}
                      className="photoheadbtn"
                      onClick={this.displayImage}
                    />
                  </ButtonToolbar>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="imageModelBody">
                {this.props.detailedInfoContents != null && (
                  <div className="imageContainerModel">
                    {images != undefined &&
                      images.length > 0 &&
                      img_url != undefined && (
                        <Carousel>
                          {images.map(function(image, index) {
                            return (
                              <Carousel.Item key={index}>
                                <img
                                  className={image_class}
                                  key={index}
                                  src={img_url.concat(image)}
                                  width={750}
                                  height={750}
                                  alt="350x350"
                                />
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      )}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className="imageModelFooter">
                <Button bsStyle="primary" onClick={() => this.rotateImage()}>
                  Rotate
                </Button>
                <Button bsStyle="primary" onClick={this.displayImage}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              className="pdfModel"
              show={this.state.showpdf}
              bsSize="large"
              aria-labelledby="contained-modal-title-lg"
            >
              <Modal.Header className="pdfModelHeader">
                <Modal.Title id="contained-modal-title-lg">
                  PDF VIEWER 
                  <ButtonToolbar className="pull-right button">
                    <Button bsStyle="primary" onClick={this.displayPdf}>
                      <i className="fa fa-times" />
                    </Button>
                  </ButtonToolbar>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pdfModelBody">
                {this.props.detailedInfoContents != null &&
                  this.state.showpdf &&
                  img_url != undefined && (
                    <div className="pdfContainerModel">
                      <iframe
                        className="pdf_iframe"
                        src={img_url.concat("pdf/" + this.state.pdffile)}
                        frameBorder="0"
                        allowFullScreen
                      />
                    </div>
                  )}
              </Modal.Body>
              <Modal.Footer className="pdfModelFooter">
                <Button bsStyle="primary" onClick={this.displayPdf}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            {this.props.detailedInfoContents != null && (
              <Panel.Body
                className="featureInfoPanelBody"
                id="featureInfoPanelBody"
              >
                <div className="imageContainer" onClick={this.displayImage}>
                  {images != undefined &&
                    images.length > 0 &&
                    img_url != undefined && (
                      <Carousel>
                        {images.map(function(image, index) {
                          // console.log('images',image);
                          return (
                            <Carousel.Item key={index}>
                              <img
                                key={index}
                                src={img_url.concat(image)}
                                width={350}
                                height={350}
                                alt="350x350"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    )}
                </div>
                <div>
                  {prog.length == 3 && (
                    <div className="show-gridsss">
                      {prog.map((element) => {
                        return (
                          <Row>
                            <Col md={4} className="progresbarname">
                              <p>
                                <b>{element.name}</b>
                              </p>
                            </Col>
                            <Col md={8} className="progresbar">
                              <ProgressBar
                                striped
                                active
                                bsStyle="success"
                                now={element.per}
                                label={`${element.per}%`}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  )}
                </div>
                <TableFormat displayName="Basic Details">
                  <tbody className="tablebody">
                    {this.showUpdationStatus()}
                    {this.formatInfo()}
                    {itemsForeign}
                  </tbody>
                    </TableFormat>

                    {sortedData.map((data) => {
                  if (
                    data.related_table === "members" &&
                    data.fields.length > 0
                  ) {
                    return <MemberDetails data={data.fields} />;
                  }
                  if (
                    data.related_table === "floor_prop_area" &&
                    data.fields.length > 0
                  ) {
                    return <PropertyArea data={data.fields} />;
                  }
                  
                  if (
                    data.related_table === "property_floor_type" &&
                     data.related_table === "property_roof_type" &&
                    data.fields.length > 0
                  ) {
                    return <RoofType data={data.fields} />;
                  }
                  if (
                    data.fields.length > 0 &&
                    !(
                      layerName == "Residential" &&
                      data.related_table === "establishment"
                    ) &&
                    !(
                      layerName !== "Residential" &&
                      data.related_table === "property_Other_Details"
                    )
                  ) {
                    return <RelatedTables data={data} />;
                  }
                })}
                {/*<TableFormat displayName="Basic Details">
                  <tbody className="tablebody">
                    {this.showUpdationStatus()}
                    {this.formatInfo()}
                    {itemsForeign}
                  </tbody>
                    </TableFormat>*/}
                {this.props.detailedInfoContentsForeignKey &&
                this.props.detailedInfoContentsForeignKey[this.props.count] &&
                this.props.detailedInfoContentsForeignKey[this.props.count]
                  .editable &&
                this.props.detailedInfoContentsForeignKey[this.props.count]
                  .available_permissions &&
                this.props.detailedInfoContentsForeignKey[this.props.count]
                  .available_permissions.length ? (
                  <Button
                    bsStyle="primary"
                    block
                    onClick={(e) => {
                      this.setState({
                        editAddItem: !this.state.editAddItem,
                      });
                    }}
                  >
                    Action
                  </Button>
                ) : (
                  ""
                )}
                {this.props.detailedInfoContentsForeignKey &&
                this.props.detailedInfoContentsForeignKey[this.props.count] &&
                this.props.detailedInfoContentsForeignKey[this.props.count]
                  .available_permissions ? (
                  <Collapse
                    in={this.state.editAddItem}
                    className="featureInfoPanel"
                  >
                    <div>
                      {this.props.detailedInfoContentsForeignKey[
                        this.props.count
                      ].available_permissions.indexOf("can_edit") >= 0 ? (
                        <Button
                          className="editAddButton"
                          onClick={(e) => {
                            window.open(
                              ADMIN_URL +
                                "map/login/" +
                                LocalStorageHandler.getToken() +
                                "/" +
                                layer_id.replace("_", "/").replace(".", "/") +
                                "/change/",
                              "_blank"
                            );
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        ""
                      )}
                      {/* {this.props.detailedInfoContentsForeignKey[
                        this.props.count
                      ].available_permissions.indexOf("can_add") >= 0 ? (
                        <Button
                          className="editAddButton"
                          onClick={(e) => {
                            window.open(
                              ADMIN_URL +
                                "admin/" +
                                layer_id.split(".")[0].replace("_", "/") +
                                "/add/",
                              "_blank"
                            );
                          }}
                        >
                          Add New
                        </Button>
                      ) : (
                        ""
                      )} */}
                      <PdfReport
                        count={this.props.count}
                        detailedInfoContents={this.props.detailedInfoContents}
                        detailedInfoContentsForeignKey={this.props.detailedInfoContentsForeignKey}
                        selectedLayer={this.props.selectedLayer}
                        renderImage={this.renderImage}
                      ></PdfReport>
                    </div>
                  </Collapse>
              ) : (
                  ""
                )}
                <div className="pdfBotton">
                  <ButtonGroup bsSize="xsmall">
                    {this.state.pdf_mgmt && (
                      <Button
                        bsStyle="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.displayPdf(this.state.pdf_mgmt_file, e);
                        }}
                      >
                        Management Details
                      </Button>
                    )}
                    {this.state.pdf_doc && (
                      <Button
                        bsStyle="success"
                        onClick={(e) => {
                          e.preventDefault();
                          this.displayPdf(this.state.pdf_doc_file, e);
                        }}
                      >
                        Documents
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              </Panel.Body>
            )}
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default DetailedInfo;
