import React, { Component } from "react";
import { connect } from "react-redux";
import "./AddAsset.css";
import AddAssetPanel from "./AddAssetPanel";
import addAssetRed from "../../../../../asset/svg/add_asset_red.svg";
import { updateWidgetEnable } from "../../map_component/data/actions";
import Widget from "../../../../../containers/widget/widget";
class AddAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAssetPanelControl: false,
      enabled: false,
      layersList: "",
    };
  }

  handleShowAssetPanelControl = () => {
    this.setState({
      showAssetPanelControl: !this.state.showAssetPanelControl,
    });
    this.props.updateWidgetEnable("5");
  };

  render() {
    return (
      <React.Fragment>
        <Widget
          placement="right"
          tooltipText="Add Asset"
          handleClick={this.handleShowAssetPanelControl}
          img={addAssetRed}
          class="addAsset"
        />
        <AddAssetPanel
          showAssetPanelControl={this.state.showAssetPanelControl}
          handleShowAssetPanelControl={this.handleShowAssetPanelControl}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (enable) => dispatch(updateWidgetEnable(enable)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAsset);
