import React, { Component } from "react";
import DataFormat from "../../helper/DataFormat";
import TableFormat from "./TableFormat";
class RoofType extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props;
    return (
      <TableFormat displayName={"Roof Type"}>
        <tbody className="tablebody">
          {data.map((item, key) => {
            return (
              <>
                <tr key={key}>
                  <td style={{ width: "300px" }}>Roof Category</td>
                  <td style={{ width: "300px" }}>
                    {DataFormat.dataFormat(item["Roof Category"])}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "300px" }}>Roof Percentage</td>
                  <td style={{ width: "300px" }}>
                    {DataFormat.dataFormat(item["Roof Percentage"])}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </TableFormat>
    );
  }
}
export default RoofType;
