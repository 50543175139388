import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
  } from "./constants";
  const initialState={
    APIRequest: false,
    APIRequestModel:false,
    responses:""
  }
  export default function(state = initialState, action) {
    switch (action.type) {
      case FORGOT_PASSWORD_REQUEST:
        return {
          ...state,
          APIRequestModel: false
        };
      case FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          APIRequestModel: true,
          responses: action.response.data
        };
      case FORGOT_PASSWORD_FAILURE:
        return {
          ...state,

          APIRequestModel: false,
          responses:action.error
        };
        case RESET_PASSWORD_REQUEST:
        return {
          ...state,
          APIRequest: false
        };
      case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          APIRequest: true,
          responses: action.response.data
        };
      case RESET_PASSWORD_FAILURE:
        return {
          ...state,

          APIRequest: false,
          responses:action.error
        };
      default:
        return state;
    }
  }
  