import APIRequest from "../util/api/APIRequest";
const fetchUsers = (page, limit) => {
  return APIRequest({
    url: "user/",
    method: "GET",
    params: {
      page: page,
      limit: limit
    }
  });
};

const addOrUpdateUser = (data, method) => {
  return APIRequest({
    url: "user/",
    method: method,
    data: data
  });
};
const fetchUserByID = id => {
  return APIRequest({
    url: `user/${id}`,
    method: "GET"
  });
};
const deleteUser = userId => {
  return APIRequest({
    url: `user/${userId}`,
    method: "DELETE"
  });
};
//user can change password from the settings module
const changePassword = data =>{
  return APIRequest({
    url:'user/resetpassword/',
    method:"POST",
    data:data
  });
};
//If password is forgot then call this function to send reset password url to mail
const doForgotPassword=(usernameOremail)=>{
  return APIRequest({
    url: "user/forgotpassword",
    method: "POST",
    data:usernameOremail
  });
}
//to reset the password
const reasetPassword=(data)=>{
  return APIRequest({
    url: "user/reset-password",
    method: "POST",
    data:data
  });
}
const UserService = {
  fetchUsers,
  addOrUpdateUser,
  fetchUserByID,
  deleteUser,
  changePassword,
  doForgotPassword,
  reasetPassword
};
export default UserService;
