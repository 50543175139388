import React, { Component } from "react";
import { connect } from "react-redux";
import layers from "../../../../../asset/svg/layers_red.svg";
import baseLayers from "../../../../../asset/svg/base_maps_red.svg";
import legend from "../../../../../asset/svg/legend_red.svg";
import opacity from "../../../../../asset/svg/opacity_red.svg";
import logout from "../../../../../asset/svg/logout_red.svg";
import settings from "../../../../../asset/svg/Settings_red.svg";
import more from "../../../../../asset/svg/more.svg";
import draw from "../../images/draw.png";
import ToolbarMenus from "./ToolbarMenus";
import localStorageHandler from "../../../../../util/storage";
import { doLogout } from "../../../../user/login/data/actions";

class MenuItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  mapWidgets() {
    return [
      "Layers",
      "Legend",
      "Opacity",
      "Base Maps",
      "Draw",
      "More",
      "Styles",
      "Settings",
    ];
  }

  getWidget(menuName) {
    switch (menuName) {
      case "Layers":
        return this.generateMenuItem(
          layers,
          this.props.handleShowLayerControl,
          menuName
        );
      case "Legend":
        return this.generateMenuItem(
          legend,
          this.props.handleShowLegendControl,
          menuName
        );
      case "Opacity":
        return this.generateMenuItem(
          opacity,
          this.props.handleShowOpacityControl,
          menuName
        );
      case "Base Maps":
        return this.generateMenuItem(
          baseLayers,
          this.props.handleShowBaseMapControl,
          menuName
        );
      case "Draw":
        return this.generateMenuItem(draw, null, menuName);
      case "Styles":
        return this.generateMenuItem(
          draw,
          this.props.handleShowStyleControl,
          menuName
        );
      case "Settings":
        return this.generateMenuItem(
          more,
          this.props.handleShowSettingsControl,
          menuName
        );

      default:
        return;
    }
  }
  generateMenuItem(img, handleClick, tooltipText) {
    return (
      <ToolbarMenus
        img={img}
        handleClick={handleClick}
        tooltipText={tooltipText}
        key={tooltipText}
      />
    );
  }
  getRoleBasedMenu() {
    let userMenu = localStorageHandler.getMenu();
    let items = [];
    this.mapWidgets().forEach((element) => {
      userMenu.map((menuItem) => {
        if (element === menuItem.menu_name) {
          if (menuItem.permission.length > 0)
            if (menuItem.permission[0].view_permission)
              items.push(this.getWidget(menuItem.menu_name));
        }
      });
    });
    return items;
  }

  render() {
    return (
      <ul className="nav navbar-nav navbar-right">
        {this.getRoleBasedMenu()}
        {localStorageHandler.getUserDetails().user_type === 6 && (
          <ToolbarMenus
            img={logout}
            tooltipText={"Logout"}
            handleClick={this.props.doLogout}
          />
        )}
      </ul>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    doLogout: () => dispatch(doLogout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItems);
