import React, { Component } from "react";
import {
  Label,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
} from "react-bootstrap";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { LineString, Polygon, Circle } from "ol/geom.js";
import Draw from "ol/interaction/Draw.js";
import { getArea, getLength } from "ol/sphere.js";
import { unByKey } from "ol/Observable.js";
import areaImgRed from "../../../../../asset/svg/measure_area_red.svg";
import lengthImg from "../../../../../asset/svg/measure_length_red.svg";
import circleImg from "../../../../../asset/svg/measure_radius_red.svg";
import { updateWidgetEnable } from "../../map_component/data/actions";
import { connect } from "react-redux";
import "./MeasurePanel.css";
import OverlayPanel from "../overlay_panel/OverlayPanel";

class MeasurePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawType: null,
      vectorSource: null,
      draw: null,
      sketch: null,
      listener: null,
      output: 0.0,
      unit: "",
    };
  }

  handleInputChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      vectorSource: new VectorSource(),
    });
  }

  handleShowMeasurePanelControl = () => {
    const { mapComponent } = this.props;
    mapComponent.removeInteraction(this.state.draw);
    this.props.handleShowMeasurePanelControl();
  };

  handleClear = () => {
    const { mapComponent } = this.props;
    mapComponent.removeInteraction(this.state.draw);
    this.setState({
      output: 0.0,
      drawType: null,
    });
    mapComponent.getLayers().forEach((layer) => {
      if (layer && layer.get("name") && layer.get("name") == "measure-layer") {
        mapComponent.removeLayer(layer);
      }
    });
  };

  handleMeasureTypeControl = (drawType) => {
    const { mapComponent } = this.props;
    const { vectorSource, draw } = this.state;
    mapComponent.removeInteraction(draw);
    vectorSource.clear();
    mapComponent.addLayer(this.getVectorLayer());
    let listener;
    this.setState(
      {
        drawType: drawType,
        output: 0.0,
        draw: this.addInteraction(drawType),
      },
      () => {
        mapComponent.addInteraction(this.state.draw);
        const { draw } = this.state;
        draw.on("drawstart", (evt) => {
          vectorSource.clear();
          listener = this.handleDrawStart(evt);
        });
        draw.on("drawend", (listener) => {
          this.handleDrawEnd(listener);
        });
      }
    );
  };

  handleDrawStart = (evt) => {
    this.setState({
      sketch: evt.feature,
    });

    return this.state.sketch.getGeometry().on("change", (evt) => {
      let geom = evt.target;
      if (geom instanceof Polygon) {
        this.setState({
          output: this.formatArea(geom),
        });
      } else if (geom instanceof LineString) {
        this.setState({
          output: this.formatLength(geom),
        });
      } else if (geom instanceof Circle) {
        this.setState({
          output: this.formatRadius(geom),
        });
      }
    });
  };

  handleDrawEnd = (listener) => {
    this.setState({
      sketch: null,
    });
    unByKey(listener);
    //this.props.mapComponent.removeInteraction(this.state.draw);
  };

  getVectorLayer() {
    return new VectorLayer({
      source: this.state.vectorSource,
      name: "measure-layer",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "red",
          width: 2,
        }),
      }),
    });
  }
  addInteraction(drawType) {
    return new Draw({
      source: this.state.vectorSource,
      type: drawType,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
  }

  formatArea(polygon) {
    var area = getArea(polygon);
    var output;
    if (this.state.unit == "km") {
      output = Math.round((area / 1000000) * 100) / 100 + " " + "km";
    } else {
      output = Math.round(area * 100) / 100 + " " + "m";
    }
    return output;
  }

  formatLength(line) {
    var length = getLength(line);
    var output;
    if (this.state.unit == "km") {
      output = Math.round((length / 1000) * 100) / 100 + " " + "km";
    } else {
      output = Math.round(length * 100) / 100 + " " + "m";
    }
    return output;
  }

  formatRadius(circle) {
    var radius;
    radius = Math.round(circle.getRadius() * 100) / 100;
    var output;
    if (this.state.unit == "km") {
      output = Math.round((radius / 1000) * 100) / 100 + " " + "km";
    } else {
      output = Math.round(radius * 100) / 100 + " " + "m";
    }
    return output;
  }

  render() {
    const { showWidget } = this.props;
    if (showWidget === "2") {
      return (
        <OverlayPanel
          showControl={this.props.showMeasurePanelControl}
          handleShowControl={this.handleShowMeasurePanelControl}
          titleName="Measure"
          headClass="measurePanels"
          bodyClass="measurePanelsBody"
        >
          <div className="measureButtonContainer">
            {/* <Form horizontal>
              <FormGroup>
                <Col componentClass={ControlLabel} sm={2}>
                  Unit
                </Col>
                <Col sm={10}>
                  <FormControl
                    style={{ height: "30px" }}
                    componentClass="select"
                    placeholder="Unit"
                    value={this.state.unit}
                    name="unit"
                    onChange={this.handleInputChange}
                    required
                  >
                    <option value="m">m</option>
                    <option value="km">km</option>
                  </FormControl>
                </Col>
              </FormGroup>
            </Form> */}
            <div className="measureButtons">
              <button
                className="extendMeasureButton"
                onClick={(type) => this.handleMeasureTypeControl("LineString")}
              >
                <OverlayTrigger
                  placement="top"
                  style={{
                    backgroundColor: "var(--secondary-color)",
                  }}
                  overlay={<Tooltip id="menu">Measure Length</Tooltip>}
                >
                  <img src={lengthImg} />
                </OverlayTrigger>
              </button>
            </div>
            <div className="measureButtons">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="menu">Measure Area</Tooltip>}
              >
                <button
                  className="extendMeasureButton"
                  onClick={(type) => this.handleMeasureTypeControl("Polygon")}
                >
                  <img src={areaImgRed} />
                </button>
              </OverlayTrigger>
            </div>
            <div className="measureButtons">
              <OverlayTrigger
                placement="top"
                style={{
                  backgroundColor: "var(--secondary-color)",
                }}
                overlay={<Tooltip id="menu">Measure Radius</Tooltip>}
              >
                <button
                  className="extendMeasureButton"
                  onClick={(type) => this.handleMeasureTypeControl("Circle")}
                >
                  <img src={circleImg} />
                </button>
              </OverlayTrigger>
            </div>
          </div>
          <div className="measureResultContainer">
            <div className="measureResult">
              <h5>
                <span>
                  {this.state.drawType == "LineString"
                    ? "Length"
                    : this.state.drawType == "Polygon"
                    ? "Area"
                    : this.state.drawType == "Circle"
                    ? "Radius"
                    : "Select a Geometry"}
                </span>
                <Label
                  style={{
                    backgroundColor: "var(--secondary-color)",
                    marginLeft: "3px",
                  }}
                >
                  {this.state.drawType == "LineString" && (
                    <span>{this.state.output}</span>
                  )}
                  {this.state.drawType == "Polygon" && (
                    <span>
                      {this.state.output}
                      <sup>2</sup>
                    </span>
                  )}
                  {this.state.drawType == "Circle" && (
                    <span>{this.state.output}</span>
                  )}
                </Label>
              </h5>
            </div>
          </div>
          <div>
            <Button
              className="measureClearButton"
              bsStyle="success"
              bsSize="small"
              onClick={() => this.handleClear()}
            >
              Clear Measure
            </Button>
          </div>
        </OverlayPanel>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (enable) => dispatch(updateWidgetEnable(enable)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasurePanel);
