import APIRequest from "../util/api/APIRequest";

const mapQueryBuffer = (layerlist, geometry, featureGeometry) => {
  if (featureGeometry != null){
    return APIRequest({
      url: `map/bufferresult/`,
      method: "POST",
      data: {
        layer: layerlist,
        buffer_type : "Polygon",
        flatCoordinate : geometry,
        flatCoordinate_polygon : featureGeometry,
    }});
  }
  else {
    return APIRequest({
      url: `map/bufferresult/`,
      method: "POST",
      data: {
        layer: layerlist,
        buffer_type : null,
        flatCoordinate : geometry,
        flatCoordinate_polygon : [],
    }});
  }
    
  };

  const BufferService = {
    mapQueryBuffer,
  };
  export default BufferService;