import {
  IS_CONFIG_LOADING,
  CONFIG_LOADING_COMPLETE,
  GET_DASHBOARD_SUMMARY_FAILED,
  GET_DASHBOARD_SUMMARY_SUCCESS,
  GET_WARDS_FAILED,
  GET_WARDS_SUCCESS,
  GET_DASHBOARD_FILTERS_SUCCESS,
  GET_DASHBOARD_FILTERS_FAILED,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_DETAILS_FAILED,
  GET_JOB_FILTER_SUCCESS,
  GET_JOB_FILTER_FAILED,
  GET_EDUCATION_FILTER_SUCCESS,
  GET_EDUCATION_FILTER_FAILED,
  MEMBER_DETAILS_DOWNLOAD_INIT_ACTION,
  MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_SUCEESS,
  MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_FAIL,
  GET_MEMBER_DETAILS_SUCCESS_INIT_ACTION,
  GET_ANALISIS_FILTER_SUCCESS,
  GET_ANALISIS_FILTER_FAILED,ACCESSIBLE_LAYER_REQUEST,
    
} from "./constants";
const initialState = {
  isConfigLoading: false,
  isDashboardSummaryLoaded: false,
  dashboardSummary: null,
  wards: [],
  isWardsLoaded: false,
  filters: null,
  isFiltersLoaded: false,
  memberDetails: null,
  isMemberDetailsLoaded: false,
  jobFilter: null,
  isJobFilterLoaded: false,
  educationFilter: null,
  isEducationFilterLoaded: false,
  downloadPage:1,
  recordCount: 0,
  unique_id: null,
  path:null,
  socioEconomicFetchData: false,
  // isDropdowndata: false,
  // dropdownData: "",
  roadlist: {},
  loading: false,
  error: null,
};
export default function(state = initialState, action) {
  switch (action.type) {
    case IS_CONFIG_LOADING:
      return {
        ...state,
        isConfigLoading: true
      };
    case CONFIG_LOADING_COMPLETE:
      return {
        ...state,
        isConfigLoading: false
      };
    case GET_DASHBOARD_SUMMARY_SUCCESS:
      return {
        ...state,
        isDashboardSummaryLoaded: true,
        dashboardSummary: action.response.data,
      };
    case GET_DASHBOARD_SUMMARY_FAILED:
      return {
        ...state,
        isDashboardSummaryLoaded: false,
        dashboardSummary: null,
      };
      // case GET_DROPDOWN_DATA_SUCCESS:
        //     return{
        //         ...state,
        //         isDropdowndata: true,
        //         dropdownData: action.response.data,
        //     };

        // case GET_DROPDOWN_DATA_FAILED:
        //     return{
        //         ...state,
        //         isDropdowndata: false,
        //         dropdownData: null,
        //     };
    //     case FETCH_ROADLIST_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };

    // case FETCH_ROADLIST_SUCCESS:
    //   return {
    //     ...state,
    //     roadlist: action.payload,
    //     loading: false,
    //     error: null,
    //   };

    // case FETCH_ROADLIST_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error,
    //   };
    case GET_WARDS_SUCCESS:
      return {
        ...state,
        isWardsLoaded: true,
        wards: action.response.data.wards,
      };
    case GET_WARDS_FAILED:
      return {
        ...state,
        isWardsLoaded: false,
        wards: [],
      };
    case GET_DASHBOARD_FILTERS_SUCCESS:
      return {
        ...state,
        filters: action.response.data.data,
        isFiltersLoaded: true,
      };
    case GET_DASHBOARD_FILTERS_FAILED:
      return {
        ...state,
        filters: null,
        isFiltersLoaded: false,
      };
      case GET_ANALISIS_FILTER_SUCCESS:
      return {
        ...state,
        fetching: false,
        layers_list: action.response.data.layers
      };
    case GET_ANALISIS_FILTER_FAILED:
      return {
        ...state,
        fetching: false
      }; 
      case ACCESSIBLE_LAYER_REQUEST:
        return {
          ...state,
          fetching: true
          };
      
    
    case GET_MEMBER_DETAILS_SUCCESS:
      return {
        ...state,
        memberDetails: action.response.data.member_details,
        isMemberDetailsLoaded: true,
        unique_id: action.response.data.member_details ? action.response.data.unique_id : "",
        recordCount: action.response.data.member_details ? action.response.data.total_items : 0,
        path: action.response.data.member_details ? action.response.data.path : "",
        socioEconomicFetchData: false
      };
    case GET_MEMBER_DETAILS_FAILED:
      return {
        ...state,
        memberDetails: null,
        isMemberDetailsLoaded: false,
        socioEconomicFetchData: false,
      };
    case GET_JOB_FILTER_SUCCESS:
      return {
        ...state,
        jobFilter: action.response.data.data,
        isJobFilterLoaded: true,
      };
    case GET_JOB_FILTER_FAILED:
      return {
        ...state,
        jobFilter: null,
        isJobFilterLoaded: false,
      };
    case GET_EDUCATION_FILTER_SUCCESS:
      return {
        ...state,
        educationFilter: action.response.data.data,
        isEducationFilterLoaded: true,
      };
    case GET_EDUCATION_FILTER_FAILED:
      return {
        ...state,
        educationFilter: null,
        isEducationFilterLoaded: false,
      };    

    case MEMBER_DETAILS_DOWNLOAD_INIT_ACTION:
        return {
          ...state,
          downloadLoading: true,
          // downloadPage:action.payload.page,
        };

      case MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_SUCEESS:
        return {
          ...state,
          downloadFilter: action.response.member_details,
          downloadPage:state.downloadPage + 1,
          isDownloadFilterLoaded: true,
          downloadLoading: false,
        };
      case MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_FAIL:
        return {
          ...state,
          downloadFilter: null,
          isDownloadFilterLoaded: false,
          downloadLoading: false,
        };
        case GET_MEMBER_DETAILS_SUCCESS_INIT_ACTION:
          return {
          ...state,
          socioEconomicFetchData: true,
          memberDetails: [],
          }

    default:
      return state;
  }
}
