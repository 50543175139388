import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import "./GoToXYPanel.css";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { transform } from "ol/proj";
import { Stroke, Style, Icon } from "ol/style.js";
import { updateWidgetEnable } from "../../map_component/data/actions";
import pin from "../../../../../asset/png/pin.png";
import OverlayPanel from "../overlay_panel/OverlayPanel";

class GoToXYPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x_value: 0.0,
      y_value: 0.0,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  removeGoToLocation = () => {
    const { mapComponent } = this.props;
    mapComponent.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") == "goToLocation") {
        mapComponent.removeLayer(layer);
      }
    });
  };

  goToLocation = (event) => {
    event.preventDefault();
    const { mapComponent } = this.props;
    this.removeGoToLocation();
    var x = Number(this.state.x_value);
    var y = Number(this.state.y_value);
    var iconFeature = new Feature({
      geometry: new Point(transform([x, y], "EPSG:4326", "EPSG:3857")),
      population: 4000,
      rainfall: 500,
    });
    var iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: pin,
        scale: 0.5,
      }),
    });
    iconFeature.setStyle(iconStyle);
    var vectorSource = new VectorSource({
      features: [iconFeature],
    });
    var vectorLayer = new VectorLayer({
      name: "goToLocation",
      source: vectorSource,
      wfsstyle: new Style({
        stroke: new Stroke({
          color: "rgba(0, 0, 255, 1.0)",
          width: 2,
        }),
      }),
    });
    mapComponent.addLayer(vectorLayer);
    mapComponent
      .getView()
      .setCenter(transform([x, y], "EPSG:4326", "EPSG:3857"));
    mapComponent.getView().setZoom(13);
  };

  render() {
    const { showWidget } = this.props;
    if (showWidget === "1") {
      return (
        <OverlayPanel
          showControl={this.props.showGoToXYPanelControl}
          handleShowControl={this.props.handleShowGoToXYPanelControl}
          titleName="Go To Location"
          headClass="gotoPanels"
          bodyClass="gotoPanelsBody"
        >
          <Form onSubmit={this.onSubmit} horizontal>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={4}>
                Longitude
              </Col>
              <Col sm={8}>
                <FormControl
                  type="input"
                  name="x_value"
                  placeholder="Enter X value"
                  value={this.state.x_value}
                  onChange={this.handleInputChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalEmail">
              <Col componentClass={ControlLabel} sm={4}>
                Latitude
              </Col>
              <Col sm={8}>
                <FormControl
                  type="input"
                  name="y_value"
                  placeholder="Enter Y value"
                  value={this.state.y_value}
                  onChange={this.handleInputChange}
                />
              </Col>
            </FormGroup>
            <Col xs={6}>
              <Button
                className="gotoPanelsButtons"
                bsStyle="success"
                bsSize="small"
                onClick={(event) => this.removeGoToLocation()}
              >
                Remove
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="gotoPanelsButtons"
                bsStyle="success"
                bsSize="small"
                onClick={(event) => this.goToLocation(event)}
              >
                Submit
              </Button>
            </Col>
          </Form>
        </OverlayPanel>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    legendUrls: state.mapReducer.legendUrls,
    showWidget: state.mapReducer.showWidget,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (enable) => dispatch(updateWidgetEnable(enable)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoToXYPanel);
