import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import jsPDF from "jspdf";
import { transform } from "ol/proj.js";
import { toStringHDMS } from "ol/coordinate";
import { Button, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import LocalStorageHandler from "../../../../../util/storage";
import getLayerTree from "../../helper/LayerTreeLoader";
import "./ExportPdfPanel.css";
import compass from "../../../../../asset/png/compass.png";
import ulccs_emblem from "../../../../../asset/png/ULCCS emblem.png";
import emblem from "../../../../../asset/png/emblem.png";
import OverlayPanel from "../overlay_panel/OverlayPanel";

class ExportPdfPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: "a3",
      resolution: "72",
      orientation: "landscape",
      layout: "L1",
      heading: "",
      subHeading: "",
      legendUrls: [],
      selectedLayers: [],
    };
    this.pdf = null;
  }
  handleChange = (e, validation) => {
    var field = {};
    if (
      validation &&
      validation.maxLength &&
      e.target.value.length > validation.maxLength
    )
      field[e.target.name] = this.state[e.target.name];
    else field[e.target.name] = e.target.value;
    this.setState(field);
  };
  // handleChangePageSize = (e) => {
  //   this.setState({ pageSize: e.target.value });
  // };
  // handleChangeLayout = (e) => {
  //   this.setState({ layout: e.target.value });
  // };
  // handleChangeHeading = (e) => {
  //   this.setState({ heading: e.target.value });
  // };
  // handleChangeSubheading = (e) => {
  //   this.setState({ subHeading: e.target.value });
  // };
  // handleChangeResolution = (e) => {
  //   this.setState({ resolution: e.target.value });
  // };
  handleExportPdf = () => {
    this.handleMapRenderComplete(this.props.renderEvent);
    this.props.setZoom();
  };
  getBase64Image(pdf) {
    let images = [];
    const legendUrls = this.getLegendUrls();
    legendUrls.map((item) => {
      axios(item.url, {
        responseType: "arraybuffer",
      }).then((response) => {
        let imgString = new Buffer(response.data, "binary").toString("base64");
        let image = "data:image/jpeg;base64,".concat(imgString);
        images.push(image);
        pdf.addImage(image, "PNG", 16, 170, 50, 30);
        // pdf.save("map.pdf");
      });
    });
  }

  urlTobase64(url) {
    axios(url, {
      responseType: "arraybuffer",
    }).then((response) => {
      let imgString = new Buffer(response.data, "binary").toString("base64");
      let image = "data:image/jpeg;base64,".concat(imgString);
      // images.push(image);
      // pdf.addImage(image, "JPEG", 16, 170, 50, 30);
      return image;
    });
  }
  toDegreesLong(coord) {
    // var out=[];
    var out1 = toStringHDMS(coord);
    var l = out1.length;
    var out = out1.substring(l / 2 + 1, l);
    var str = out.split(" ");
    var min_val = str[1].substring(0, 2);
    var sec_val = str[2].substring(0, 2);
    var newstr = str[0] + " " + min_val + "' " + sec_val + '" ' + str[3];
    return newstr;
  }
  toDegreesLat(coord) {
    var out1 = toStringHDMS(coord);
    var l = out1.length;
    var out = out1.substring(0, l / 2);
    var str = out.split(" ");
    var min_val = str[1].substring(0, 2);
    var sec_val = str[2].substring(0, 2);
    var newstr = str[0] + " " + min_val + "' " + sec_val + '" ' + str[3];
    return newstr;
  }
  //   getTextWidth(txt, font) {
  //     this.element = document.createElement('canvas');
  //     this.context = this.element.getContext("2d");
  //     this.context.font = font;
  //     var tsize = {'width':this.context.measureText(txt).width, 'height':parseInt(this.context.font)};
  //     return tsize;
  // };

  handleMapRenderComplete = (event) => {
    const { mapComponent } = this.props;
    var data = null;
    this.getLegendUrls();
    //Creating compass image
    var img1 = new Image();
    img1.crossOrigin = "Anonymous";
    img1.src = compass;
    //Creating inset map image
    var text = LocalStorageHandler.getLocalBody().local_body_name;
    var type = LocalStorageHandler.getLocalBody().localbody_type;
    let img4_url =
      "https://map.ulgis.com/mapImages/" + text + " " + type + ".PNG";
    var img2 = new Image();
    img2.crossOrigin = "Anonymous";
    img2.src = img4_url;
    //Creating LSGD image
    var img3 = new Image();
    img3.crossOrigin = "Anonymous";
    img3.src = emblem;
    //Creating ULCCS emblem
    var img4 = new Image();
    img4.crossOrigin = "Anonymous";
    img4.src = ulccs_emblem;
    var dims = {
      a0: [1189, 841],
      a1: [841, 594],
      a2: [594, 420],
      a3: [420, 297],
      a4: [297, 210],
      a5: [210, 148],
    };
    var format = this.state.pageSize;
    var resolution = this.state.resolution;
    var layout = this.state.layout;
    var heading = this.state.heading;
    var subheading = this.state.subHeading;
    var dim = dims[format];
    var width = Math.round((dim[0] * resolution) / 25.4);
    var height = Math.round((dim[1] * resolution) / 25.4);
    var size = /** @type {module:ol/size~Size} */ (mapComponent.getSize());
    // var extent = mapComponent.getView().calculateExtent(size);
    var extent = mapComponent.getView().calculateExtent(size);
    var canvas = event.context.canvas;
    // var ccanvas = document.createElement("canvas");
    // var ctx = ccanvas.getContext("2d");
    var imgUrl = canvas.toDataURL("image/jpeg");
    var imgMap = new Image();
    imgMap.src = imgUrl;
    // imgMap.onload = function() {
    //   ctx.drawImage(imgMap, 0, 0, 500, 300, 60, 60, 500, 300);
    // };
    event.context.drawImage(imgMap, 0, 0, 500, 300, 60, 60, 500, 300);
    let ccanvas = event.context.canvas;
    var data = ccanvas.toDataURL("image/jpeg");
    // map.once("rendercomplete", function() {
    //   const mapCanvas = document.createElement("canvas");
    //   mapCanvas.width = width;
    //   mapCanvas.height = height;
    //   const mapContext = mapCanvas.getContext("2d");
    //   Array.prototype.forEach.call(
    //     document.querySelectorAll(".ol-layer canvas"),
    //     function(canvas) {
    //       if (canvas.width > 0) {
    //         const opacity = canvas.parentNode.style.opacity;
    //         mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
    //         const transform = canvas.style.transform;
    //         // Get the transform parameters from the style's transform matrix
    //         const matrix = transform
    //           .match(/^matrix\(([^\(]*)\)$/)[1]
    //           .split(",")
    //           .map(Number);
    //         // Apply the transform to the export map context
    //         CanvasRenderingContext2D.prototype.setTransform.apply(
    //           mapContext,
    //           matrix
    //         );
    //         mapContext.drawImage(canvas, 0, 0);
    //       }
    //     }
    //   );
    //   mapContext.globalAlpha = 1;
    //   data = mapCanvas.toDataURL("image/jpeg");
    // });
    // var canvas = event.context.canvas;
    // canvas.toDataURL("image/jpeg");

    var pdf = new jsPDF(
      this.state.orientation,
      undefined,
      this.state.orientation === "landscape" ? dims.a3 : dims.a2
    );
    var textx,
      texty,
      compassx,
      compassy,
      compassw,
      compassh,
      imgx,
      imgy,
      imgw,
      imgh,
      size,
      legendx,
      legendy,
      srectx,
      srecty,
      srectw,
      srecth,
      stextx,
      stexty,
      crtextx,
      crtexty,
      crfont,
      sfont,
      xoffset,
      yoffset,
      ylimit,
      hrx,
      hry,
      hrw,
      hrh,
      scrx,
      scry,
      scrw,
      scrh,
      lgrx,
      lgry,
      lgrw,
      lgrh,
      lgfx,
      lgfy,
      lsgdrx,
      lsgdry,
      lsgdrw,
      lsgdrh,
      lsgdtx,
      lsgdty,
      ultsx,
      ultsy,
      borx,
      bory,
      borw,
      borh,
      insetRecX,
      insetRecY,
      insetRecW,
      insetRecH,
      insetMapX,
      insetMapY,
      insetMapW,
      insetMapH;
    switch (format) {
      case "a0": {
        textx = 570;
        texty = 35;
        compassx = 28;
        compassy = 40;
        compassw = 120;
        compassh = 120;
        imgx = 28;
        imgy = 40;
        imgw = 1035.1;
        imgh = 556.6;
        size = 35;
        legendx = 1130;
        legendy = 40;
        srectx = 28;
        srecty = 808;
        srectw = 60;
        srecth = 10;
        stextx = 44;
        stexty = 816;
        crtextx = 28;
        crtexty = 826;
        crfont = 30;
        sfont = 30;
        xoffset = 0;
        yoffset = 5;
        ylimit = 850;
        hrx = 18;
        hry = 15;
        hrw = 300;
        hrh = 30;
        scrx = 0;
        scry = 0;
        scrw = 0;
        scrh = 0;
        lgrx = 0;
        lgry = 0;
        lgrw = 0;
        lgrh = 0;
        lgfx = 0;
        lgfy = 0;
        lsgdrx = 0;
        lsgdry = 0;
        lsgdrw = 0;
        lsgdrh = 0;
        lsgdtx = 0;
        lsgdty = 0;
        ultsx = 0;
        ultsy = 0;
        insetRecX = 18;
        insetRecY = 211;
        insetRecW = 120;
        insetRecH = 75;
        insetMapX = 19;
        insetMapY = 212;
        insetMapW = 118;
        insetMapH = 73;
        break;
      }

      case "a3": {
        switch (layout) {
          case "L1": {
            textx = 205;
            texty = 35;
            compassx = 350.5;
            compassy = 15;
            compassw = 23;
            compassh = 23;
            borx = 14;
            bory = 11;
            borw = 393;
            borh = 279;
            imgx = 21;
            imgy = 51;
            imgw = 382;
            imgh = 157;
            size = 25;
            legendx = 143;
            legendy = 219;
            srectx = 342;
            srecty = 41;
            srectw = 40;
            srecth = 3;
            stextx = 358;
            stexty = 40;
            crtextx = 15;
            crtexty = 294;
            crfont = 10;
            sfont = 10;
            xoffset = 0;
            yoffset = 5;
            ylimit = 250;
            hrx = 18;
            hry = 15;
            hrw = 300;
            hrh = 30;
            scrx = 321;
            scry = 15;
            scrw = 82;
            scrh = 30;
            lgrx = 141;
            lgry = 211;
            lgrw = 262;
            lgrh = 50;
            lgfx = 143;
            lgfy = 216;
            lsgdrx = 141;
            lsgdry = 264;
            lsgdrw = 262;
            lsgdrh = 22;
            lsgdtx = 160;
            lsgdty = 280;
            ultsx = 360;
            ultsy = 280;
            // offset = 5;
            insetRecX = 18;
            insetRecY = 211;
            insetRecW = 120;
            insetRecH = 75;
            insetMapX = 19;
            insetMapY = 212;
            insetMapW = 118;
            insetMapH = 73;
            break;
          }
          case "L2": {
            textx = 205;
            texty = 35;
            compassx = 350.5;
            compassy = 220;
            compassw = 23;
            compassh = 23;
            borx = 14;
            bory = 11;
            borw = 393;
            borh = 279;
            imgx = 21;
            imgy = 51;
            imgw = 382;
            imgh = 157;
            size = 25;
            legendx = 143;
            legendy = 219;
            srectx = 342;
            srecty = 247;
            srectw = 40;
            srecth = 3;
            stextx = 358;
            stexty = 245;
            crtextx = 15;
            crtexty = 294;
            crfont = 10;
            sfont = 10;
            xoffset = 0;
            yoffset = 5;
            ylimit = 250;
            hrx = 18;
            hry = 15;
            hrw = 385;
            hrh = 30;
            scrx = 321;
            scry = 211;
            scrw = 82;
            scrh = 50;
            lgrx = 141;
            lgry = 211;
            lgrw = 177;
            lgrh = 50;
            lgfx = 143;
            lgfy = 216;
            lsgdrx = 141;
            lsgdry = 264;
            lsgdrw = 262;
            lsgdrh = 22;
            lsgdtx = 160;
            lsgdty = 280;
            ultsx = 360;
            ultsy = 280;
            // offset = 5;
            insetRecX = 18;
            insetRecY = 211;
            insetRecW = 120;
            insetRecH = 75;
            insetMapX = 19;
            insetMapY = 212;
            insetMapW = 118;
            insetMapH = 73;
            break;
          }
          case "L3": {
            textx = 205;
            texty = 35;
            compassx = 350.5;
            compassy = 15;
            compassw = 23;
            compassh = 23;
            borx = 14;
            bory = 11;
            borw = 393;
            borh = 279;
            imgx = 21;
            imgy = 51;
            imgw = 382;
            imgh = 157;
            size = 25;
            legendx = 143;
            legendy = 219;
            srectx = 342;
            srecty = 41;
            srectw = 40;
            srecth = 3;
            stextx = 358;
            stexty = 40;
            crtextx = 15;
            crtexty = 294;
            crfont = 10;
            sfont = 10;
            xoffset = 0;
            yoffset = 5;
            ylimit = 250;
            hrx = 18;
            hry = 15;
            hrw = 300;
            hrh = 30;
            scrx = 321;
            scry = 15;
            scrw = 82;
            scrh = 30;
            lgrx = 141;
            lgry = 211;
            lgrw = 177;
            lgrh = 50;
            lgfx = 143;
            lgfy = 216;
            lsgdrx = 141;
            lsgdry = 264;
            lsgdrw = 262;
            lsgdrh = 22;
            lsgdtx = 160;
            lsgdty = 280;
            ultsx = 360;
            ultsy = 280;
            pdf.setLineWidth(0.5);
            pdf.setDrawColor(0);
            pdf.rect(321, 211, 82, 50); //rectangle outside map
            pdf.setFontSize(15);
            pdf.setFont("Arial");
            pdf.text(323, 216, "Approved By: ");
            // offset = 5;
            insetRecX = 18;
            insetRecY = 211;
            insetRecW = 120;
            insetRecH = 75;
            insetMapX = 19;
            insetMapY = 212;
            insetMapW = 118;
            insetMapH = 73;
            break;
          }
          case "L4": {
            textx = 155;
            texty = 35;
            compassx = 240.5;
            compassy = 17;
            compassw = 24;
            compassh = 23;
            borx = 14;
            bory = 11;
            borw = 269;
            borh = 393;
            imgx = 21;
            imgy = 51;
            imgw = 258;
            imgh = 145;
            size = 25;
            legendx = 19;
            legendy = 215;
            srectx = 342;
            srecty = 41;
            srectw = 40;
            srecth = 3;
            stextx = 358;
            stexty = 40;
            crtextx = 15;
            crtexty = 408;
            crfont = 10;
            sfont = 10;
            xoffset = 0;
            yoffset = 5;
            ylimit = 250;
            hrx = 18;
            hry = 15;
            hrw = 203;
            hrh = 30;
            scrx = 226;
            scry = 15;
            scrw = 52;
            scrh = 30;
            lgrx = 18;
            lgry = 200;
            lgrw = 261;
            lgrh = 97;
            lgfx = 21;
            lgfy = 207;
            lsgdrx = 18;
            lsgdry = 378;
            lsgdrw = 261;
            lsgdrh = 22;
            lsgdtx = 32;
            lsgdty = 392;
            ultsx = 360;
            ultsy = 280;
            insetRecX = 18;
            insetRecY = 300;
            insetRecW = 120;
            insetRecH = 75;
            insetMapX = 19;
            insetMapY = 301;
            insetMapW = 118;
            insetMapH = 73;
            // offset = 5;
            break;
          }
        }

        break;
      }
      case "a4": {
        textx = 135;
        texty = 25;
        compassx = 20;
        compassy = 30;
        compassw = 35;
        compassh = 35;
        imgx = 16;
        imgy = 30;
        imgw = 200;
        imgh = 130;
        size = 15;
        legendx = 217;
        legendy = 30;
        srectx = 16;
        srecty = 155;
        srectw = 35;
        srecth = 5;
        stextx = 30;
        stexty = 159;
        crtextx = 16;
        crtexty = 163;
        crfont = 10;
        sfont = 10;
        xoffset = 0;
        yoffset = 5;
        ylimit = 850;
        hrx = 18;
        hry = 15;
        hrw = 300;
        hrh = 30;
        scrx = 0;
        scry = 0;
        scrw = 0;
        scrh = 0;
        lgrx = 0;
        lgry = 0;
        lgrw = 0;
        lgrh = 0;
        lgfx = 0;
        lgfy = 0;
        lsgdrx = 0;
        lsgdry = 0;
        lsgdrw = 0;
        lsgdrh = 0;
        lsgdtx = 0;
        lsgdty = 0;
        ultsx = 0;
        ultsy = 0;
        insetRecX = 18;
        insetRecY = 211;
        insetRecW = 120;
        insetRecH = 75;
        insetMapX = 19;
        insetMapY = 212;
        insetMapW = 118;
        insetMapH = 73;
        break;
      }
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var date = dd + "/" + mm + "/" + yyyy;
    pdf.addImage(data, "JPEG", imgx, imgy, imgw, imgh); //Map Image
    pdf.setDrawColor(0);
    pdf.setLineWidth(1.0);
    pdf.rect(borx, bory, borw, borh); //rectangle for border
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(0);
    pdf.rect(imgx, imgy, imgw, imgh); //rectangle outside map
    var xspace = imgw / 7;
    var yspace = imgh / 5;
    pdf.setFillColor(0);
    pdf.rect(imgx - 1, imgy - 1, 2, 2, "F"); //rectangle for scale
    pdf.setFillColor(0);
    pdf.rect(imgx + imgw / 2 - 1, imgy - 1, 2, 2, "F"); //small squares around map
    pdf.rect(imgx + imgw - 1, imgy - 1, 2, 2, "F");
    pdf.rect(imgx + imgw - 1, imgy + imgh / 2 - 1, 2, 2, "F");
    pdf.rect(imgx + imgw - 1, imgy + imgh - 1, 2, 2, "F");
    pdf.rect(imgx + imgw / 2 - 1, imgy + imgh - 1, 2, 2, "F");
    pdf.rect(imgx - 1, imgy + imgh - 1, 2, 2, "F");
    pdf.rect(imgx - 1, imgy + imgh / 2 - 1, 2, 2, "F");
    pdf.setDrawColor(0);
    pdf.line(imgx + xspace, imgy, imgx + xspace, imgy - 2); //small lines around map
    pdf.line(imgx + 2 * xspace, imgy, imgx + 2 * xspace, imgy - 2);
    pdf.line(imgx + 3 * xspace, imgy, imgx + 3 * xspace, imgy - 2);
    pdf.line(imgx + 4 * xspace, imgy, imgx + 4 * xspace, imgy - 2);
    pdf.line(imgx + 5 * xspace, imgy, imgx + 5 * xspace, imgy - 2);
    pdf.line(imgx + 6 * xspace, imgy, imgx + 6 * xspace, imgy - 2);
    //pdf.line(imgx+7*xspace,imgy,imgx+7*xspace,imgy-2);
    pdf.line(imgx, imgy + yspace, imgx - 2, imgy + yspace);
    pdf.line(imgx, imgy + 2 * yspace, imgx - 2, imgy + 2 * yspace);
    pdf.line(imgx, imgy + 3 * yspace, imgx - 2, imgy + 3 * yspace);
    pdf.line(imgx, imgy + 4 * yspace, imgx - 2, imgy + 4 * yspace);
    //pdf.line(imgx,imgy+5*yspace,imgx-2,imgy+5*yspace);
    pdf.setLineWidth(0.5);
    var topextent = [],
      bottomextent = [];
    topextent = transform(
      [mapComponent.frameState_.extent[0], mapComponent.frameState_.extent[1]],
      "EPSG:3857",
      "EPSG:4326"
    );
    bottomextent = transform(
      [mapComponent.frameState_.extent[2], mapComponent.frameState_.extent[3]],
      "EPSG:3857",
      "EPSG:4326"
    );
    var latoffset = (bottomextent[1] - topextent[1]) / 5;
    var longoffset = (bottomextent[0] - topextent[0]) / 8;
    pdf.setTextColor(0);
    pdf.setFontSize(8);
    pdf.setFont("Arial");
    //Adding lat and lon in the top and left of  map image
    pdf.text(
      imgx - 10 + xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 2 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 2 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 3 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 3 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 4 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 4 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 5 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 5 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 6 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 6 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 3,
      imgy + yspace + 10,
      this.toDegreesLat([topextent[0], topextent[1] + 5 * latoffset]),
      null,
      90
    );
    pdf.text(
      imgx - 3,
      imgy + 2 * yspace + 10,
      this.toDegreesLat([topextent[0], topextent[1] + 4 * latoffset]),
      null,
      90
    );
    pdf.text(
      imgx - 3,
      imgy + 3 * yspace + 10,
      this.toDegreesLat([topextent[0], topextent[1] + 3 * latoffset]),
      null,
      90
    );
    pdf.text(
      imgx - 3,
      imgy + 4 * yspace + 10,
      this.toDegreesLat([topextent[0], topextent[1] + 2 * latoffset]),
      null,
      90
    );
    pdf.setDrawColor(0);
    pdf.rect(hrx, hry, hrw, hrh); //rectangle for headings
    pdf.setDrawColor(0);
    pdf.rect(scrx, scry, scrw, scrh); //rectangle for scale and compass
    pdf.setTextColor(0);
    pdf.setFontSize(30);
    pdf.text(heading, (hrw + hrx + hrx) / 2, hry + 15, "center");
    pdf.setFontSize(20);
    pdf.text(subheading, (hrw + hrx + hrx) / 2, hry + 25, "center");
    pdf.setDrawColor(0);
    pdf.rect(lgrx, lgry, lgrw, lgrh); //rectangle for legends
    pdf.setTextColor(0);
    pdf.setFontSize(15);
    if (layout === "L4") {
      pdf.rect(140, 300, 139, 75);
    }
    pdf.text(lgfx, lgfy, "Legend");
    pdf.rect(lsgdrx, lsgdry, lsgdrw, lsgdrh); //rectangle for LSGD
    pdf.setTextColor(0);
    pdf.setFontSize(12);
    pdf.text(lsgdtx + 50, lsgdty - 10, "Project :");
    pdf.setFontSize(14);
    pdf.text(
      lsgdtx,
      lsgdty,
      "INTELLIGENT PROPERTY MANAGEMENT SYSTEM - " + text.split(" ")[0]
    );
    pdf.setTextColor(0);
    pdf.setFontSize(12);
    pdf.text(ultsx - 6, ultsy - 10, "Developed by : ");
    pdf.setFontSize(14);
    pdf.text(ultsx, ultsy, "ULCCS LTD");
    var scale = mapComponent.controls.array_[3];
    pdf.setDrawColor(0);
    pdf.rect(srectx, srecty, srectw, srecth); //rectangle for scale
    pdf.setFillColor(0); //scale
    var w = srectw / 4;
    pdf.rect(srectx, srecty, w, srecth, "F");
    pdf.rect(srectx + w, srecty, w, srecth);
    pdf.rect(srectx + 2 * w, srecty, w, srecth, "F");
    pdf.rect(srectx + 3 * w, srecty, w, srecth);
    pdf.setTextColor(0);
    pdf.setFontSize(sfont);
    pdf.text(stextx, stexty, scale.renderedHTML_);
    pdf.setTextColor(0);
    pdf.setFontSize(crfont);
    pdf.setFont("SimSun");
    pdf.text(crtextx, crtexty, "Copyright © 2020 " + text);
    pdf.text(380, 294, "Date : " + date);
    //Compass image adding to pdf
    img1.onload = function() {
      var canvas1 = document.createElement("canvas"),
        ctx1 = canvas1.getContext("2d");
      canvas1.height = img1.naturalHeight;
      canvas1.width = img1.naturalWidth;
      ctx1.drawImage(img1, 0, 0);
      var imgData = canvas1.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", compassx, compassy, compassw, compassh);
    };
    //Adding inset map to pdf
    img2.onload = function() {
      var canvas2 = document.createElement("canvas"),
        ctx2 = canvas2.getContext("2d");
      canvas2.height = img2.naturalHeight;
      canvas2.width = img2.naturalWidth;
      ctx2.drawImage(img2, 0, 0);
      pdf.setDrawColor(0);
      pdf.rect(insetRecX, insetRecY, insetRecW, insetRecH); //rectangle for Insetmap
      var imgData = canvas2.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", insetMapX, insetMapY, insetMapW, insetMapH);
    };
    //Adding LSGD emblem to pdf
    img3.onload = function() {
      var canvas3 = document.createElement("canvas"),
        ctx3 = canvas3.getContext("2d");
      canvas3.height = img3.naturalHeight;
      canvas3.width = img3.naturalWidth;
      ctx3.drawImage(img3, 0, 0);
      var imgData = canvas3.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", lsgdtx - 12, lsgdty - 7, 10, 10);
    };
    //Adding ULCCS emblem to pdf
    img4.onload = function() {
      var canvas4 = document.createElement("canvas"),
        ctx4 = canvas4.getContext("2d");
      canvas4.height = img4.naturalHeight;
      canvas4.width = img4.naturalWidth;
      ctx4.drawImage(img4, 0, 0);
      var imgData = canvas4.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", ultsx - 12, ultsy - 7, 10, 10);
    };
    var layers = this.props.mapComponent.values_.layergroup.values_.layers
      .array_[1].values_.layers.array_;
    var offset = 0;
    var legends = [];
    layers.forEach((element) => {
      if (
        element.values_.visible &&
        this.state.selectedLayers.indexOf(element.values_.name) >= 0
      ) {
        var layer = element.values_.name;
        let layerName = element.getSource().getParams().LAYERS;
        var url =
          element.values_.source.urls[0] +
          "?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/jpeg&WIDTH=20&HEIGHT=20&LAYER=" +
          element.values_.source.params_.LAYERS +
          "&LEGEND_OPTIONS=forceLabels:on;fontName:Lucida%20Sans%20Regular";
        legends.push(url);
      }
    });
    var images = [];
    const legendUrls = this.getLegendUrls();
    legendUrls.forEach((urlObj) => {
      var url = urlObj.url;
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      img.onload = function() {
        var canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d");
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        var uri = {
          uri: canvas.toDataURL("image/png"),
          h: canvas.height,
          w: canvas.width,
        };
        images.push(uri); //-> "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWP4z8DwHwAFAAH/q842iQAAAABJRU5ErkJggg=="
        if (images.length == legends.length) {
          var offset = 0;
          var height = 0;
          var coulumns = 0;
          images.forEach((element) => {
            if (element.h + offset + height >= ylimit - 70) {
              xoffset = xoffset + 50;
              offset = 0;
              height = 0;
              coulumns = coulumns + 1;
            } else {
              // offset += 5;
            }
            height = height + element.h;
            if (
              (layout == "L1" && coulumns < 5) ||
              (layout != "L1" && coulumns < 3)
            ) {
              pdf.addImage(
                element.uri,
                "JPEG",
                legendx + xoffset,
                legendy + offset
              );
              offset = offset + element.h / 4;
            }
          });
          setTimeout(function() {
            window.open(pdf.output("bloburl"), "_blank");
          }, 3000);
        }
      };
    });
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    this.setState({ heading: "" });
    this.setState({ subHeading: "" });
  };

  getLegendUrls = () => {
    const { mapLayers } = this.props;
    let layerTree = getLayerTree();
    let urls = [];
    for (let i = 0; i < mapLayers.length; i++) {
      if (mapLayers[i].getVisible() && mapLayers[i].type == "TILE") {
        let layer = mapLayers[i].getSource().getParams().LAYERS;
        let layerName = "";
        let layerNameValue = mapLayers[i].getProperties().name;
        let url = null;
        let style = null;
        layerTree.map((treeItem) => {
          if (treeItem.hasOwnProperty("children")) {
            treeItem.children.forEach((layer) => {
              if (layer.value == layerNameValue) {
                layerName = layer.label;
                if (layer.style != null) style = layer.style;
              }
            });
          }
        });
        if (style == null) {
          url =
            mapLayers[i].getSource().urls[0] +
            "?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/jpeg&WIDTH=20&HEIGHT=20&LAYER=" +
            layer +
            "&LEGEND_OPTIONS=forceLabels:on;fontName:Lucida%20Sans%20Regular";
        } else {
          url =
            mapLayers[i].getSource().urls[0] +
            "?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/jpeg&WIDTH=20&HEIGHT=20&LAYER=" +
            layer +
            "&STYLE=" +
            style +
            "&LEGEND_OPTIONS=forceLabels:on;fontName:Lucida%20Sans%20Regular";
        }
        var element = {};
        element.layerName = layerName;
        element.url = url;
        element.layer = layerNameValue;

        urls.push(element);
      }
    }
    // this.setState({legendUrls:urls})
    // this.props.updateLegendUrls(urls);
    return urls;
  };

  handleOrientationChange = (e) => {
    this.setState({
      ...this.state,
      orientation: e.target.value,
      layout: e.target.value === "landscape" ? "L4" : "L1",
    });
    e.target.value == "portraite" && this.props.setZoom();
  };

  componentDidMount() {}
  render() {
    var allLayers = [];
    var layers = [];
    if (this.props.mapComponent)
      layers = this.props.mapComponent.values_.layergroup.values_.layers
        .array_[1].values_.layers.array_;
    // var offset = 0;
    // var legends = [];
    layers.forEach((element) => {
      if (element.values_.visible) {
        var layer = element.values_.name;
        allLayers.push(layer);
      }
    });
    if (
      this.state.selectedLayers.length != allLayers.length &&
      allLayers.length > 0 &&
      (this.state.selectedLayers.length == 0 ||
        allLayers.length != this.state.allLayers.length)
    ) {
      this.setState({ selectedLayers: allLayers });
      this.setState({ allLayers: allLayers });
    }
    const { showWidget } = this.props;
    if (showWidget === "3") {
      return (
        <OverlayPanel
          showControl={this.props.showExportPanelControl}
          handleShowControl={this.props.handleShowExportPanelControl}
          titleName="Export To PDF"
          headClass="exportPanels"
          bodyClass="exportPanelsBody"
        >
          <form>
            <p>
              Disclaimer : Please enable atleast 1 layer before taking print
            </p>

            <FormGroup controlId="formControlsText">
              <ControlLabel>Heading</ControlLabel>
              <FormControl
                componentClass="input"
                name="heading"
                placeholder="Heading (max length 40)"
                className="btn_select"
                onChange={(e) => this.handleChange(e, { maxLength: 40 })}
                value={this.state.heading}
              />
              <ControlLabel>Sub Heading</ControlLabel>
              <FormControl
                componentClass="input"
                name="subHeading"
                placeholder="Sub Heading (max length 80)"
                onChange={(e) => this.handleChange(e, { maxLength: 80 })}
                value={this.state.subHeading}
              />
            </FormGroup>
            <FormGroup controlId="formControlsSelect">
              <ControlLabel>Page size</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                onChange={this.handleChangePageSize}
                value={this.state.pageSize}
              >
                {/* <option value="a0">A0</option> */}
                <option value="a3">A3</option>
                {/* <option value="a4">A4</option> */}
              </FormControl>
              <ControlLabel>Orientation</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                name="layout"
                onChange={this.handleOrientationChange}
                value={this.state.orientation}
              >
                <option value="landscape">Landscape</option>
                <option value="portraite">Portraite</option>
              </FormControl>
              {this.state.orientation === "landscape" && (
                <>
                  <ControlLabel>Layout</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    name="layout"
                    onChange={this.handleChange}
                    value={this.state.layout}
                  >
                    <option value="L1">Layout 1</option>
                    <option value="L2">Layout 2</option>
                    <option value="L3">Layout 3</option>
                  </FormControl>
                </>
              )}

              <label
                style={{ fontWeight: "500", paddingTop: "10px" }}
                class="custom-control-label"
                for="defaultUnchecked"
              >
                Select layers for legends.
              </label>
              {allLayers.map((layer) => {
                return (
                  <div
                    class="custom-control custom-checkbox"
                    style={{ padding: "5px 0px" }}
                    key={"select-legend-key-" + layer}
                  >
                    <input
                      onChange={(e) => this.legendLayerSelected(e, layer)}
                      type="checkbox"
                      class="custom-control-input"
                      id={"defaultUnchecked" + layer}
                      checked={this.state.selectedLayers.indexOf(layer) >= 0}
                    />
                    <label
                      style={{ paddingLeft: "10px" }}
                      class="custom-control-label"
                      for={"defaultUnchecked" + layer}
                    >
                      {layer}
                    </label>
                  </div>
                );
              })}
              {/* <ControlLabel>Resolution </ControlLabel>
                        <FormControl
                          componentClass="select"
                          placeholder="select"
                          name="resolution"
                          value={this.state.resolution}
                          onChange={this.handleChange}>
                          <option value="72" defaultValue>
                            72 dpi (fast)
                          </option>
                          <option value="150">150 dpi</option>
                          <option value="300">300 dpi (slow)</option>
                        </FormControl> */}
            </FormGroup>
          </form>
          <div className="exportButtonContainer">
            <div className="exportButtons">
              <Button
                bsStyle="success"
                bsSize="small"
                className="btn_submit"
                onClick={this.handleExportPdf}
                disabled={this.state.heading == ""}
              >
                Export PDF
              </Button>
            </div>
          </div>
        </OverlayPanel>
      );
    } else return null;
  }
  legendLayerSelected = (e, layer) => {
    var layers = [...this.state.selectedLayers];
    var index = layers.indexOf(layer);
    if (index < 0) layers.push(layer);
    else layers.splice(index, 1);
    this.setState({ selectedLayers: layers });
  };
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    legendUrls: state.mapReducer.legendUrls,
    showWidget: state.mapReducer.showWidget,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportPdfPanel);
