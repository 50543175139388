import React, { Component } from "react";
import { Button } from "react-bootstrap";
import LocalStorageHandler from "../../../../../util/storage";
import jsPDF from "jspdf";

class PdfReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  
  handleRoadDetailPreview = () => {
    if (this.props.detailedInfoContents != null) {
      const { selectedLayer } = this.props;
      let marginLeft;
      var layerName = "";
      if (selectedLayer) {
        layerName = selectedLayer.getProperties().name;
      }
      const image = this.props.renderImage();
      const img_url = LocalStorageHandler.getLocalBody().image_url;
      // console.log("image url",image);
      var doc = jsPDF();
      doc.setProperties({
        title: "Details",
      });

      let datas = []
  if(this.props.detailedInfoContents)
  {
    const details = this.props.detailedInfoContents[this.props.count]
    Object.keys(details).map((items)=>{
      // let finalY = doc.lastAutoTable.finalY; // The y position on the page
      if (
        !items.includes("photo") &&
        !items.includes("p_d_f_") 
      )
     { 
      let head = details[items].display_name
      let body = details[items].value
    datas.push({
      head,body
    })
  }
  })
}

      doc.autoTable({
        startY: doc.autoTable() + 90,
        body : datas,
        didDrawPage: function(data) {
          // Header
          doc.setFontSize(15);
          doc.setTextColor(40);
          doc.text("Basic Details", data.settings.margin.left, 15);
          marginLeft = data.settings.margin.left;
        }, 
      });

      this.props.detailedInfoContentsForeignKey[
        this.props.count
      ].related_tables.map((data) => {
        if (data.related_table === "members" && data.fields.length > 0) {
          // let finalY = doc.lastAutoTable.finalY; // The y position on the page
          // doc.text(marginLeft, finalY + 10, "Member Details");
          // let head = Object.keys(data.fields[0]);
          // let body = data.fields.map((data) => {
          //   let val = Object.values(data);
          //   val = val.map((data) => {
          //     if (data === "false") {
          //       return "NO";
          //     } else if (data === "true") {
          //       return "YES";
          //     } else if (data === "NR") {
          //       return "NOT RECEIVED";
          //     } else if (data.constructor === Array) {
          //       if (data.length > 0) return data.join(", ");
          //       else return "No data";
          //     } else {
          //       return data;
          //     }
          //   });
          //   return val;
          // });
          // doc.autoTable({
          //   startY: doc.autoTable.previous.finalY + 20,
          //   head: [head],
          //   body: body,
          // });
          return 0;
        }
        if (
          data.related_table === "floor_prop_area" &&
          data.fields.length > 0
        ) {
          let finalY = doc.lastAutoTable.finalY; // The y position on the page
          doc.text(marginLeft, finalY + 10, "Area Details");
          let head = Object.keys(data.fields[0]);
          let body = data.fields.map((data) => {
            return Object.values(data);
          });
          doc.autoTable({
            startY: doc.autoTable.previous.finalY + 20,
            head: [head],
            body: body,
          });
          return 0;
        }
        if (
          data.related_table === "property_roof_type" &&
          data.fields.length > 0
        ) {
          let finalY = doc.lastAutoTable.finalY; // The y position on the page
          doc.text(marginLeft, finalY + 10, "Roof Details");
          let head = Object.keys(data.fields[0]);
          let body = data.fields.map((data) => {
            return Object.values(data);
          });
          doc.autoTable({
            startY: doc.autoTable.previous.finalY + 20,
            head: [head],
            body: body,
          });
          return 0;
        }
        if (
          data.fields.length > 0 &&
          !(
            layerName == "Residential" && data.related_table === "establishment"
          ) &&
          !(
            layerName !== "Residential" &&
            data.related_table === "property_Other_Details"
          )
        ) {
          let finalY = doc.lastAutoTable.finalY; // The y position on the page
          doc.text(marginLeft, finalY + 10, data.display_name);
          //object to array
          let body = Object.entries(data.fields[0]);
          doc.autoTable({
            startY: doc.autoTable.previous.finalY + 20,
            head: [],
            body: body,
          });
          return 0;
        }
      });
      image.map((item,index) => {
        console.log('image item',item," - index",index);
       
        const row = Math.floor(index / 2);
        const col = index % 2;
      
        const position = col * (85 + 10) + 10;
        const finalY = doc.lastAutoTable.finalY + row * (60 + 10) + 10;
        // let finalY = doc.lastAutoTable.finalY + 10; // The y position on the page
        doc.addImage(img_url + item, "JPEG", position, finalY, 85, 60);
        marginLeft += 95;
      });
  
      window.open(doc.output("bloburl"), "_blank");
    }
  };

  render() {
    console.log('propssPdfreport',this.props);
    return (
      <Button className="editAddButton" onClick={this.handleRoadDetailPreview}>
        Print Details 
      </Button>
    );
  }
}
export default PdfReport;
