import React, { Component } from "react";
import {
  Modal,
  Button,
  Col,
  FormGroup,
  ControlLabel,
  Row,
  FormControl,
} from "react-bootstrap";
class ConfirmBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: this.props.saveTemplateFileName
        ? this.props.saveTemplateFileName
        : "",
    };
  }
  handleInputChangeName = (el) => {
    this.setState({ fileName: el.target.value });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        style={{ top: "25%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Template</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row style={{ width: "100%" }}>
            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup required>
                <ControlLabel style={{ fontWeight: "bold" }}>
                  File name
                </ControlLabel>
                <FormControl
                  type="input"
                  placeholder="Enter file name"
                  value={this.props.saveTemplateFileName}
                  name="File name"
                  onChange={(el) => this.props.handleInputChangeName(el)}
                  required
                  // disabled={this.props.saveTemplateFileName.length === 0}
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>

        {!this.props.isNotSelected ? (
          <Modal.Footer>
            <Button bsStyle="danger" onClick={this.props.action}>
              Yes
            </Button>
            <Button bsStyle="success" onClick={this.props.onHide}>
              No
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              bsStyle="success"
              onClick={() => this.props.action(this.state.fileName)}
              disabled={this.props.saveTemplateFileName.length === 0}
            >
              OK
            </Button>
            <Button bsStyle="danger" onClick={this.props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default ConfirmBox;
