const attributes = {
  land_name: "LANDMARK",
  category: "CATEGORY",
  sub_cat: "SUB CATEGORY",

  sche_id: "SCHEME ID",
  scheme_nam: "SCHEME NAME",
  comm_year: "COMMISSIONED YEAR",
  dom_con: "DOMESTIC CONNECTION",
  nondom_con: "NONE DOMESTIC CONNECTION",
  ind_con: "INTESTRIAL CONNECTION",
  taps: "STREET TAP",
  bene_popul: "BENEFICIARY POPULATION",
  bene_panch: "BENEFICIARY PANCHAYATH",
  section: "SECTION",
  sub_divi: "SUB DIVISION",
  divi: "DIVISION",

  consumer_n: "CONSUMER NAME",
  build_name: "BUILDING NAME",
  build_no_: "BUILDING NUMBER",
  panchayat: "PANCHAYATH",
  road_name: "ROAD NAME",
  conn_type: "CONNECTION TYPE",

  juri_type: "JURISDICTION TYPE",
  jurisdicti: "JURISDICTION",

  type: "TYPE",
  area: "AREA (Sq. m)",
  ownership: "OWNERSHIP",
  survey_num: "SURVEY NUMBER",
  no_stories: "NO OF STORIES",
  build_type: "BUILDING TYPE",
  contr_year: "CONSTRUCTION YEAR",
  constr_cos: "CONSTRUCTION COST",

  build_valu: "BUILDING VALUE",
  circle: "CIRCLE",
  region: "REGION",

  land_id: "LAND ID",
  book_value: "BOOK VALUE",
  acqui_date: "ACQUISITION DATE",

  motors_id: "MOTORS ID",
  pump_type: "PUMP TYPE",
  power: "POWER IN WATTS",
  purch_year: "PURCHASE YEAR",
  make: "MAKE",
  cost: "COST",

  road_type: "ROAD TYPE",
  carry_widt: "CARRY WIDTH (M)",
  berm_widt: "BERM WIDTH (M)",
  width: "WIDTH (M)",

  tap_id: "TAP ID",
  panch_name: "PANCHAYATH",
  tap_addr: "Tap ADDRESS",
  elepol_n1: "ELECTRIC POL 1",
  elepol_n2: "ELECTRIC POL 2",
  dist_nrtap: "DIST_NRTAP",
  tap_stat: "TAP STATUS",

  river_id: "RIVER_ID",
  river_nam: "RIVER NAME",
  no_wss: "NO OF WATER SUPPLY SCHEME",

  valve_id: "VALVE ID",
  closin_dir: "CLOSING DIRECTION",
  no_turns: "NO OF TURNS",

  tank_id: "TANK ID",
  tank_type: "TANK TYPE",
  capacity: "CAPACITY",
  sub_divisi: "SUB DIVISION",

  village: "VILLAGE",
  object_id: "WELL ID",

  pipelin_id: "PIPELINE ID",
  pipe_type: "PIPE TYPE",
  thickness: "THICKNESS",
  length: "LENGTH",
  diameter: "DIAMETER",
  year_lay: "LAYING YEAR",

  municipal: " MUNICIPALITY ",
  ward_no: " WARD NUMBER ",
  ward_name: " WARD NAME ",
  district: " DISTRICT ",
  property_i: " GIS ID ",
  propertyid: " OLD BUILDING NUMBER ",
  propertyi2: " NEW BUILDING NUMBER ",
  bld_name: " BUILDING NAME ",
  owner_name: " OWNER NAME ",
  add_own: " OWNER ADDRESS ",
  postoff: " POST OFFICE ",
  ownoccupat: " OWNER OCCUPATION ",
  streetname: " STREET NAME ",
  place_name: " PLACE NAME ",
  ph_no_own: " OWNER PHONE NUMBER ",
  owneremail: " OWNER EMAIL ",
  survey_no: " SURVEY NUMBER ",
  villagenam: " VILLAGE NAME ",
  bldg_zone: " BUILDING ZONE ",
  bldg_statu: " BUILDING STATUS ",
  bldg_type: " BUILDING TYPE ",
  year_const: " CONSTRUCTION YEAR ",
  bldg_usage: " BUILDING USAGE ",
  tenant: " TENANT NAME ",
  add_tent: " TENANT ADDRESS ",
  ph_no_tent: " TENANT PHONE ",
  tent_email: " TENANT EMAIL ",
  rent_amunt: " RENT AMOUNT ",
  estab_name: " ESTABLISHMENT NAME ",
  incharname: " INCHARGE NAME ",
  estab_year: " ESTABLISHMENT YEAR ",
  incharpost: " INCHARGE POST ",
  inform_by: " INFORMATION BY ",
  members: " MEMBERS ",
  other_bldg: " OTHER BUILDING ",
  ac: " AIR CONDITIONER ",
  no_of_ac: " NUMBER OF AC ",
  strtype: " STRUCTURE TYPE ",
  no_floor: " NUMBER OF FLOORS ",
  no_of_room: " NUMBER OF ROOM ",
  subbasemen: " SUB BASEMENT AREA ",
  basement: " BASEMENT AREA ",
  grdflr_are: " GROUND FLOOR AREA ",
  carporch: " CAR PORCH AREA ",
  walltype: " WALL TYPE ",
  rooftype: " ROOF TYPE ",
  rf_cvrstat: " ROOF COVER STATUS ",
  rf_cvrperc: " ROOF COVER PERCENTAGE ",
  floor_type: " FLOOR TYPE ",
  gld_wdarea: " GLAZED WOOD AREA ",
  bathroom: " BATH ROOM ",
  latrine: " LATRINE ",
  water_conn: " WATER CONNECTION ",
  elect_conn: " ELECTRICITY CONNECTION ",
  near_road: " NEAR ROAD ",
  road_width: " ROAD WIDTH ",
  roadtype: " ROAD TYPE ",
  currenttax: " TAX YEAR ",
  currentta2: " TAX RECEIPT ",
  currentta3: " TAX DATE ",
  currentta4: " TAX AMOUNT ",
  surveydate: " SURVEY DATE ",
  photo: " PHOTO ",
  photo2: " PHOTO2 ",
  remarks: " REMARKS ",
  flr_area_1: "1ST FLOOR AREA",
  flr_area_2: "2ND FLOOR AREA",
  flr_area_3: "3RD FLOOR AREA",
  flr_area_4: "4TH FLOOR AREA",
  flr_area_5: "5TH FLOOR AREA",
  flr_area_6: "6TH FLOOR AREA",
  flr_area_7: "7TH FLOOR AREA",
  flr_area_8: "8TH FLOOR AREA",
  flr_area_9: "9TH FLOOR AREA",
  flr_are_10: "10TH FLOOR AREA",
  flr_are_11: "11TH FLOOR AREA",
  flr_are_12: "12TH FLOOR AREA",
  flr_are_13: "13TH FLOOR AREA",
  flr_are_14: "14TH FLOOR AREA",

  wardno: "WARD NUMBER",
  wardname: "WARD NAME",
  zone: "ZONE",
  name: "NAME",
  placename: "PLACE NAME",
  street_na: "STREET NAME",
  category2: "CONSTRUCTION MATERIAL",
  catagory: "CATEGORY",
  sub_catego: "SUB CATEGORY",
  start_poin: "STARTING POINT",
  end_point: "ENDING POING",
  road_cat: "ROAD CATEGORY",
  road_cat2: "SURFACE CATEGORY ",

  c_h_p_ovr: "OVERALL",
  c_h_p_doc: "DOCUMENTATION",
  c_h_p_con: "CONSTRUCTION",
  project_id: "PROJECT ID",
  proje_code: "PROJECT CODE",
  proje_name: "PROJECT NAME",
  location: "LOCATION",
  state: "STATE",
  taluk: "TALUK",
  Panchayath: "PANCHAYATH",
  panchayath: "PANCHAYATH",
  ward: "WARD",
  issue_stat: "ISSUE STATUS",
  link: "LINK",

  municipali: " MUNICIPALITY  ",
  gis_id: " GIS_ID  ",
  old_pro_id: " OLD PROPERTY ID ",
  new_pro_id: " NEW PROPERTY ID ",
  bldg_name: "  BUILDING NAME ",
  owner_add: "  OWNER ADDRESS ",
  post_offic: " POST OFFICE ",
  street_nam: " STREET NAME ",
  owner_occu: " OWNER OCCUPATION  ",
  owner_phno: " OWNER PHONE NUMBER  ",
  ten_native: " TENENT NATIVE ",
  incharrole: " INCHARGE ROLE ",
  no_employe: " NUMBER OF EMPLOYEE  ",
  licence: "  LICENCE ",
  licence_no: " LICENCE NO  ",
  no_membrs: "  NUMBER OF MEMBRS  ",
  details_m1: " MEMBER 1 DETAILS  ",
  details_m2: " MEMBER 2 DETAILS  ",
  details_m3: " MEMBER 3 DETAILS  ",
  details_m4: " MEMBER 4 DETAILS  ",
  details_m5: " MEMBER 5 DETAILS  ",
  details_m6: " MEMBER 6 DETAILS  ",
  details_m7: " MEMBER 7 DETAILS  ",
  details_m8: " MEMBER 8 DETAILS  ",
  details_m9: " MEMBER 9 DETAILS  ",
  detail_m10: " MEMBER 10 DETAILS ",
  detail_m11: " MEMBER 11 DETAILS ",
  detail_m12: " MEMBER 12 DETAILS ",

  more_membe: " MORE MEMBERS  ",
  religion: " RELIGION  ",
  rationcard: " RATIONCARD  ",
  death_1yea: " DEATH WITH IN 1 YEAR  ",
  cause_deat: " CAUSE OF DEATH  ",
  age_job: "  AGE_JOB ",
  widow: "  WIDOW ",
  widow_penn: " WIDOW PENSION ",
  other_pens: " OTHER PENSION ",
  any_diseas: " ANY DISEASES  ",
  disabiliti: " DISABILITI  ",
  pet_dog: "  PET DOG ",
  cattles: "  CATTLES ",
  poultry_de: " POULTRY ",
  whichfloor: " WHICH FLOOR ",
  not_led_: " NOT LED ",
  gas_connec: " GAS CONNECTION  ",
  well: " WELL  ",
  subcatgory: "SUB CATEGORY",
  lat: "LATITUDE",
  long: "LONGITUDE",
  bndry_name: "BOUNDARY NAME",
  flr_area_1: "1ST FLOOR AREA ",
  flr_area_2: "2ND FLOOR AREA  ",
  flr_area_3: "3RD FLOOR AREA  ",
  flr_area_4: "4TH FLOOR AREA  ",
  flr_area_5: "5TH FLOOR AREA  ",
  flr_area_6: "6TH FLOOR AREA  ",
  flr_area_7: "7TH FLOOR AREA  ",
  flr_area_8: "8TH FLOOR AREA  ",
  flr_area_9: "9TH FLOOR AREA  ",
  flrarea_10: "10TH FLOOR AREA ",
  flr_area_11: "11TH FLOOR AREA  ",
  flr_area_12: "12TH FLOOR AREA  ",
  house_id: "HOUSE ID",
  benefic_no: "BENEFICIARY NUMBER",
  // name:"BENEFICIARY NAME",
  address: "BENEFICIARY ADDRESS",
  pincode: "BENEFICIARY PINCODE",
  phone_no: "BENEFICIARY PHONE NUMBER",
  whatsapp_n: "BENEFICIARY WHATSAAP NO",
  email_id: "BENEFICIARY EMAIL ID",
  id_proof: "BENEFICIARY ID PROOF",
  id_number: "BENEFICIARY ID NUMBER",
  pin_code: "PIN CODE",
  meb1name: "MEMBER 1 NAME",
  meb1agesex: "MEMBER 1 AGE & SEX",
  meb1edujob: "MEMBER 1 EDUCATION & JOB",
  meb2name: "MEMBER 2 NAME",
  meb2agesex: "MEMBER 2 AGE & SEX",
  meb2edujob: "MEMBER 2 EDUCATION & JOB",
  meb3name: "MEMBER 3 NAME",
  meb3agesex: "MEMBER 3 AGE & SEX",
  meb3edujob: "MEMBER 3 EDUCATION & JOB",
  meb4name: "MEMBER 4 NAME",
  meb4agesex: "MEMBER 4 AGE & SEX",
  meb4edujob: "MEMBER 4 EDUCATION & JOB",
  meb5name: "MEMBER 5 NAME",
  meb5agesex: "MEMBER 5 AGE & SEX",
  meb5edujob: "MEMBER 5 EDUCATION & JOB",
  meb6name: "MEMBER 6 NAME",
  meb6agesex: "MEMBER 6 AGE & SEX",
  meb6edujob: "MEMBER 6 EDUCATION & JOB",
  meb7name: "MEMBER 7 NAME",
  meb7agesex: "MEMBER 7 AGE & SEX",
  meb7edujob: "MEMBER 7 EDUCATION & JOB",
  meb8name: "MEMBER 8 NAME",
  meb8agesex: "MEMBER 8 AGE & SEX",
  meb8edujob: "MEMBER 8 EDUCATION & JOB",
  meb9name: "MEMBER 9 NAME",
  meb9agesex: "MEMBER 9 AGE & SEX",
  meb9edujob: "MEMBER 9 EDUCATION & JOB",
  meb10name: "MEMBER 10 NAME",
  meb10agese: "MEMBER 10 AGE & SEX",
  meb10edujo: "MEMBER 10 EDUCATION & JOB",
  memberd: "MEMBER DETAILS",
  nolab: "NO OF LABERS",
  pensiond: "PENSIOND",
  area_ar: "AREA IN ASSESMENT REGISTER",
  area_atrbt: "SURVEYED AREA",
  difrnts: "AREA DIFFERENCE",
  bldng_ar: "BUILDING TYPE IN AR",
  bldng_atrb: "SURVEYED BUILDING TYPE",
  not_in_ar: "NOT IN AR",
  bldng_chan: "BUILDING TYPE CHANGE",

  waqf_id: "WAQF ID",
  w_prop_id: "PROPERTY ID",
  waqf_name: "WAQF NAME",
  property_n: "PROPERTY NAME",
  re_survey_: "RE-SURVEY NUMBER",
  area_cent: "AREA IN CENT",

  age: "AGE",
  sex: "SEX",
  education: "EDUCATION",
  occupation: "OCCUPATION",
  mar_status: "MERITAL STATUS",

  area_total: "AREA TOTAL",
  area_class: "AREA CLASS",

  income_cat: "INCOME CATEGORY",
  prop_id_ol: "OLD PROPERTY ID",
  apprve: "",
  building_n: "BUILDING NUMBER",
  prop_id_ne: "NEW PROPERTY ID",
  epidemic: "EPIDEMIC",
  phone: "PHONE NUMBER",
  name_of_ow: "OWNER NAME",
  MEMBERS: "NUMBER OF MEMBERS",
  date: "DATE",
  gisno: "GIS NUMBER",
  adress: "ADDRESS",
  email: "EMAIL ID",
  aadar_card: "AADAR CARD",

  build_add: "BUILDING ADDRESS",
  prop_of_bu: "BUILDING TYPE",
  constructi: "CONSTRUCTION MATERIAL",
  yr_of_cons: "YEAR OF CONSTRUCTION",
  owned__ren: "BUILDING USAGE",
  name_of_te: "NAME OF TENENT",
  tenant__ad: "TENENT ADDRESS",
  tnt_email: "TENENT EMAIL",
  tnt_ph_no: "TENENT PHONE NUMBER",
  name_of_es: "NAME OF ESTABLISHMENT",
  name_of_in: "NAME OF INCHARGE",
  designatio: "DESIGNATION",

  carpet_are: "CAR PORCH AREA",
  gd_floor: "GROUND FLOOR AREA",
  f1st_floor: "1ST FLOOR AREA",
  f2nd_floor: "2ND FLOOR AREA  ",
  f3rd_floor: "3RD FLOOR AREA  ",
  f4th_floor: "4TH FLOOR AREA  ",
  f5th_floor: "5TH FLOOR AREA  ",
  wall_type: "WALL TYPE",
  flooring: "FLOOR TYPE",
  roof: "ROOF TYPE",

  well_recha: "WELL RECHARGE",
  lined_un: "LINED OR UNLINED",
  waste_mang: "WASTE MANAGMENT",
  facing_roa: "FACING ROAD",
  width1: "",
  fincs_ass: "FINANCE ASSIST",
  fb_ac_no: "FACEBOOK ACCOUNT NO",
  tax__non_t: "TAX STATUS",
  year: "TAX YEAR",
  recpt_no: "TAX RECIEPT NUMBER",
  date1: "TAX DATE",
  surv_no: "SURVEY NUMBER",
  amount: "TAX AMOUND",
  welfare_pe: "WELFARE PENSION",
  type_of_pe: "TYPE OF PENSION",
  name_of_pe: "NAME OF PENSION",
  group_: "",
  mem_of_gvt: "MEMBER OF GOVERNMENT",
  vehicle_ty: "VEHICLE TYPE",
  vehicle_no: "VEHICLE NO",
  vehicle__1: "VEHICLE 1",
  vehicle__2: "VEHICLE 2",
  vehicle__3: "VEHICLE 3",
  branch: "BRANCH",
  ecs_status: "ECNOMIC STATUS",
  comment: "COMMENT",
  gps_date: "GPS DATE",
  wardnuber: "  WARD NUMBER ",
  aadharnum: "  AADHAR NUMER  ",
  estab_type: " ESTABLISHMENT TYPE  ",
  fourwhelr: "  FOUR WHEELER  ",
  watrinwell: " WATER IN WELL ",
  rain_whrvt: " RAINWATER HARVESTING  ",
  waste_mng: "  WASTE MANAGEMENT  ",
  m1_details: " MEMBER1 DETAILS ",
  m2_details: " MEMBER2 DETAILS ",
  m3_details: " MEMBER3 DETAILS ",
  m4_details: " MEMBER4 DETAILS ",
  m5_details: " MEMBER5 DETAILS ",
  m6_details: " MEMBER6 DETAILS ",
  m7_details: " MEMBER7 DETAILS ",
  m8_details: " MEMBER8 DETAILS ",
  m9_details: " MEMBER9 DETAILS ",
  m10_detail: " MEMBER10 DETAILS  ",
  m11_detail: " MEMBER11 DETAILS  ",
  m12_detail: " MEMBER12 DETAILS  ",
  widow_pens: " WIDOW PENSSION  ",
  intercastm: " INTERCAST MARRIAGE  ",
  ration_car: " RATION CARD DETAILS ",
  ration_no: "  RATIONCARD NO ",
  paultry_de: " POULTRY DETAILS ",
  gas_conn: " GAS CONECTION ",
  buildid: "  BUILDING ID ",
  pro_id: " PROPERTY ID ",
  ar_adrs: "  AR ADDRESS  ",
  paultry: "  POULTRY ",
  cattles_de: " CATTLES DETAILS ",
  m13_detail: " MEMBER13 DETAILS  ",
  m14_detail: " MEMBER14 DETAILS  ",
  m15_detail: " MEMBER15 DETAILS  ",
  startpoint: " STARTPOINT  ",
  endpoint: " ENDPOINT  ",
  carwidth: " CARRIAGE WIDTH  ",
  surfacetyp: " SURFACE TYPE  ",
  namesurvey: " NAME SURVEY ",
  km: " KM  ",
  gps_time: " GPS_TIME  ",
  datafile: " DATAFILE  ",
  gnss_heigh: " GNSS_HEIGH  ",
  shape_area: " AREA  ",
  id: "  ID ",
  ehealth_id: "E-HEALTH ID",
  pincode: "  PINCODE ",
  house_name: " HOUSE NAME  ",
  landline_n: " LANDLINE NO ",
  electrific: " TYPE OF LOCALITY  ",
  type_of_ho: " ELECTRIFICATION STATUS  ",
  roof_type: "  ROOF TYPE ",
  source_of_: " SOURCE OF WATER DRINKING  ",
  open_tanks: " OPEN TANKS  ",
  animal_and: " ANIMAL AND PETS ",
  cattle_she: " CATTLE SHED ",
  well__yes_: " WELL TYPE ",
  type_of_co: " TYPE OF COOKING FUEL  ",
  remarks_1: "  REMARKS 1 ",
  marital_st: " MARITAL STATUS  ",
  health_ins: " HEALTH INSURANCE STATUS ",
  major_heal: " MAJOR HEALTH ISSUE  ",
  type_of_di: " TYPE OF DISABILITY  ",
  tobacco_us: " TOBACCO USE ",
  alcohol_us: " ALCOHOL USED  ",
  mobile_num: " MOBILE NUMBER ",
  residentia: " RESIDENTIAL STATUS  ",
  nativity: " NATIVITY  ",
  member_lis: " MEMBER LIST ",
  member_nam: " MEMBER NAME ",
  house_no: " HOUSE NO  ",
  latitude: " LATITUDE  ",
  longitude: "  LONGITUDE ",
  source_of1: " SOURCE OF WATER DRINKING  ",
  type_of_la: " TYPE OF LATERINE  ",
  owner_add: "  OWNER ADDRESS ",
  year_const: " YEAR OF CONSTRUCTION  ",
  corp_name: "  CORPORATION NAME  ",
  ward_num: "  WARD NUMBER  ",
  bldg_ads: "   BUILDING ADDRESS  ",
  village_na: "  VILLAGE NAME ",
  bldg_year: "   BUILDING CONSTRUCTION YEAR ",
  str_type: "  STRUCTURE TYPE ",
  which_floo: "  WHICH FLOOR  ",
  rf_cvrprec: "  ROOF COVERAGE PERSENTAGE   ",
  surveyor: " SURVEYOR NAME ",
  corp_name: "   CORPORATION NAME ",
  ward_num: " WARD NUMBER ",
  bldg_ads: " BUILDING ADDRESS  ",
  village_na: " VILLAGE NAME  ",
  bldg_year: "  BUILDING CONSTRUCTION YEAR  ",
  school_nam: " SCHOOL NAME ",
  school_typ: " SCHOOL TYPE ",
  princi_hm: "  PRINCIPAL / HEAD MASTER NAME  ",
  incharge: " INCHARGE  ",
  no_student: " TOTAL NUMBER OF STUDENTS  ",
  no_teacher: " TOTAL NUMBER OF TEACHERS  ",
  no_nonteac: " NUMBER OF NON TEACHING STAFFS ",
  middaymeal: " MID DAY MEAL AVAILABILITY ",
  schoolbus: "  SCHOOL BUS   AVAILABILITY   ",
  schoollab: "   SCHOOL LAB AVAILABILITY  ",
  library: "  LIBRARY ",
  schoolclub: " SCHOOL CLUBS  ",
  no_classro: " NUMBER OF CLASS ROOMS ",
  toiletfaci: " TOILET FECILITY   ",
  no_benches: " TOTAL NUMBER OF BUNCHES ",
  no_desks: " TOTAL NUMBER OF DESKS ",
  no_chairs: "  TOTAL NUMBER OF CHAIRS  ",
  no_tables: "  TOTAL NUMBER OF TABLES  ",
  no_almirah: " TOTAL NUMBER OF ALMIRAH ",
  no_smartcl: " SMART CLASS ROOM AVAILABLE OR NOT ",
  no_smartcl: " TOTAL NUMBER OF SMART CLASS ROOMS   ",
  no_compute: " TOTAL NUMBER OF COMPUTERS   ",
  no_laptops: " TOTAL NUMBER OF LAPTOPS ",
  no_trees: " TOTAL NUMBER OF TREES ",
  play_groun: " AVAILABILITY  OF PLAY GROUND  ",
  which_floo: " WHICH FLOOR ",
  rf_cvrprec: " ROOF COVERAGE PERSENTAGE  ",
  surveyor: " SURVEYOR NAME   ",
  corp_name: "  CORPORATION NAME  ",
  aerobin_na: " AEROBIN NAME  ",
  work_time: "  WORKING TIME  ",
  mate_accep: " METERIAL ACCEPTED ",
  near_tc_no: " NEAR TC NUMBER  ",
  kwa_sectio: " KWA SECTION NAME  ",
  water_avai: " WATER AVAILABILITY  ",
  surveyor_d: " SURVEY DATE ",
  place: "  PLACE NAME  ",
  well_condi: " CONDITION OF WELL ",
  side_wall: "  SIDE WALL FOR WELL  ",
  pond_name: "  POND NAME ",
  side_wall: "  SIDE WALL FOR POND  ",
  pond_condi: " POND CONDITION  ",
  funded__by: " LIGHT FUNDED BY ",
  working_st: " WORKING STATUS  ",
  light_type: " LIGHT TYPE  ",
  land_type: "  LAND TYPE ",
  survey_nu: "  SURVEY NUMBER ",
  village_na: " VILLAGE NAME  ",
  buildings: "  NUMBER OF BUILDING  ",
  pond: " PRESENTS OF PONDS ",
  protect_wa: " PROTECTION WALL ",
  wall_lengt: " PROTECTION WALL LENGTH  ",
  trees: "  TREES ",
  no_coconut: " TOTAL NUMBER OF COCUNUT TREES ",
  no_mango: " TOTAL NUMBER OF MANGO TREES ",
  no_aracanu: " TOTAL NUMBER OF ARACANUT TREES  ",
  no_jackfru: " TOTAL NUMBER OF JACK FRUITTREES ",
  no_sandal: "  TOTAL NUMBER OF SANDALWOODTREES ",
  no_rosewoo: " TOTAL NUMBER OF ROSEWOODTREES ",
  no_teakwoo: " TOTAL NUMBER OF TEAKWOODTREES ",
  no_tamarin: " TOTAL NUMBER OF TAMARINTREES  ",
  no_mahagan: " TOTAL NUMBER OF MAHAGANITREES ",
  no_anjili: "  TOTAL NUMBER OF ANJILITREES ",
  no_others: "  TOTAL NUMBER OF OTHERTREES  ",
  total_area: " TOTAL AREAS ",
  protect_wa: " PROTECTION WALL ",
  Corporation: "  CORPORATION NAME  ",
  districtna: " DISTRICT NAME ",
  Layername: "  Layer Name  ",
  area_sqmtr: " AREA IN Sqmtr ",
  protect_wa: " PROTECTION WALL ",
  Placement: "  PLACEMENT SIDE  ",
  start_end: "  START OR END  ",
  Objnam: " GCP NAME  ",
  Easting: "  X_COORDINATE  ",
  Northing: "  Y_COORDINATE ",
  Height: "  Altitude ",
  newpro_id: "  NEW PROPERTY ID ",
  street: " STREET  ",
  ownername: "  OWNER NAME  ",
  ownerhouse: " OWNER HOUSE ",
  ownerplace: " OWNER PLACE ",
  emailid: "  EMAIL ID  ",
  ownerphone: " OWNER PHONE ",
  ownerland: "  OWNER LANDLINE  ",
  occupp: " OCCUPPATION ",
  ownervill: "  OWNER VILLAGE ",
  ownersurno: " OWNER SURVEY NO ",
  ownerpin: " OWNER PIN ",
  ownerpost: "  OWNER POST  ",
  gisid: "  GIS ID  ",
  mainbldg: " MAIN BUILDING ",
  globalid: " GLOBAL ID ",
  creationda: " CREATION DATE ",
  creator: "  CREATOR ",
  editdate: " EDIT DATE ",
  editor: " EDITOR  ",
  roadname: " ROAD NAME ",
  roadwidth: "  ROAD WIDTH  ",
  zones: "  ZONES ",
  surveyor: " SURVEYOR  ",
  oid_: " OID ",
  gisid_1: "  GISID ",
  bldgrefid: "  BUILDING REFERENCE ID ",
  mainbldg_1: " MAIN BUILDING ",
  ptstatus: " PT STATUS ",
  localbody: "  LOCAL BODY  ",
  wardno_1: " WARD NO ",
  doorstatus: " DOOR STATUS ",
  bldgstatus: " BUILDING STATUS ",
  bldgtype: " BUILDING TYPE ",
  bldgsubtyp: " BUILDING SUBTYPE  ",
  bldgunder: "  BUILDING UNDER  ",
  bldgname: " BUILDING NAME ",
  postoffice: " POST OFFICE ",
  street_1: " STREET  ",
  place_1: "  PLACE ",
  zones_1: "  ZONES ",
  oldproid: " OLD PROPERTY ID ",
  newproid: " NEW PROPERTY ID ",
  nearprono: "  NEAR PROPERTY NO  ",
  oldproremk: " OLD PROPERTY REMARK ",
  ownernam_1: " OWNER NAME  ",
  ownerhou_1: " OWNER HOUSE ",
  ownerpla_1: " OWNER PLACE ",
  ownerpos_1: " OWNER POST OFFICE ",
  ownerpin_1: " OWNER PIN ",
  ownersur_1: " OWNER SURVEY NO ",
  ownervil_1: " OWNER VILLAGE ",
  owneroccup: " OWNER OCCUPATION  ",
  ownerpho_1: " OWNER PHONE NO  ",
  ownerlan_1: " OWNER LANDLINE  ",
  emailid_1: "  EMAIL ID  ",
  yearconstr: " YEAR OF CONSTRUCTION  ",
  noofyears: "  NO OF YEARS ",
  otherbldg: "  OTHER BUILDING  ",
  otherwrdno: " OTHER WARD NO ",
  otherproid: " OTHER PROPERTY ID ",
  bldgusage: "  BUILDING USAGE  ",
  tenentname: " TENANT NAME ",
  tntnative: "  TENANT NATIVE ",
  tnthouse: " TENANT HOUSE  ",
  tntplace: " TENANT PLACE  ",
  tntpin: " TENANT PIN  ",
  tntsurno: " TENANT SURVEY NO  ",
  tntpost: "  TENANT POST OFFICE  ",
  tntvillage: " TENANT VILLAGE  ",
  tenentph: " TENANT PHONE  ",
  tenentland: " TENANT LANDLINE ",
  tntemail: " TENANT EMAIL  ",
  rentamout: "  RENT AMOUNT ",
  estabname: "  ESTABLISHMENT NAME  ",
  estabtype: "  ESTABLISHMENT TYPE  ",
  estpost: "  ESTABLISHMENT POST OFFICE ",
  estpin: " ESTABLISHMENT PIN ",
  estphone: " ESTABLISHMENT PHONE ",
  estemail: " ESTABLISHMENT EMAIL ",
  estabyear: "  ESTABLISHMENT YEAR  ",
  estland: "  ESTESTABLISHMENT LANDLINE ",
  noofemp: "  NO OF EMPLOYEE  ",
  noofemploy: " NO OF EMPLOYEE  ",
  incharge: " INCHARGE  ",
  incharrole: " INCHARGE ROLE ",
  gstno: "  GST NO  ",
  licence: "  LICENCE ",
  licenceno: "  LICENCE NO  ",
  informedby: " INFORMED BY ",
  noofmem: "  NO OF MEMBERS ",
  memberdeta: " MEMBER DATA ",
  mem1: " MEMBER 1  ",
  mem2: " MEMBER 2  ",
  mem3: " MEMBER 3  ",
  mem4: " MEMBER 4  ",
  mem5: " MEMBER 5  ",
  mem6: " MEMBER 6  ",
  mem7: " MEMBER 7  ",
  mem8: " MEMBER 8  ",
  mem9: " MEMBER 9  ",
  mem10: "  MEMBER 10 ",
  mem11: "  MEMBER 11 ",
  mem12: "  MEMBER 12 ",
  mem13: "  MEMBER 13 ",
  mem14: "  MEMBER 14 ",
  mem15: "  MEMBER 15 ",
  mem16: "  MEMBER 16 ",
  mem17: "  MEMBER 17 ",
  mem18: "  MEMBER 18 ",
  mem19: "  MEMBER 19 ",
  mem20: "  MEMBER 20 ",
  mem21: "  MEMBER 21 ",
  mem22: "  MEMBER 22 ",
  mem23: "  MEMBER 23 ",
  mem24: "  MEMBER 24 ",
  mem25: "  MEMBER 25 ",
  mem26: "  MEMBER 26 ",
  mem27: "  MEMBER 27 ",
  mem28: "  MEMBER 28 ",
  mem29: "  MEMBER 29 ",
  mem30: "  MEMBER 30 ",
  casts: "  CASTE ",
  intercast: "  INTERCASTE MARRIAGE ",
  account: "  BANK ACCOUNT DETAILS  ",
  rationcard: " RATION CARD ",
  rcardno: "  RATION CARD NO  ",
  pension: "  PENSION ",
  diseases: " DISEASES  ",
  disability: " DISABILITY  ",
  cattlesdet: " CATTLES DETAILS ",
  paultrydet: " POULTRY DETAILS ",
  vehicle: "  VEHICLE ",
  nooffloors: " NO OF FLOORS  ",
  noofrooms: "  NO OF ROOMS ",
  noofbase: " NO OF BASEMENT  ",
  basement1: "  BASEMENT 1  ",
  basement2: "  BASEMENT 2  ",
  basement3: "  BASEMENT 3  ",
  basement4: "  BASEMENT 4  ",
  stair: "  STAIR AREA  ",
  porch: "  CAR PORCH ",
  pathway: "  PATHWAY AREA ",
  grndfloor: "  GROUND FLOOR  ",
  floor1: " FLOOR 1 ",
  floor2: " FLOOR 2 ",
  floor3: " FLOOR 3 ",
  floor4: " FLOOR 4 ",
  floor5: " FLOOR 5 ",
  floor6: " FLOOR 6 ",
  floor7: " FLOOR 7 ",
  floor8: " FLOOR 8 ",
  floor9: " FLOOR 9 ",
  floor10: "  FLOOR 10  ",
  floor11: "  FLOOR 11  ",
  floor12: "  FLOOR 12  ",
  floor13: "  FLOOR 13  ",
  floor14: "  FLOOR 14  ",
  floor15: "  FLOOR 15  ",
  floortype: "  FLOOR TYPE  ",
  floorsqmtr: " FLOOR SQUARE METER  ",
  norftypes: "  NO ROOF TYPES ",
  roofrep_co: " ROOF REPRESENTATIVE COVERAGE  ",
  rooftotper: " ROOF TOTAL PERCENTAGE ",
  roof1: "  ROOF 1  ",
  roof2: "  ROOF 2  ",
  roof3: "  ROOF 3  ",
  roof4: "  ROOF 4  ",
  electricit: " ELECTRICITY ",
  consno: " CONSUMER NO ",
  gas: "  GAS CONNECTION  ",
  cen_ac: " CENTRALIZED AC  ",
  sourcetype: " SOURCE TYPE ",
  wtrconn: "  WATER CONNECTION  ",
  wtrinwell: "  WATER IN WELL ",
  rainwtrht: "  RAIN WATER HAVESTING  ",
  wastemgmnt: " WASTE MANAGEMENT  ",
  flood: "  FLOOD ",
  taxyear: "  TAX YEAR  ",
  taxnumber: "  TAX NUMBER  ",
  taxdate: "  TAX DATE  ",
  taxamount: "  TAX AMOUNT  ",
  nearroad: " NEAR ROAD ",
  roadwidt_1: " ROAD WIDTH  ",
  roadtype_1: " ROAD TYPE ",
  landmark: " LANDMARK ",
  lndmrkcat: "  LANDMARK CATEGORY  ",
  lndmrksub: "  LANDMARK SUBCATEGORY ",
  lndmrkname: " LANDMARK NAME  ",
  image1: " IMAGE 1 ",
  image2: " IMAGE 2 ",
  surveyor_1: " SURVEYOR  ",
  adcal: "  ADCAL ",
  uid: "  UID ",
  gstnostatu: " GST NUMBER STATUS ",
  profession: " PROFESSION  ",
  newproremk: " NEW PROPERTY REMARK ",
  profession: " PROFESSION  ",
  edu_owner: "  OWNER EDUCATION ",
  note: " NOTE  ",
  struc_chan: " STRUCTURE CHANGE  ",
  struc_chng: " STRUCTURE CHANGE YEAR",
  roof_chang: " ROOF CHANGE ",
  roof_cha_1: "ROOF CHANGE YEAR",
  disposalwa: " WASTE DISPOSAL  ",
  parkingare: " PARKING AREA  ",
  checkwell: "  CHECK WELL  ",
  wellrechar: " WELL RECHARGE ",
  org_inorg: "  ORGANIC/INORGANIC ",
  organic: "  ORGANIC ",
  inorganic: "  INORGANIC ",
  harithasen: " HARITHASENA ",
  soakpit: "  SOAK PIT  ",
  solarpanel: " SOLAR PANEL ",
  installsol: " INSTALL SOLAR ",
  solarfeas: "  SOLAR FEASIBILITY ",
  funding: "  FUNDING ",
  which_fund: " FUNDING SOURCE  ",
  resassoc: " RESIDENCE ASSOCIATION ",
  resassocno: " RESIDENCE ASSOCIATION NUMBER  ",
  resassocna: " RESIDENCE ASSOCIATION NAME  ",
  lastyearde: " DEATH IN LAST YEAR  ",
  death_coun: " DEATH COUNT ",
  nearroaddi: " NEAR ROAD DIRECTION ",
  neighname: "  NEIGHBOUR NAME  ",
  ownerchsta: " OWNER CHANGE STATUS ",
  arstatus: " AR STATUS ",
  deviceid: " DEVICE ID ",
  unautharea: "UNAUTHORISED AREA",
  shape_leng: "SHAPE LENGTH",
  parktype: "PARKING TYPE",
  street_lig: "STREET LIGHT",
  subtype: "SUBTYPE",
  class: "CLASS",
  shape_le_1: "SHAPE_LE_1",
  shape_le_2: "SHAPE_LE_2",
  street_l_1: "STREET_L_1",
  fundedby: "FUNDED BY",
  wrkstatus: "WORK STATUS",
  // status: "STATUS",
  reamrks: "REMARKS",
  welltype: "WELL TYPE",
  objectid: "OBJECT ID",
  taxreceipt: "TAX RECEIPT",
  created_da: "CREATED DATE",
  created_us: "CREATED USER",
  last_edite: "LAST EDIT",
  last_edi_1: "LAST EDIT 1",
  re_gis: "GIS REMARKS",
  eng_gis: "GIS ENGINEER",
  ar_change: "AR CHANGE",
  area_chang: "AREA CHANGE",
  qc_status: "QC STATUS",
  row_id: "ROW ID",
  uniquerowi: "UNIQUE ROW ID",
  area_tt: "AREA_TT",
  municipaln: "MUNICIPALITY",
  distname: "DISTRICT ",
  lastedit: "LAST EDIT",
  area_sq_m: "AREA IN SQ M",
  confidence: "CONFIDENCE",
  contourmina: "CONTOUR MINIMUM",
  contourmax: "CONTOUR MAXIMUM",
  estb_yr: "ESTABLISHMENT YEAR",
  mem_count: "MEMBER COUNT",
  rent_amnt: "RENT AMOUNT",
  knpm_code: "KNPM CODE",
  flr_count: "FLOOR COUNT",
  room_count: "ROOM COUNT",
  basement_4: "BASEMENT 4",
  basement_3: "BASEMENT 3",
  knpm_cod_1: "KNPM CODE 2",
  flr_4: "FOURTH FLOOR AREA",
  flr_5: "FIFTH FLOOR AREA",
  flr_6: "SIXTH FLOOR AREA",
  flr_7: "SEVENTH FLOOR AREA",
  flr_8: "EIGHTH FLOOR AREA",
  flr_9: "NINETH FLOOR AREA",
  flr_10: "TENTH FLOOR AREA",
  flr_11: "ELEVENTH FLOOR AREA",
  flr_12: "TWELTH FLOOR AREA",
  knpm_cod_2: "KNPM CODE 2",
  car_porch: "CAR PORCH",
  flr_grnd: "GROUND FLOOR AREA",
  flr_2: "SECOND FLOOR AREA",
  flr_3: "THIRD FLOOR AREA",
  flr_1: "FIRST FLOOR AREA",
  bldg_stats: "BUILDING SATUS",
  bldg_typ: "BUILDING TYPE",
  tnt_nm: "TENANT NAME",
  tnt_native: "TENANT NATIVE",
  tnt_addrs: "TENANT ADDRESS",
  tnt_mob: "TENANT MOBILE",
  estb_nm: "ESTABLISHMENT NAME",
  estb_typ: "ESTABLISHMENT TYPE",
  inchrg_nm: "INCHARGE NAME",
  inchrg_pst: "INCHARGE POST",
  infrmd_by: "INFORMED BY",
  m1_dtls: "MEMBER 1",
  m2_dtls: "MEMBER 2",
  m3_dtls: "MEMBER 3",
  m4_dtls: "MEMBER 4",
  m5_dtls: "MEMBER 5",
  m6_dtls: "MEMBER 6",
  m7_dtls: "MEMBER 7",
  m8_dtls: "MEMBER 8",
  m9_dtls: "MEMBER 9",
  m10_dtls: "MEMBER 10",
  m11_dtls: "MEMBER 11",
  m12_dtls: "MEMBER 12",
  rcard_colr: "RATION CARD COLOR",
  rcard_no: "RATION CARD NUMBER",
  pltry_dtls: "POULTRY DETAILS",
  ac_dtls: "AC AVAILIBILITY",
  four_whelr: "FOUR WHEELER",
  struct_typ: "STRUCTURE TYPE",
  bldg_under: "BUILDING UNDER",
  bldg_nm: "BUILDING NAME",
  ownr_nm: "OWNER NAME",
  ownr_addrs: "OWNER ADDRESS",
  ownrocc_sb: "OWNER OCCUPATION",
  ownr_mob: "OWNER MOBILE",
  aadhar_no: "AADHAR NUMBER",
  village_nm: "VILLAGE NAME",
  ownr_post: "OWNER POST",
  ownr_sur: "OWNER SURVEY NUMBER",
  ownr_vlage: "OWNER VILLAGE",
  tnt_l_phn: "TENANT LAND PHONE",
  tnt_pincod: "TENANT PIN CODE",
  tnt_place: "TENANT PLACE",
  tnt_pstoff: "TENANT POST OFFICE",
  tnt_sur_no: "TENANT SURVEY NUMBER",
  tnt_vilage: "TENANT VILLAGE",
  tnt_hsname: "TENANT HOUSE NAME",
  unique_id: "UNIQUE ID",
  emp_count: "EMP COUNT",
  flr_sqmtr: "TOTAL FLOOR AREA",
  rf_tot_per: "ROOF TOTAL PERCENTAGE",
  tax_amnt: "TAX AMOUNT",
  taxpaid_dt: "TAX PAID DATE",
  tax_bil_no: "TAX BILL NUMBER",
  taxpaid_yr: "TAXPAID YEAR",
  wall_typ: "WALL TYPE",
  rf_typ: "ROOF TYPE",
  rfcvrstats: "ROOF COVER STATUS",
  flr_typ: "FLOOR TYPE",
  wtr_in_wel: "FLOOR TYPEWATER IN WELL",
  rnwtr_hvt: "RAIN WATER HARVEST",
  wst_mng: "WASTE MANAGEMENT",
  near_rd: "NEAR ROAD",
  rd_width: "ROAD WIDTH",
  rd_typ: "ROAD TYPE",
  ward_nm: "WARD NAME",
  ar_addrs: "AR ADDRESS",
  disablty: "DISABILITY",
  catls: "CATTLES",
  catls_dtls: "CATTLE DETAIL",
  poultry: "POULTRY",
  m13_dtls: "MEMBER 13",
  m14_dtls: "MEMBER 14",
  m15_dtls: "MEMBER 15",
  street_nm: "STREET NAME",
  place_nm: "PLACE NAME",
  bldg_sbtyp: "BUILDING SUBTYPE",
  centrl_ac: "CENTRALISED AC",
  door_stats: "DOOR STATUS",
  estb_email: "ESTABLISHMENT EMAIL",
  estb_l_phn: "ESTABLISHMENT LAND PHONE",
  estb_mob: "ESTABLISHMENT MOBILE",
  estb_pin: "ESTABLISHMENT PIN",
  estb_post: "ESTABLISHMENT POST OFFICE",
  ownr_house: "OWNER HOUSE",
  ownr_l_phn: "OWNER LAND PHONE",
  ownr_pin: "OWNER PIN",
  ownr_place: "OWNER PLACE",
  serial_no: "SERIAL NUMBER",

  ownr_email: "OWNER EMAIL",
  flr_13: "THIRTEENTH FLOOR AREA",
  flr_14: "FOURTEENTH FLOOR AREA",
  flr_15: "FIFTEENTH FLOOR AREA",
  flrhightyp: "HIGHER FLOOR TYPE",
  otrbldg_id: "OTHER BUILDING ID",
  otrbldgwrd: "OTHER BUILDING WARD",
  ownr_occup: "OWNER OCCUPATION",
  ownr_surno: "OWNER SURVEY NUMBER",
  roof_1: "ROOF 1",
  roof_2: "ROOF 2",
  roof_3: "ROOF 3",
  roof_4: "ROOF 4",

  eduownbldg: "EDUCATION BUILDING OWNER",
  str_chang: "STRUCTURE CHANGE",
  str_yrchng: "STRUCTURE CHANGE YEAR",
  rf_chang: "ROOF CHANGE",
  rf_yrchang: "ROOF CHANGE YEAR",
  elec_conn: "ELECTRICITY CONNECTION",
  consumerno: "COMSUMER NUMBER",
  area_prkng: "PARKING AREA",
  ramp: "RAMP FECILITY",
  src_typ: "WATER SOURSE TYPE",
  wtr_conn: "WATER CONNECTION",
  wellwateru: "WELL WATER USAGE",
  deathcount: "DEATH COUNT",
  deathcause: "CAUSE OF DEATH",
  tax_year: "TAX YEAR",
  est_yr: "ESTABLISHMENT YEAR",
  PROFESSION: "PROFESSIONAL TAX",
  inchrg_rl: "INCHARGE ROLE",
  gst_stats: "GST STATUS",
  gst_no: "GST NUMBER",
  rd_dirctn: "ROAD DIRECTION",
  cooperatio: "OWNER COOPERATION",
  neighbr_nm: "NEIGHBOUR NAME",
  Lnmrk: "LANDMARK OR NOT",
  lnmrk_nm: "LANDMARK NAME",
  lnmr_cat: "LANDMARK CATEGORY",
  lnmrksbtyp: "LANDMARK SUBTYPE",
  chng_ownr: "BUILDING OWNER CHANGE",

  lm_type: "LANDMARK TYPE",
  lm_subtype: "LANDMARK SUBTYPE",
  fundedby: "FUNDED BY",
  wrkstatus: "WORKING STATUS",
  groundtype: "GROUNDTYPE",
  status: "USAGE CONDITION",

  lnmrk: "LANDMARK OR NOT ",
  m21_dtls: "MEMBER 21 DETAILS",
  m22_dtls: "MEMBER 22 DETAILS",
  m23_dtls: "MEMBER 23 DETAILS",
  m24_dtls: "MEMBER 24 DETAILS",
  m25_dtls: "MEMBER 25 DETAILS",
  m26_dtls: "MEMBER 26 DETAILS",
  m27_dtls: "MEMBER 27 DETAILS",

  watertype: "WATER TANK TYPE",

  sub_class: "SUB CLASS",
  length_km: "LENGTH IN KM",
  rd_name: "ROAD NAME",
  start_poin: "START POINT",
  end_point: "END POINT",
  cons_ma: "ROAD CONSTRUCTION MATERIAL",
  cw_width: "CARRIAGE WIDTH (in mt.)",
  row_width: "TOTAL WIDTH (in mt.)",
  maintain: "MAINTAINED BY",
  fp: "FOOT PATH",
  fp_width: "FOOT PATH WIDTH (in mt. in case Yes)",
  fp_cons_ma: "FOOT PATH CONSTRUCTION MATERIAL",

  category_s: "CATEGORY",
  parking_ty: "PARKING TYPE",
  authorisat: "AUTHORISATION",
  authoris_1: "AUTHORISED NUMBER",
  water_type: "WATER SOURCE TYPE",
  category_l: "CATEGORY",
  pond_type: "POND TYPE",
  park_type: "PARK TYPE",
  ground_typ: "GROUND TYPE",
  category_g: "CATEGORY",
  const_matr: "CONSTRUCTION MATERIAL",
  name: "NAME",
  location_: "LOCATION",
  funded_by: "FUNDED BY",
  types_l: "LIGHT TYPE",
  work_statu: "WORKING STATUS",
  well_type: "WELL TYPE",
  bus_stand_: "BUS STAND TYPE",
  locality_1: "LOCATION",
  bridge_wid: "BRIDGE WIDTH",
  length__mt: "LENGTH(mtr)",

  ac_dtls: "AIR CONDITIONER DETAILS",
  bank_acc: "BANK ACCOUNT",
  basement1: "BASEMENT 1ST FLOOR AREA",
  basement2: "BASEMENT 2ND FLOOR AREA",
  basement3: "BASEMENT 3RD FLOOR AREA",
  basement4: "BASEMENT 4TH FLOOR AREA",
  bathroom: "BATHROOM",
  bldg_nm: "BUILDING NAME",
  bldg_sbtyp: "BUILDING SUBTYPE",
  bldg_stats: "BUILDING STATUS",
  bldg_typ: "BUILDING TYPE",
  bldg_under: "BUILDING UNDER",
  bldg_usage: "BUILDING USAGE",
  bldg_zone: "BUILDING ZONE",
  bsmntcount: "BASEMENT COUNT",
  car_po_ar: "CAR PORCH AREA",
  car_porch: "CAR PORCH",
  caste_nm: "CASTE NAME",
  catls: "CATTLES",
  catls_no: "NUMBER OF CATTLES",
  com_st_ar: "COMMON STAIR AREA",
  com_stair: "COMMON STAIR",
  consumerno: "CONSUMER NUMBER",
  cooperatn: "COOPERATION",
  district: "DISTRICT",
  door_stats: "DOOR STATUS",
  eduownbldg: "OWNERSHIP OF THE EDUCATION BUILDING",
  elec_conn: "ELECTRIC CONNECTION",
  emp_count: "EMPLOYEES COUNT",
  estb_email: "ESTABLISHMENT EMAIL ID",
  estb_l_phn: "ESTABLISHMENT LANDPHONE NUMBER",
  estb_mob: "ESTABLISHMENT MOBILE NUMBER",
  estb_nm: "ESTABLISHMENT NAME",
  estb_typ: "ESTABLISHMENT TYPE",
  estb_yr: "ESTABLISHMENT YEAR",
  flr_1: "1ST FLOOR AREA",
  flr_10: "10TH FLOOR AREA",
  flr_11: "11TH FLOOR AREA",
  flr_12: "12TH FLOOR AREA",
  flr_13: "13TH FLOOR AREA",
  flr_14: "14TH FLOOR AREA",
  flr_15: "15TH FLOOR AREA",
  flr_2: "2ND FLOOR AREA",
  flr_3: "3RD FLOOR AREA",
  flr_4: "4TH FLOOR AREA",
  flr_5: "5TH FLOOR AREA",
  flr_6: "6TH FLOOR AREA",
  flr_7: "7TH FLOOR AREA",
  flr_8: "8TH FLOOR AREA",
  flr_9: "9TH FLOOR AREA",
  flr_count: "FLOOR COUNT",
  flr_grnd: "GROUND FLOOR AREA",
  flr_typ: "FLOOR TYPE",
  flrhightyp: "HIGHER FLOOR TYPE",
  form_id: "FORM ID",
  gas_conn: "GAS CONNECTION",
  global_id: "GLOBAL ID",
  gst_stats: "GST STATUS",
  health_ins: "HEALTH INSURANCE ",
  inchrg_nm: "INCHARGE NAME",
  inchrg_pst: "INCHARGE POST(ROLE)",
  informedby: "INFORMED BY",
  kudumbasre: "KUDUMBASREE MEMBERSHIP",
  latitude: "LATTITUDE",
  latrine: "LATRINE",
  licence_no: "LICENCE NUMBER",
  lnd_typ: "LAND TYPE",
  lndmrk: "LANDMARK",
  lndmrk_cat: "LANDMARK CATEGORY",
  lndmrk_nm: "LANDMARK NAME",
  lnmrksbtyp: "LANDMARK SUBTYPE",
  localbody: "LOCAL BODY",
  longitude: "LONGITUDE",
  m1_dlts: "MEMBER 1 DETAILS",
  m10_dlts: "MEMBER 10 DETAILS",
  m11_dlts: "MEMBER 11 DETAILS",
  m12_dlts: "MEMBER 12 DETAILS",
  m13_dlts: "MEMBER 13 DETAILS",
  m14_dlts: "MEMBER 14 DETAILS",
  m15_dlts: "MEMBER 15 DETAILS",
  m16_dlts: "MEMBER 16 DETAILS",
  m17_dlts: "MEMBER 17 DETAILS",
  m18_dlts: "MEMBER 18 DETAILS",
  m19_dlts: "MEMBER 19 DETAILS",
  m2_dlts: "MEMBER 2 DETAILS",
  m20_dlts: "MEMBER 20 DETAILS",
  m3_dlts: "MEMBER 3 DETAILS",
  m4_dlts: "MEMBER 4 DETAILS",
  m5_dlts: "MEMBER 5 DETAILS",
  m6_dlts: "MEMBER 6 DETAILS",
  m7_dlts: "MEMBER 7 DETAILS",
  m8_dlts: "MEMBER 8 DETAILS",
  m9_dlts: "MEMBER 9 DETAILS",
  main_bldg: "MAIN BUILDING",
  mem_count: "MEMBER COUNT",
  near_rd: "NEAR ROAD NAME",
  nearprono: "NEAR PROPERTY NUMBER",
  new_pro_id: "NEW BUILDING ID",
  noflrocc: "NUMBER OF FLOOR OCCUPI",
  noofyears: "NUMBER OF YEARS",
  nwpridrmks: "NEW BUILDING ID REMARKS",
  objectid: "OBJECT ID",
  old_pro_id: "OLD BUILDING ID",
  other_bldg: "OTHER BUILDING",
  otr_wstmng: "OTHER WASTE MANAGEMENT",
  otrpr_dtls: "OTHER BUILDING DETAILS",
  ownr_email: "OWNER EMAIL ID",
  ownr_house: "OWNER HOUSE NAME",
  ownr_l_phn: "OWNER LANDPHONE NUMBER",
  ownr_mob: "OWNER MOBILE NUMBER",
  ownr_nm: "OWNER NAME",
  ownr_occup: "OWNER OCCUPATION",
  ownr_pin: "OWNER POSTOFFICE PINCODE",
  ownr_place: "OWNER PLACE NAME",
  ownr_post: "OWNER POSTOFFICE NAME",
  ownr_state: "OWNER STATE NAME",
  pathway: "PATHWAY AREA",
  pet_dtls: "PETS DETAILS",
  photo_1: "IMAGE 1",
  photo_2: "IMAGE 2",
  pincode: "PINCODE",
  place_nm: "PLACE NAME",
  plot_area: "PLOT AREA",
  pltry: "POULTRY",
  pltrycount: "POULTRY COUNT",
  pnt_stats: "POINT STATUS",
  postoffice: "POST OFFICE",
  prop_count: "PROPERTY COUNT",
  prop_id: "PROPERTY ID",
  rcard_colr: "RATIONCARD COLOUR",
  rcard_no: "RATIONCARD NUMBER",
  rd_typ: "ROAD TYPE",
  rd_width: "ROAD WIDTH",
  religion: "RELIGION",
  rent_amnt: "RENT AMOUNT",
  rf_altrtn: "ROOF ALTERATION",
  rf_count: "ROOF COUNT",
  rf_tot_per: "ROOF TOTAL PERCENTAGE",
  rf_typ_per: "ROOF TYPE PERCENTAGE",
  rf_yrchng: "ROOF ALTERATION YEAR",
  rnwtr_hvt: "RAIN WATER HARVESTING",
  room_count: "ROOM COUNT",
  rsassodtls: "RESIDENTIAL ASSOCIATION DETAILS",
  schm_ifany: " BUILDING UNDER ANY SCHEME",
  solar_panl: "SOLAR PANAL",
  src_typ: "WATER SOURCE TYPE",
  str_altrtn: "STRUCTURE ALTERATION",
  str_yrchng: "STRUCTURE ALTERATION YEAR",
  street_nm: "STREET NAME",
  struct_typ: "STRUCTURE TYPE",
  sur_strtdt: "SURVEY DATE",
  survey_no: "SURVEY NUMBER",
  tax_amnt: "TAX AMOUNT",
  tax_annual: "TAX ANNUAL AMOUNT",
  tax_bil_no: "TAX BILL NUMBER",
  taxpaid_dt: "TAX PAID DATE",
  taxpaid_yr: "TAX PAID YEAR",
  thozhlurap: "THOZHILURAPPU SCHEME MEMBERSHIP",
  tnt_email: "TENANT EMAIL ID",
  tnt_hsname: "TENANT HOUSE NAME",
  tnt_l_phn: "TENANT LANDPHONE NUMBER",
  tnt_mob: "TENANT MOBILE NUMBER",
  tnt_native: "TENANT NATIVE",
  tnt_nm: "TENANT NAME",
  tnt_pincod: "TENANT PINCOD",
  tnt_pstoff: "TENANT POSTOFFICE",
  tnt_state: "TENANT STATE",
  tnt_stats: "TENANT STATUS",
  tnt_street: "TENANT STREET NAME",
  tnt_sur_no: "TENANT SURVEY NUMBER",
  tot_flr_ar: "TOTAL FLOOR AREA",
  vehcl_dtls: "VEHICLE DETAILS",
  village_nm: "VILLAGE NAME",
  wall_typ: "WALL TYPE",
  ward_nm: "WARD NAME",
  ward_no: "WARD NUMBER",
  water_conn: "WATER CONNECTION",
  wl_prnl: "WELL WATER AVAILABILITY",
  wl_prnlmnt: "WELL WATER AVAILABILITY MONTH",
  wst_mngtyp: "WASTE MANAGEMENT TYPE",
  wstmng_tlt: "TOILET WASTE DISPOSAL",
  wtr_shrtg: "WATER SHORTAGE",
  wtrconn_ty: "WATER CONNECTION TYPE",
  wtrspl_dur: "WATER SUPPLY DURATION",
  year_const: "YEAR OF CONSTRUCTION",

  length_mtr: "LENGTH(mtr)",
  width_mtr: "WIDTH(mtr)",

  rentamount: "RENT AMOUNT",
  cattle: "CATTLE",
  caste: "CASTE",
  healthinsu: "HEALTH INSURANCE",
  wtrcncsrc: "WATER CONNECTION SOURCE",
  poultrydet: "POULTRY DETAILS",
  tenantname: "TENANT NAME",
  tenantph: "TENANT PHONE",
  tenantland: "TENANT LANDLINE",
  death1: "DEATH CAUSE",
  death2: "DEATH CAUSE SPECIFY",
  fp_const_m: "FOOT PATH CONSTRUCTION MATERIAL",
  bldg_refid: "BUILDING REFERENECE ID",
  oldpro_rmk: "OLD BUILDING REMARK",
  m16_dtls: "MEMBER 16 DETAILS",
  m17_dtls: "MEMBER 17 DETAILS",
  m18_dtls: "MEMBER 18 DETAILS",
  m19_dtls: "MEMBER 19 DETAILS",
  m20_dtls: "MEMBER 20 DETAILS",
  area_uauth: "AREA UNAUTHORIZED",
  wtr_test: "WATER TEST",
  well_rchrg: "WELL RECHARGE",
  proprty_ar: "PROPERTY AR",
  cmn_rmk: "COMMON REMARK",

  form_id: "FORM ID",
  prop_id: "PROPERTY ID",
  mem_ID: "MEMBER ID",
  mem_nm: "MEMBER NAME",
  mem_sex: "MEMBER SEX",
  mem_age: "MEMBER AGE",
  edu_catgry: "EDUCATION CATEGORY",
  edu_typ: "EDUCATION TYPE",
  occup_type: "OCCUPATION TYPE",
  mem_occup: "MEMBER OCCUPATION",
  occup_loss: "OCCUPATION LOSS",
  nri_mem: "NRI MEMBERSHIP",
  nrk_mem: "NRK MEMBERSHIP",
  mari_stats: "MARTIAL STATUS",
  mem_pens: "MEMBER PENSION",
  disability: "DISABILITY",
  diseases: "DISEASES",

  objectid: "	OBJECT ID	",
  form_id: "	FORM ID	",
  global_id: "	GLOBAL ID	",
  prop_count: "	PROPERTY COUNT	",
  prop_id: "	PROPERTY ID	",
  informedby: "	INFORMED BY	",
  pnt_stats: "	POINT STATUS	",
  struct_typ: "	STRUCTURE TYPE	",
  bldg_typ: "	BUILDING TYPE	",
  bldg_sbtyp: "	BUILDING SUBTYPE	",
  bldg_stats: "	BUILDING STATUS	",
  door_stats: "	DOOR STATUS	",
  cooperatn: "	COOPERATION	",
  bldg_under: "	BUILDING UNDER	",
  district: "	DISTRICT	",
  localbody: "	LOCAL BODY	",
  village_nm: "	VILLAGE NAME	",
  ward_no: "	WARD NUMBER	",
  ward_nm: "	WARD NAME	",
  postoffice: "	POST OFFICE	",
  pincode: "	PINCODE	",
  street_nm: "	STREET NAME	",
  place_nm: "	PLACE NAME	",
  bldg_zone: "	BUILDING ZONE	",
  old_pro_id: "	OLD BUILDING ID	",
  new_pro_id: "	NEW BUILDING ID	",
  nwpridrmks: "	NEW BUILDING ID REMARKS	",
  eduownbldg: "	OWNERSHIP OF THE EDUCATION BUILDING	",
  main_bldg: "	MAIN BUILDING	",
  ownr_nm: "	OWNER NAME	",
  ownr_house: "	OWNER HOUSE NAME	",
  ownr_place: "	OWNER PLACE NAME	",
  survey_no: "	SURVEY NUMBER	",
  ownr_post: "	OWNER POSTOFFICE NAME	",
  ownr_pin: "	OWNER POSTOFFICE PINCODE	",
  ownr_state: "	OWNER STATE NAME	",
  ownr_occup: "	OWNER OCCUPATION	",
  ownr_mob: "	OWNER MOBILE NUMBER	",
  ownr_l_phn: "	OWNER LANDPHONE NUMBER	",
  ownr_email: "	OWNER EMAIL ID	",
  other_bldg: "	OTHER BUILDING	",
  otrpr_dtls: "	OTHER BUILDING DETAILS	",
  bldg_nm: "	BUILDING NAME	",
  rsassodtls: "	RESIDENTIAL ASSOCIATION DETAILS	",
  year_const: "	YEAR OF CONSTRUCTION	",
  noofyears: "	NUMBER OF YEARS	",
  str_altrtn: "	STRUCTURE ALTERATION	",
  str_yrchng: "	STRUCTURE ALTERATION YEAR	",
  rf_altrtn: "	ROOF ALTERATION	",
  rf_yrchng: "	ROOF ALTERATION YEAR	",
  bldg_usage: "	BUILDING USAGE	",
  tnt_nm: "	TENANT NAME	",
  tnt_hsname: "	TENANT HOUSE NAME	",
  tnt_street: "	TENANT STREET NAME	",
  tnt_native: "	TENANT NATIVE	",
  tnt_state: "	TENANT STATE	",
  tnt_pstoff: "	TENANT POSTOFFICE	",
  tnt_pincod: "	TENANT PINCOD	",
  tnt_stats: "	TENANT STATUS	",
  tnt_mob: "	TENANT MOBILE NUMBER	",
  tnt_l_phn: "	TENANT LANDPHONE NUMBER	",
  tnt_email: "	TENANT EMAIL ID	",
  rent_amnt: "	RENT AMOUNT	",
  tnt_sur_no: "	TENANT SURVEY NUMBER	",
  wall_typ: "	WALL TYPE	",
  flr_count: "	FLOOR COUNT	",
  bsmntcount: "	BASEMENT COUNT	",
  basement1: "	BASEMENT 1ST FLOOR AREA	",
  basement2: "	BASEMENT 2ND FLOOR AREA	",
  basement3: "	BASEMENT 3RD FLOOR AREA	",
  basement4: "	BASEMENT 4TH FLOOR AREA	",
  noflrocc: "	NUMBER OF FLOOR OCCUPI	",
  flr_grnd: "	GROUND FLOOR AREA	",
  flr_1: "	1ST FLOOR AREA	",
  flr_2: "	2ND FLOOR AREA	",
  flr_3: "	3RD FLOOR AREA	",
  flr_4: "	4TH FLOOR AREA	",
  flr_5: "	5TH FLOOR AREA	",
  flr_6: "	6TH FLOOR AREA	",
  flr_7: "	7TH FLOOR AREA	",
  flr_8: "	8TH FLOOR AREA	",
  flr_9: "	9TH FLOOR AREA	",
  flr_10: "	10TH FLOOR AREA	",
  flr_11: "	11TH FLOOR AREA	",
  flr_12: "	12TH FLOOR AREA	",
  flr_13: "	13TH FLOOR AREA	",
  flr_14: "	14TH FLOOR AREA	",
  flr_15: "	15TH FLOOR AREA	",
  tot_flr_ar: "	TOTAL FLOOR AREA	",
  car_porch: "	CAR PORCH	",
  car_po_ar: "	CAR PORCH AREA	",
  com_stair: "	COMMON STAIR	",
  com_st_ar: "	COMMON STAIR AREA	",
  pathway: "	PATHWAY AREA	",
  room_count: "	ROOM COUNT	",
  rf_count: "	ROOF COUNT	",
  rf_tot_per: "	ROOF TOTAL PERCENTAGE	",
  rf_typ_per: "	ROOF TYPE PERCENTAGE	",
  flr_typ: "	FLOOR TYPE	",
  flrhightyp: "	HIGHER FLOOR TYPE	",
  estb_nm: "	ESTABLISHMENT NAME	",
  estb_typ: "	ESTABLISHMENT TYPE	",
  estb_yr: "	ESTABLISHMENT YEAR	",
  estb_email: "	ESTABLISHMENT EMAIL ID	",
  estb_l_phn: "	ESTABLISHMENT LANDPHONE NUMBER	",
  estb_mob: "	ESTABLISHMENT MOBILE NUMBER	",
  licence_no: "	LICENCE NUMBER	",
  gst_stats: "	GST STATUS	",
  inchrg_nm: "	INCHARGE NAME	",
  inchrg_pst: "	INCHARGE POST(ROLE)	",
  emp_count: "	EMPLOYEES COUNT	",
  mem_count: "	MEMBER COUNT	",
  m1_dlts: "	MEMBER 1 DETAILS	",
  m2_dlts: "	MEMBER 2 DETAILS	",
  m3_dlts: "	MEMBER 3 DETAILS	",
  m4_dlts: "	MEMBER 4 DETAILS	",
  m5_dlts: "	MEMBER 5 DETAILS	",
  m6_dlts: "	MEMBER 6 DETAILS	",
  m7_dlts: "	MEMBER 7 DETAILS	",
  m8_dlts: "	MEMBER 8 DETAILS	",
  m9_dlts: "	MEMBER 9 DETAILS	",
  m10_dlts: "	MEMBER 10 DETAILS	",
  m11_dlts: "	MEMBER 11 DETAILS	",
  m12_dlts: "	MEMBER 12 DETAILS	",
  m13_dlts: "	MEMBER 13 DETAILS	",
  m14_dlts: "	MEMBER 14 DETAILS	",
  m15_dlts: "	MEMBER 15 DETAILS	",
  m16_dlts: "	MEMBER 16 DETAILS	",
  m17_dlts: "	MEMBER 17 DETAILS	",
  m18_dlts: "	MEMBER 18 DETAILS	",
  m19_dlts: "	MEMBER 19 DETAILS	",
  m20_dlts: "	MEMBER 20 DETAILS	",
  m28_dlts: "	MEMBER 28 DETAILS	",
  m29_dlts: "	MEMBER 29 DETAILS	",
  m30_dlts: "	MEMBER 30 DETAILS	",
  more_mem: "MORE MEMBERS",
  bathroom: "	BATHROOM	",
  latrine: "	LATRINE	",
  wstmng_tlt: "	TOILET WASTE DISPOSAL	",
  elec_conn: "	ELECTRIC CONNECTION	",
  consumerno: "	CONSUMER NUMBER	",
  ac_dtls: "	 AIR CONDITIONER DETAILS	",
  rcard_colr: "	RATIONCARD COLOUR	",
  rcard_no: "	RATIONCARD NUMBER	",
  gas_conn: "	GAS CONNECTION	",
  bank_acc: "	BANK ACCOUNT	",
  caste_nm: "	CASTE NAME	",
  religion: "	RELIGION	",
  health_ins: "	HEALTH INSURANCE 	",
  schm_ifany: "	 BUILDING UNDER ANY SCHEME	",
  vehcl_dtls: "	VEHICLE DETAILS	",
  water_conn: "	WATER CONNECTION	",
  wtrconn_ty: "	WATER CONNECTION TYPE	",
  wtrspl_dur: "	WATER SUPPLY DURATION	",
  wtr_shrtg: "	WATER SHORTAGE	",
  src_typ: "	WATER SOURCE TYPE	",
  wl_prnl: "	WELL WATER AVAILABILITY	",
  wl_prnlmnt: "	WELL WATER AVAILABILITY MONTH	",
  rnwtr_hvt: "	RAIN WATER HARVESTING	",
  solar_panl: "	SOLAR PANAL	",
  otr_wstmng: "	OTHER WASTE MANAGEMENT	",
  wst_mngtyp: "	WASTE MANAGEMENT TYPE	",
  thozhlurap: "	THOZHILURAPPU SCHEME MEMBERSHIP	",
  kudumbasre: "	KUDUMBASREE MEMBERSHIP	",
  lnd_typ: "	LAND TYPE	",
  plot_area: "	PLOT AREA	",
  catls: "	CATTLES	",
  catls_no: "	NUMBER OF CATTLES	",
  pltry: "	POULTRY	",
  pltrycount: "	POULTRY COUNT	",
  pet_dtls: "	PETS DETAILS	",
  tax_bil_no: "	TAX BILL NUMBER	",
  taxpaid_dt: "	TAX PAID DATE	",
  taxpaid_yr: "	TAX PAID YEAR	",
  tax_amnt: "	TAX AMOUNT	",
  tax_annual: "	TAX ANNUAL AMOUNT	",
  near_rd: "	NEAR ROAD NAME	",
  rd_typ: "	ROAD TYPE	",
  rd_width: "	ROAD WIDTH	",
  lndmrk: "	LANDMARK	",
  nearprono: "	NEAR PROPERTY NUMBER	",
  lndmrk_nm: "	LANDMARK NAME	",
  lndmrk_cat: "	LANDMARK CATEGORY	",
  lnmrksbtyp: "	LANDMARK SUBTYPE	",
  photo_1: "	IMAGE 1	",
  photo_2: "	IMAGE 2	",
  latitude: "	LATTITUDE	",
  longitude: "	LONGITUDE	",
  sur_strtdt: "	SURVEY DATE	",

  drone_id: "DRONE ID",
  cons_ma: "ROAD CONSTRUCTION MATERIAL",
  cons_mat: "ROAD CONSTRUCTION MATERIAL",
  fp_place: "FOOTPATH PLACEMENT",
  carriage_w: "CARRIAGE WIDTH",

  deathspec: "SPECIFY CAUSE OF DEATH",
  ward_numbe: "WARD NUMBER",
  wrd_nme: "WARD NAME",
  owneraddre: "OWNER ADDRESS",
  nri: "NRI",
  nrk: "NRK",
  job: "JOB",
  bloodgroup: "BLOOD GROUP",
  disper: "DISABILITY PERCENTAGE",
  banktype: "BANK TYPE",
  no_of_memb: "NUMBER OF MEMBERS",
  name_1: "NAME",
  age_1: "AGE",
  sex_1: "SEX",
  job_1: "JOB",
  cooliewag1: "IF COOLIE, WAGE?",
  bloodgrup1: "BLOOD GROUP",
  educat_1: "EDUCATION CATEGORY",
  edutyp_1: "EDUCATION TYPE",
  maritstat1: "MARITAL STATUS",
  diseases_1: "DISEASES",
  disablty_1: "DISABILITY",
  pension_1: "PENSION",
  month_year: "MONTH/YEAR",
  SEX: "SEX",
  job: "JOB",
  coolie_wag: "IF COOLIE, WAGE?",
  bloodgroup: "BLOOD GROUP",
  educat: "EDUCATION CATEGORY",
  edutype: "EDUCATION TYPE",
  maritstat: "MARITAL STATUS",
  DISEASES: "DISEASES",
  DISABILITY: "DISABILITY",
  disper: "DISABILITY PERCENTAGE",
  PENSION: "PENSION",
  nri: "NRI",
  nrk: "NRK",
  banktype: "BANK TYPE",
  tax_prfsnl: "PROFESSIONAL TAX",
  type_l: "TYPE OF LIGHT",
  height: "HEIGHT OF LIGHT POST",
  no_bulb: " NUMBER OF BULB/LED",
  transforme: "TRANSFORMER ID",
  tank_capac: "TANK CAPACITY IN LITRES",
  water_sour: "WATER SOURCE TYPE",
  junction_n: "JUNCTION NAME",
  no_road: "NUMBER OF ROADS",
  pedestrian: "PEDESTRIAN CROSS",
  no_gallery: "NUMBER OF GALLERY",
  locality: "LOCATION",
  disab_typ: "DISABILITY TYPE",
  munciplty: "MUNICIPALITY",
};

export default (key) => {
  return attributes[key] == null ? key.toUpperCase() : attributes[key];
};
