import { Fill, Stroke, Style, Circle, Text } from "ol/style.js";

const styleFunction = (feature, resolution) => {
  return new Style({
    fill: new Fill({
      color: "rgba(255, 255, 0, 0.2)",
      fillOpacity: 0.5,
    }),
    stroke: new Stroke({
      color: "#ffff00",
      width: 3,
      lineCap: "round",
    }),
    image: new Circle({
      radius: 7,
      fill: new Fill({ color: "#ffff00" }),
      stroke: new Stroke({ color: "#e60000", width: 1 }),
    }),

    // text: new Text({
    //   font: "20px Calibri,sans-serif",
    //   offsetY: 20,
    //   overflow: true,
    //   fill: new Fill({
    //     color: "#000000",
    //   }),
    //   stroke: new Stroke({
    //     color: "#fff",
    //     width: 1,
    //     opacity: 1,
    //   }),
    //   // backgroundFill: new Fill({
    //   //   color: "rgb(230, 224, 224)",
    //   // }),

    //   text: feature.values_.new_pro_id
    //     ? feature.values_.new_pro_id
    //     : feature.values_.road_name,
    // }),
  });
};
export default styleFunction;
