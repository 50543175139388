import React, { Component } from "react";
import {
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
} from "react-bootstrap";
import {
  fetchDataByAttribute,
  getColumns,
  getLayers,
  fetchValueByAttribute,
} from "../data/action";
import ConditionSelector from "./ConditonSelector";
import { snackbarActions } from "../../../../snackbar/data/action";
import { connect } from "react-redux";
import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import debounce from "lodash.debounce";
var temp = [];
class FilterForm extends Component {
  myRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      searchBy: null,
      searchValue: null,
      options: [],
      option: [],
      isLoading: false,
      layerToSearch: null,
      attributes: {},
      layers: {},
      layer: "",
      layerCategory: "",
      data: {
        operator: "",
        attribute: "",
        autocomplete: "",
        model: "",
        value: [],
      },
      attributeName: "",
      category_field: "",
      category_id: "",
      attributeInformation: "",
      query: "",
    };
    this.onInput = debounce(this.onInput.bind(this), 500);
   
  }

  componentDidMount() {
  
    this.props.getLayers();
  }
  //Auto completion search
  handleSearch = (query, index) => {
    this.setState({ isLoading: true });
    let res = this.props.data.layer.split(":");
    let table = res[1];
    //function for search data
    this.props.fetchDataByAttribute(
      this.props.data["column".concat(index)],
      query,
      table
    );
  };

  attributeSelect = (event, index, isClearValue) => {
    const displayName = event.target.value;
    // console.log('display name filter form',displayName);
    const attributeInformation = this.props.data.layer.advancefilterinfo.find(
      (attribute) => attribute.display_name === displayName
    );
    this.setState({
      attributeName: attributeInformation.field,
      attributeInformation: attributeInformation,
    });
    this.props.showSnackbar("Please select the operation");
    this.props.handleInputChangeAttribute(
      event,
      index,
      attributeInformation.field,
      attributeInformation.model_name,
      attributeInformation.parent_model,
      isClearValue
    );

    // if (attributeInformation.auto_complete) {
    this.props.fetchValueByAttribute(
      attributeInformation.model_name,
      attributeInformation.field,
      attributeInformation.data_type,
      this.props.data.layer.category_field,
      this.props.data.layer.category_id,
      false,
      null
    );
    // }
  };

  //search

  searchValue = (value) => {
    this.props.fetchValueByAttribute(
      this.state.attributeInformation.model_name,
      this.state.attributeInformation.field,
      this.state.attributeInformation.data_type,
      this.props.data.layer.category_field,
      this.props.data.layer.category_id,
      true,
      value
    );
    // }
  };

  handleSearchValueChange = (evt, index) => {
   
    //function for setting search value for filtering
    if (evt.length != 0) {
      this.props.handleInputChangeDropdown(evt[0], index);
    
    }
    //search value setting for auto completion
    this.setState(
      {
        searchValue: evt.length == 0 ? null : evt[0],
        option: evt.length == 0 ? [] : evt,
      },
      () => {
        temp = this.state.option;
        this.props.handleToUpdate(temp, index);
      }
    );
  };

  componentDidUpdate(prevProps) {
    
    if (prevProps.datas != this.props.datas) {
      this.setState(
        {
          isLoading: false,
          options: this.props.datas,
        },
        () => {}
      );
    }
  }
  handleInputChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  attributeList = () => {
    return this.props.data.layer.advancefilterinfo
      .sort((a, b) => (a["display_name"] > b["display_name"] ? 1 : -1))
      .map((column, index) => {
        if (
          !(
            this.props.data.layer.layer_id == 2 &&
            column.model_name == "Establishment"
          ) &&
          !(
            this.props.data.layer.layer_id != 2 &&
            (column.model_name == "Member_Details" ||
              column.model_name == "Property_Other_Details" ||
              column.model_name == "Member_Diseases")
          )
        ) {
          return (
            <option key={index} value={column.display_name}>
              {column.display_name}
            </option>
          );
        }
      });
  };

  isValidAtribute = (index, field) => {
    if (field === "operation") {
      if (this.props.data[field.concat(index)] != "=") {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.props.data[field.concat(index)]) {
        return true;
      } else {
        return false;
      }
    }
  };

  onInput = (query, index) => {
    this.handleSearchValueChange([query], index);
  };

  render() {
    const { index, layerColumns } = this.props;
    return (
      <div>
        {index > 0 && (
          <ConditionSelector
            data={this.props.data}
            handleInputChange={this.props.handleInputChange}
            index={index}
          />
        )}

        <FormGroup controlId="formInlineName">
          <Col xs={4} sm={4} md={4} lg={4}>
            <ControlLabel>Attribute</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              name={"column".concat(index)}
              value={this.props.data["column".concat(index)]}
              onChange={(e) =>
                e.target.value && this.attributeSelect(e, index, true)
              }
              autoComplete="on"

              required
              style={
                !this.isValidAtribute(index, "column")
                  ? { borderColor: "red" }
                  : {}
              }
            >
              <option value="">SELECT</option>
              {this.attributeList()}
            </FormControl>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3}>
            <ControlLabel>Operation</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              name={"operation".concat(index)}
              value={this.props.data["operation".concat(index)]}
              onChange={(el) =>
                el.target.value && this.props.handleInputChangeOperation(el)
              }
              disabled={this.props.data["column".concat(index)] == ""}
              required
              style={
                !this.isValidAtribute(index, "operation")
                  ? { borderColor: "red" }
                  : {}
              }
            >
              <option value="">SELECT</option>
              {this.props.data.layer.advancefilterinfo.map(
                (column, indexes) => {
                  // console.log('column',column);
                  if (
                    this.props.data["column".concat(index)] ==
                    column.display_name
                  ) {
                    return Object.keys(column.operators.op).map((key, i) => {
                      return (
                        <option key={i + indexes} value={key}>
                          {column.operators.op[key]}
                        </option>
                      );
                    });
                  }
                }
              )}
            </FormControl>
          </Col>
          {/* {this.props.data["operation".concat(index)] != "=" && ( */}

          <Col xs={4} sm={4} md={4} lg={4}>
            <ControlLabel>Value</ControlLabel>
            {this.props.data.layer.advancefilterinfo.map((column, i) => {
              // console.log("filter value",column)
              if (
                this.props.data["column".concat(index)] == column.display_name
              ) {
                if (
                  // column.auto_complete != true
                  this.props.data["operation".concat(index)] === "like"
                ) {
                  return (
                    <div
                      onClick={() => {
                        let events = {
                          target: {
                            value: this.props.data["column".concat(index)],
                          },
                        };
                        // console.log('ward value form filter',events);
                        this.attributeSelect(events, index, false);
                      }}
                      key={i}
                    >
                      <FormControl
                        type="input"
                        placeholder="ENTER"
                        name={"value".concat(index)}
                        value={this.props.data["value".concat(index)]}
                        disabled={
                          this.props.data["operation".concat(index)] == "="
                        }
                        onChange={(event) => {
                          this.handleSearchValueChange(
                            [event.target.value],
                            index
                          );
                          // this.props.handleInputChangeDropdown(
                          //   event.target.value,
                          //   index
                          // );
                        }}
                        required
                        key={i}
                        style={
                          !this.isValidAtribute(index, "value")
                            ? { borderColor: "red" }
                            : {}
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => {
                        let events = {
                          target: {
                            value: this.props.data["column".concat(index)],
                          },
                        };
                        this.attributeSelect(events, index, false);
                      }}
                      key={i}
                    >
                      {
                        <AsyncTypeahead
                          minLength={0}
                          id="atribute_value"
                          isLoading={this.state.isLoading}
                          labelKey={(option) => `${option}`}
                          onSearch={(query) => {
                            if (query.length > 0) {
                              this.searchValue(query);
                              this.onInput(query, index);
                              // this.isValidAtribute(index, "value");
                            }
                          }}
                          options={this.state.options ? this.state.options : []}
                          onChange={(event) => {
                            event.length === 0 &&
                              this.props.handleClearValue("", index);
                            this.handleSearchValueChange(event, index);
                          }}
                          ref={this.myRef}
                          inputProps={
                            !this.isValidAtribute(index, "value")
                              ? {
                                  style: {
                                    borderColor: "red",
                                  },
                                }
                              : {
                                  style: {},
                                }
                          }
                          selected={this.props.data["temp".concat(index)]}
                          placeholder="SELECT"
                          // onBlur={() => {
                          //   this.state.query.length > 0 &&
                          //     this.handleSearchValueChange(
                          //       [this.state.query],
                          //       index
                          //     );
                          //   this.isValidAtribute(index, "value");
                          // }}

                          // key={i}
                          // defaultSelected={
                          //   this.props.data["temp".concat(index)]
                          // }
                          // placeholder="SELECT"
                        />
                      }

                      {/* <Typeahead
                        id="basic-typeahead-single"
                        disabled={
                          this.props.data["operation".concat(index)] == "="
                        }
                        labelKey="name"
                        name={"value".concat(index)}
                        onChange={(event) => {
                          this.handleSearchValueChange(event, index);
                        }}
                        options={this.state.options ? this.state.options : []}
                        placeholder="SELECT"
                        selected={this.props.data["temp".concat(index)]}
                        key={i}
                        inputProps={
                          !this.isValidAtribute(index, "value")
                            ? {
                                style: {
                                  borderColor: "red",
                                },
                              }
                            : {
                                style: {},
                              }
                        }
                        ref={this.myRef}
                        onBlur={() => {
                          this.handleSearchValueChange(
                            [this.myRef.current._input.value],
                            index
                          );
                          this.isValidAtribute(index, "value");
                        }}
                        // renderMenuItemChildren={(option) =>
                        //   this.getHighlightedText(
                        //     this.myRef.current &&
                        //       this.myRef.current._input.value,
                        //     this.state.options ? this.state.options : []
                        //   )
                        // }
                      /> */}
                    </div>
                  );
                }
              }
            })}
          </Col>

          <Col xs={1} sm={1} md={1} lg={1}>
            {/* <br /> */}
            <Row>
              <br />
            </Row>

            <a href="#">
              <i
                onClick={() => this.props.deleteField(index)}
                style={{
                  marginTop: 10,
                }}
                className="fa fa-trash-o"
              ></i>
            </a>
          </Col>

          {/* )} */}
        </FormGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    layerColumns: state.advancedFilter.layerColumns,
    // attributes: state.advancedFilter.attributes,
    datas: state.advancedFilter.data,
    layers: state.advancedFilter.layers,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchDataByAttribute: (searchBy, query, table) =>
      dispatch(fetchDataByAttribute(searchBy, query, table)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getColumns: () => dispatch(getColumns()),
    getLayers: () => dispatch(getLayers()),
    fetchValueByAttribute: (
      model,
      attribute,
      data_type,
      category_field,
      category_id,
      isSearch,
      searchParam
    ) =>
      dispatch(
        fetchValueByAttribute(
          model,
          attribute,
          data_type,
          category_field,
          category_id,
          isSearch,
          searchParam
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
