import { FETCHINGDATA, FETCHDATA_FAILED, FETCHDATA_SUCCESS } from "./constants";
const initialState = {
  fetching: false,
  data: null,
  response_id: null,
  // feature_data:null,
  // layerData: null,
  // resultLayer: null,
  // showAdvancedFilterWindow: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHINGDATA:
      return {
        ...state,
        fetching: true,
      };
    case FETCHDATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        response_id: action.response.data,
      };

    case FETCHDATA_FAILED:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}
