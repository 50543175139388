import React, { Component } from "react";
import "./GoToXY.css";
import goToXYRed from "../../../../../asset/svg/measure_red.svg";
import GoToXYPanel from "./GoToXYPanel";
import Widget from "../../../../../containers/widget/widget";
import { connect } from "react-redux";
import { updateWidgetEnable } from "../../map_component/data/actions";

class GoToXY extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGoToXYPanelControl: false,
    };
  }
  componentDidMount() {}
  handleShowGoToXYPanelControl = () => {
    this.setState({
      showGoToXYPanelControl: !this.state.showGoToXYPanelControl,
    });
    this.props.updateWidgetEnable("1");
  };

  render() {
    return (
      <React.Fragment>
        <Widget
          placement="right"
          tooltipText="Go To Location"
          handleClick={this.handleShowGoToXYPanelControl}
          img={goToXYRed}
          class="gotoXY"
        />
        <GoToXYPanel
          showGoToXYPanelControl={this.state.showGoToXYPanelControl}
          handleShowGoToXYPanelControl={this.handleShowGoToXYPanelControl}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (enable) => dispatch(updateWidgetEnable(enable)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(GoToXY);
