import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "../../components/user/login/Login";
import Logout from "../../components/user/logout/Logout";
import MapViewer from "../../components/map_viewer/MapViewer";
import Report from "../../components/report/Report";
import Dashboard from "../../components/dashboard";
import SocioEconomic from "../../components/dashboard/socioeconomic";
import RoadAnalysis from "../../components/dashboard/Analysis/RoadAnalysis";
import Analysis from "../../components/dashboard/Analysis/Analysis";

// import Road_Analysis from "../../components/dashboard/Analysis/RoadAnalysis";

// const Logout = React.lazy(() => import("../../components/user/logout/Logout"));
// const MapViewer = React.lazy(() =>
//   import("../../components/map_viewer/MapViewer")
// );
// const Report = React.lazy(() => import("../../components/report/Report"));
// const Dashboard = React.lazy(() => import("../../components/dashboard/"));
// const SocioEconomic = React.lazy(() =>
//   import("../../components/dashboard/socioeconomic")
// );

class AppRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Login} />
        <Suspense fallback={<div id="loader"></div>}>
          <Route exact path="/logout" component={Logout} />
          <PrivateRoute exact path="/map" component={MapViewer} />
          <PrivateRoute exact path="/report" component={Report} />
          <PrivateRoute exact path="/socioeconomic" component={SocioEconomic} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/analysis" component={Analysis} />
          <PrivateRoute exact path="/RoadAnalysis" component={RoadAnalysis} />
        </Suspense>
      </Switch>
    );
  }
}

export default AppRoutes;
