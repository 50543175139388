import {
  BUFFER_FETCHING,
  BUFFER_FETCH_SUCCESS,
  BUFFER_FETCH_FAILED,
} from "./constants";

const initialState = {
  fetching: false,
  response_features: null,
};
export default function(state = initialState, action) {
  switch (action.type) {
    case BUFFER_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case BUFFER_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        response_features: action.response.data,
      };
    case BUFFER_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
