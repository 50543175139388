import React, { Component } from "react";
import {
  Panel,
  ButtonToolbar,
  Table,
  ButtonGroup,
  Button,
  Badge,
  Label,
} from "react-bootstrap";
import Overlay from "ol/Overlay.js";
import "./MinimalInfo.css";
import closeButton from "../../../../../asset/svg/close_icon.svg";
import DataFormat from "../../helper/DataFormat";

class MinimalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOverlay: null,
      minimalInfoContent: null,
    };
    this.popup = React.createRef();
    this.popup_close = React.createRef();
    this.popup_more = React.createRef();
    this.zoomTo = React.createRef();
    this.onIncrement = React.createRef();
    this.onDecrement = React.createRef();
  }
  UNSAFE_componentWillMount() {
    this.setState({
      popupOverlay: new Overlay({
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      }),
    });
  }

  componentDidMount() {
    this.state.popupOverlay.setElement(this.popup.current);
    this.popup_close.current.onclick = (e) => this.props.onMinimalInfoClose(e);
    this.popup_more.current.onclick = (e) => this.props.showDetailedInfo(e);
    this.zoomTo.current.onclick = (e) => this.zoomToClickedFeature(e);
    this.onIncrement.current.onclick = (e) => this.props.onCountIncrement(e);
    this.onDecrement.current.onclick = (e) => this.props.onCountDecrement(e);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clickEvent != this.props.clickEvent) {
      this.props.mapComponent.addOverlay(this.state.popupOverlay);
      this.state.popupOverlay.setPosition(this.props.clickEvent.coordinate);
    }
  }
  componentWillUnmount() {
    this.state.popupOverlay.setMap(null);
  }
  formatLayerCount = () => {
    if (this.props.minimalInfoContentLayerCounts != null) {
      let minimalInfoContentLayerCount = this.props
        .minimalInfoContentLayerCounts[this.props.count];
      return Object.values(minimalInfoContentLayerCount).map((layer) => {
        return (
          <tr>
            <td>{layer.layer_label}</td>
            <td>
              {layer.count}
              {layer.length > 0 && " (Length-" + layer.length + " KM)"}
            </td>
          </tr>
        );
      });
    }
  };
  formatInfo() {
    if (this.props.minimalInfoContents != null) {
      let minimalInfoContent = this.props.minimalInfoContents[this.props.count];
      return Object.keys(minimalInfoContent).map((key) => {
        if (minimalInfoContent[key].display_name === "Updation status") {
          return (
            <>
              {!(
                minimalInfoContent[key].value === "NA" ||
                minimalInfoContent[key].value === -2
              ) && (
                <tr key={key}>
                  <td style={{ width: "300px" }}>
                    {minimalInfoContent[key].display_name}
                  </td>
                  <td style={{ width: "300px" }}>
                    {DataFormat.updationStatus(minimalInfoContent[key].value)}
                  </td>
                </tr>
              )}
            </>
          );
        } else {        
          return (
            <>
              {!(
                minimalInfoContent[key].value === "NA" ||
                minimalInfoContent[key].value === -2
              ) && (
                <tr key={key}>
                  <td style={{ width: "300px" }}>
                    {minimalInfoContent[key].display_name}
                  </td>
                  <td style={{ width: "300px" }}>
                    {DataFormat.dataFormat(minimalInfoContent[key].value)}
                  </td>
                </tr>
              )}
            </>
          );
        }
      });
    }
  }

  zoomToClickedFeature = () => {
    var extent = this.props.clickedLayer.getSource().getExtent();
    this.props.mapComponent
      .getView()
      .fit(extent, this.props.mapComponent.getSize());
  };

  showUpdationStatus = () => {
    const { layerParent, status } = this.props;
    if (
      layerParent.includes("waterbody_pond") ||
      layerParent.includes("buildingasset_property")
    ) {
      return (
        <>
          <tr>
            <td>Updation status</td>
            <td>{DataFormat.updationStatus(status)}</td>
          </tr>
        </>
      );
    }
  };

  render() {
    const items = this.formatInfo();
    const layerCount = this.formatLayerCount();

    return (
      <div>
        <div
          className="ol-popup"
          ref={this.popup}
          hidden={!this.props.isMinimalInfoVisible}
        >
          <Panel bssStyle="success" className="minimalInfoPopup">
            <Panel.Heading className="minimalInfoHead">
              <Panel.Title componentClass="h3">
                <Label style={{ backgroundColor: "#142240" }}>
                  Total Features
                </Label>
                <Badge>{this.props.total_count + 1}</Badge>
              </Panel.Title>
              <ButtonToolbar className="pull-right button Btn_toolbar">
                <ButtonGroup>
                  <button
                    className="btn btnP popupCloseButton"
                    disabled={this.props.count == 0}
                    ref={this.onDecrement}
                  >
                    <i className="fa fa-angle-left" />
                  </button>
                  <Button className=" countbuttonMin popupCloseButton">
                    {this.props.count + 1}
                  </Button>
                  <button
                    className="btn btnP popupCloseButton"
                    disabled={this.props.count == this.props.total_count}
                    ref={this.onIncrement}
                  >
                    <i className="fa fa-angle-right" />
                  </button>
                  <button className="mpanelheadbtn" ref={this.popup_close}>
                    <img src={closeButton} />
                  </button>
                </ButtonGroup>
              </ButtonToolbar>
            </Panel.Heading>
            <Panel.Body className="minimalInfoPanelBody">
              {this.props.minimalInfoContents == null && (
                <h5>There are no Layers selected</h5>
              )}
              <Table
                striped
                bordered
                condensed
                hover
                className="minimalinfoContent"
              >
                <tbody>
                  {this.showUpdationStatus()}
                  {items}
                  {this.props.minimalInfoContentLayerCounts != null &&
                    Object.keys(
                      this.props.minimalInfoContentLayerCounts[this.props.count]
                    ).length > 0 && (
                      <tr>
                        <td
                          colSpan="2"
                          style={{
                            textAlign: "center",
                            backgroundColor: "var(--secondary-color)",
                            color: "white",
                          }}
                        >
                          <b>Layer Count & Length</b>
                        </td>
                      </tr>
                    )}
                  {layerCount}
                </tbody>
              </Table>
            </Panel.Body>
            <button bsStyle="success" className="moreBtn" ref={this.popup_more}>
              More
            </button>
            <button
              bsStyle="danger"
              className="zoomBtn"
              onClick={this.zoomToClickedFeature}
              ref={this.zoomTo}
            >
              Zoom To
            </button>
          </Panel>
        </div>
      </div>
    );
  }
}

export default MinimalInfo;
