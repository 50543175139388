import React from "react";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./widget.css";

const Widget = (props) => {

  return (
    <OverlayTrigger
      placement={props.placement}
      overlay={<Tooltip id={props.tooltipText}>{props.tooltipText}</Tooltip>}
    >
      <button
        className={`widget-button ` + props.class}
        onClick={props.handleClick}
      >
        <Image
          style={props.imageStyle ? props.imageStyle : {}}
          src={props.img}
        />
      </button>
    </OverlayTrigger>
  );
};
export default Widget;
