import React, { Component } from "react";
import { Route, Redirect, Replace } from "react-router-dom";
import localStorageHandler from "../../util/storage/";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const a = this.props.path;

    var b = a.toLocaleLowerCase();
    const menu = localStorageHandler.getMenu();
    if (!this.props.isAuthenticated) {
      this.props.history.push("/login");
    } else if (menu != null) {
      const userMenu = menu.map((menuItem) => {
        var d = menuItem.menu_name.toLocaleLowerCase();

        if (a.includes(d)) {
          if (menuItem.permission[0].view_permission == 0) {
            this.props.history.push("/map");
          }
        }
      });
    }
  }
  componentDidUpdate(prevProps) {
    const a = this.props.path;
    var b = a.toLocaleLowerCase();
    const menu = localStorageHandler.getMenu();
    if (!this.props.isAuthenticated) {
      this.props.history.push("/login");
    } else if (menu != null) {
      const userMenu = menu.map((menuItem) => {
        var d = menuItem.menu_name.toLocaleLowerCase();
        if (a.includes(d)) {
          if (menuItem.permission[0].view_permission == 0) {
            this.props.history.push("/map");
          }
        }
      });
    }
  }
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) =>
          this.props.isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.loginReducer.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
);
