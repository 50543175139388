import { IS_LOADING, LOADING_COMPLETE } from "./constants";
const initialState = {
  isLoading: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case LOADING_COMPLETE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
