import React, { Component } from "react";
import "./Buffer.css";
import buffer from "../../../../../asset/svg/buffer_red.svg";
import BufferPanel from "./BufferPanel";
import { connect } from "react-redux";
import { updateWidgetEnable } from "../../map_component/data/actions";
import Widget from "../../../../../containers/widget/widget";

class Buffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBufferPanelControl: false,
    };
  }

  handleShowBufferPanelControl = () => {
    this.setState({
      showBufferPanelControl: !this.state.showBufferPanelControl,
    });
    this.props.updateWidgetEnable("4");
  };

  render() {
    return (
      <React.Fragment>
        <Widget
          placement="right"
          tooltipText="Buffer"
          handleClick={this.handleShowBufferPanelControl}
          img={buffer}
          class="buffer"
        />
        <BufferPanel
          showBufferPanelControl={this.state.showBufferPanelControl}
          handleShowBufferPanelControl={this.handleShowBufferPanelControl}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (enable) => dispatch(updateWidgetEnable(enable)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Buffer);
