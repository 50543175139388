import React, { Component } from "react";
import { NavItem } from "react-bootstrap";
import Widget from "../../../../../containers/widget/widget";
import "./Toolbar.css";

class ToolbarMenus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <NavItem id="toolnav" eventKey={2} style={{ marginRight: "25px" }}>
        <Widget
          placement="top"
          tooltipText={this.props.tooltipText}
          handleClick={this.props.handleClick}
          img={this.props.img}
          class="toolIcons"
        />
      </NavItem>
    );
  }
}

export default ToolbarMenus;
