export const getData = (response) => {
  let diagrams = response;
  // console.log('diagrm',diagrams);
  diagrams.road_material.data.datasets[0]["backgroundColor"] = [
    "rgba(230, 20, 9, 0.2)",
    "rgba(255, 201, 5, 0.2)",
    "rgba(46, 219, 2, 0.2)",
    "rgba(2, 219, 219, 0.2)",
    "rgba(127, 4, 199, 0.2)",
    "rgba(199, 4, 118, 0.2)",
    "rgba(3, 17, 145, 0.2)",
  ];
  diagrams.road_material.data.datasets[0]["borderColor"] = [
    "rgba(230, 20, 9, 1)",
    "rgba(255, 201, 5, 1)",
    "rgba(46, 219, 2, 1)",
    "rgba(2, 219, 219, 1)",
    "rgba(127, 4, 199, 1)",
    "rgba(199, 4, 118, 1)",
    "rgba(3, 17, 145, 1)",
  ];
  diagrams.road_material.data.datasets[0]["borderWidth"] = 1;

  diagrams.property.data.datasets[0]["backgroundColor"] =
    "rgba(230, 20, 9, 0.6)";

  diagrams.road_category.data.datasets[0]["backgroundColor"] = [
    "rgba(230, 20, 9, 0.2)",
    "rgba(255, 201, 5, 0.2)",
    "rgba(46, 219, 2, 0.2)",
    "rgba(2, 219, 219, 0.2)",
    "rgba(127, 4, 199, 0.2)",
    "rgba(199, 4, 118, 0.2)",
    "rgba(255, 159, 240, 0.2)",
    "rgba(255, 19, 140, 0.2)",
  ];
  diagrams.road_category.data.datasets[0]["borderColor"] = [
    "rgba(230, 20, 9, 1)",
    "rgba(255, 201, 5, 1)",
    "rgba(46, 219, 2, 1)",
    "rgba(2, 219, 219, 1)",
    "rgba(127, 4, 199, 1)",
    "rgba(199, 4, 118, 1)",
    "rgba(255, 159, 240, 1)",
    "rgba(255, 19, 140, 1)",
  ];
  diagrams.road_category.data.datasets[0]["borderWidth"] = 1;
  diagrams.population.data.datasets[0]["backgroundColor"] = [
    "rgba(230, 20, 9, 0.2)",
    "rgba(255, 201, 5, 0.2)",
    "rgba(46, 219, 2, 0.2)",
    "rgba(2, 219, 219, 0.2)",
  ];
  diagrams.population.data.datasets[0]["borderColor"] = [
    "rgba(230, 20, 9, 1)",
    "rgba(255, 201, 5, 1)",
    "rgba(46, 219, 2, 1)",
    "rgba(2, 219, 219, 1)",
  ];
  diagrams.population.data.datasets[0]["borderWidth"] = 1;
  return diagrams;
};
