import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "react-bootstrap";
import "../style.css";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      actualFilters,
      singleFilters,
      ageFilters,
      ward_id,
    } = this.props.filterData;
    console.log('props',this.props);
    return (
      <>
        <Row>
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup controlId="formControlsSelect">
              <ControlLabel>Select Ward</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                value={ward_id ? ward_id : ""}
                onChange={(e) => this.props.handleWardChange(e.target.value)}
              >
                <option value="">Select Ward</option>
                {this.props.wards &&
                  this.props.wards.map((ward) => (
                    <option value={ward.id} key={ward.id}>
                      {ward.ward_name} ({ward.ward_no})
                    </option>
                  ))}
                <option value="0">ALL WARDS</option>
              </FormControl>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Gender</ControlLabel>
              <FormControl
                componentClass="select"
                name="type"
                onChange={(e) =>
                  this.props.setSingleFilter(e.target.value, "gender")
                }
                value={singleFilters?.gender}
                required
              >
                <option value="">None</option>
                {this.props.isFiltersLoaded &&
                  this.props.filters.genders.map((filter) => {
                    if (!(filter.gender === "NR" || filter.gender === "NA"))
                      return (
                        <option key={filter.id} value={filter.id}>
                          {filter.gender}
                        </option>
                      );
                  })}
              </FormControl>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Marital Status</ControlLabel>
              <FormControl
                componentClass="select"
                name="type"
                onChange={(e) =>
                  this.props.setSingleFilter(e.target.value, "marital_status")
                }
                value={singleFilters?.marital_status}
                required
              >
                <option value="">None</option>
                {this.props.isFiltersLoaded &&
                  this.props.filters.marital_status.map((filter) => {
                    if (!(filter.status === "NR" || filter.status === "NA"))
                      return (
                        <option key={filter.id} value={filter.id}>
                          {filter.status}
                        </option>
                      );
                  })}
              </FormControl>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Job Category</ControlLabel>
              <FormControl
                componentClass="select"
                name="type"
                onChange={(e) =>
                  this.props.setSingleFilter(e.target.value, "job_category")
                }
                value={singleFilters?.job_category}
                required
              >
                <option value="">None</option>
                {this.props.isFiltersLoaded &&
                  this.props.filters.job_category.map((filter) => {
                    if (
                      !(
                        filter.fields.job_category === "NR" ||
                        filter.fields.job_category === "NA"
                      )
                    )
                      return (
                        <option
                          key={filter.fields.job_category}
                          value={filter.fields.job_category}
                        >
                          {filter.fields.job_category}
                        </option>
                      );
                  })}
              </FormControl>
            </FormGroup>
          </Col>

          {singleFilters?.job_category && singleFilters?.job_category !== "" && (
            <Col xs={12} sm={4} md={3} lg={2}>
              <FormGroup>
                <ControlLabel>Job</ControlLabel>
                <FormControl
                  componentClass="select"
                  name="type"
                  onChange={(e) =>
                    this.props.setFilters(e.target.value, "occuppation_id__in")
                  }
                  required
                >
                  <option value="">None</option>
                  {this.props.isJobFilterLoaded &&
                    this.props.jobFilter.map((filter) => {
                      if (!(filter.job === "NR" || filter.job === "NA"))
                        return (
                          <option value={filter.id} key={filter.id}>
                            {filter.job}
                          </option>
                        );
                    })}
                </FormControl>
              </FormGroup>
            </Col>
          )}
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Education Category</ControlLabel>
              <FormControl
                componentClass="select"
                name="type"
                onChange={(e) =>
                  this.props.setSingleFilter(
                    e.target.value,
                    "education_category"
                  )
                }
                value={singleFilters?.education_category}
                required
              >
                <option value="">None</option>
                {this.props.isFiltersLoaded &&
                  this.props.filters.education_categories.map((filter) => {
                    if (!(filter.category === "NR" || filter.category === "NA"))
                      return (
                        <option value={filter.id} key={filter.id}>
                          {filter.category}
                        </option>
                      );
                  })}
              </FormControl>
            </FormGroup>
          </Col>
          {singleFilters?.education_category &&
            singleFilters?.education_category !== "" && (
              <Col xs={12} sm={4} md={3} lg={2}>
                <FormGroup>
                  <ControlLabel>Educations</ControlLabel>
                  <FormControl
                    componentClass="select"
                    name="type"
                    onChange={(e) =>
                      this.props.setFilters(e.target.value, "education__in")
                    }
                    required
                  >
                    <option value="">None</option>
                    {this.props.isEducationFilterLoaded &&
                      this.props.educationFilter.map((filter) => {
                        if (
                          !(
                            filter.education === "NR" ||
                            filter.education === "NA"
                          )
                        )
                          return (
                            <option value={filter.id} key={filter.id}>
                              {filter.education}
                            </option>
                          );
                      })}
                  </FormControl>
                </FormGroup>
              </Col>
            )}
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Diseases</ControlLabel>
              <FormControl
                componentClass="select"
                name="type"
                onChange={(e) =>
                  this.props.setFilters(e.target.value, "diseases__in")
                }
                value={actualFilters?.diseases__in}
                required
              >
                <option value="">None</option>
                {this.props.isFiltersLoaded &&
                  this.props.filters.disease_types.map((filter) => {
                    if (!(filter.disease === "NR" || filter.disease === "NA"))
                      return (
                        <option value={filter.id} key={filter.id}>
                          {filter.disease}
                        </option>
                      );
                  })}
              </FormControl>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Pension</ControlLabel>
              <FormControl
                componentClass="select"
                name="type"
                value={actualFilters?.pension__in}
                onChange={(e) =>
                  this.props.setFilters(e.target.value, "pension__in")
                }
                required
              >
                <option value="">None</option>
                {this.props.isFiltersLoaded &&
                  this.props.filters.pension_types.map((filter) => {
                    if (!(filter.pension === "NR" || filter.pension === "NA"))
                      return (
                        <option value={filter.id} key={filter.id}>
                          {filter.pension}
                        </option>
                      );
                  })}
              </FormControl>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Min Age</ControlLabel>
              <FormControl
                type="number"
                placeholder="Min Age"
                value={ageFilters.min_age}
                onChange={(e) =>
                  this.props.setFilters(e.target.value, "min_age")
                }
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Max Age</ControlLabel>
              <FormControl
                type="number"
                placeholder="Max Age"
                value={ageFilters.max_age}
                onChange={(e) =>
                  this.props.setFilters(e.target.value, "max_age")
                }
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Disability</ControlLabel>
              <FormControl
                componentClass="select"
                name="type"
                onChange={(e) =>
                  this.props.setFilters(e.target.value, "disability__in")
                }
                value={actualFilters?.disability__in}
                required
              >
                <option value="">None</option>
                {this.props.isFiltersLoaded &&
                  this.props.filters.disability_types.map((filter) => {
                    if (
                      !(
                        filter.disability === "NR" || filter.disability === "NA"
                      )
                    )
                      return (
                        <option key={filter.id} value={filter.id}>
                          {filter.disability}
                        </option>
                      );
                  })}
              </FormControl>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2}>
            <FormGroup>
              <ControlLabel>Caste</ControlLabel>
              <FormControl
                componentClass="select"
                name="type"
                onChange={(e) =>
                  this.props.setSingleFilter(e.target.value, "caste")
                }
                value={singleFilters?.caste}
                required
              >
                <option value="">None</option>
                {this.props.isFiltersLoaded &&
                  this.props.filters.castes.map((filter) => {
                    if (!(filter.caste === "NR" || filter.caste === "NA"))
                      return (
                        <option key={filter.id} value={filter.id}>
                          {filter.caste}
                        </option>
                      );
                  })}
              </FormControl>
            </FormGroup>
          </Col>

          <Col xs={12} sm={4} md={3} lg={1}>
            <FormGroup>
              <Button
                style={{ marginTop: "18px" }}
                onClick={() => this.props.handleSubmit()}
                bsStyle="danger"
                block
              >
                Submit
              </Button>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} md={3} lg={1}>
            <FormGroup>
              <Button
                style={{ marginTop: "18px" }}
                onClick={() => this.props.clearFilter()}
                bsStyle="danger"
                block
              >
                Clear
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    filters: state.dashboard.filters,
    isFiltersLoaded: state.dashboard.isFiltersLoaded,
    wards: state.dashboard.wards,
    isWardsLoaded: state.dashboard.isWardsLoaded,
    jobFilter: state.dashboard.jobFilter,
    isJobFilterLoaded: state.dashboard.isJobFilterLoaded,
    educationFilter: state.dashboard.educationFilter,
    isEducationFilterLoaded: state.dashboard.isEducationFilterLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
