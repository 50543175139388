import { applyMiddleware, createStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import logger from "redux-logger";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
const initialState = {};
const middleWare = [thunk, routerMiddleware(history)];
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  middleWare.push(logger);
}

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  applyMiddleware(...middleWare)
);
export default store;
