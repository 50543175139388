import APIRequest from "../util/api/APIRequest";

const accessibleLayers = (url) => {
  return APIRequest({
    url: `map/getuseraddpermissonedlayers/`,
    method: "POST",
  });
};

const LayerService = {
  accessibleLayers,
};
export default LayerService;
