import React, { Component } from "react";
import { connect } from "react-redux";
import getLayerTree from "../../helper/LayerTreeLoader";
import "./GetCountPanel.css";
import OverlayPanel from "../overlay_panel/OverlayPanel";

class GetCountPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layers: getLayerTree(),
      count: [],
    };
  }

  render() {
    return (
      <OverlayPanel
        showControl={this.props.showGetCountPanelControl}
        handleShowControl={this.props.handleShowGetCountPanelControl}
        titleName="Count"
        headClass="getCountPanels"
        bodyClass="getCountPanelsBody"
      >
        <ul className="listStyleUl">
          {this.state.layers.map((parent, index) => (
            <li key={index}>
              <b>{parent.label}</b>
              <ul className="listStyleLi">
                {parent.children.map((child, i) => (
                  <li key={i} className="listStyleLi">
                    {child.label} - {child.count}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </OverlayPanel>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    legendUrls: state.mapReducer.legendUrls,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(GetCountPanel);
