import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
class ConfirmBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        style={{ top: "25%" }}
      >
        {!this.props.isNotSelected ? (
          <Modal.Header closeButton>
            <Modal.Title>Confirm Action</Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body>
          {this.props.massage && this.props.massage}
          {!this.props.massage && "Delete record??"}
        </Modal.Body>

        {!this.props.isNotSelected ? (
          <Modal.Footer>
            <Button bsStyle="danger" onClick={this.props.action}>
              Yes
            </Button>
            <Button bsStyle="success" onClick={this.props.onHide}>
              No
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button bsStyle="success" onClick={this.props.action}>
              OK
            </Button>
            {/* <Button bsStyle="danger" onClick={this.props.onHide}>
              Cancel
            </Button> */}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default ConfirmBox;
