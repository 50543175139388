import React, { Component } from "react";
import { doLogoutFomAdmin } from "../login/data/actions";
import { connect } from "react-redux";
class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.doLogoutFomAdmin();
  }
  render() {
    return (
      <div className="loginContainer">
        <p>Logout</p>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    doLogoutFomAdmin: () => dispatch(doLogoutFomAdmin()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
