import React, { Component } from "react";
import MenuItems from "../navbar_menus/MenuItems";
import "../navbar_menus/Toolbar.css";
import "../navbar_menus/ToolbarScript.js";

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="toolbar" style={{ marginTop: "23px", marginRight: "19px" }}>
        <MenuItems
          handleShowLayerControl={this.props.handleShowLayerControl}
          handleShowLegendControl={this.props.handleShowLegendControl}
          handleShowBaseMapControl={this.props.handleShowBaseMapControl}
          handleShowOpacityControl={this.props.handleShowOpacityControl}
          handleShowSettingsControl={this.props.handleShowSettingsControl}
        />
      </div>
    );
  }
}

export default Toolbar;
