import React, { Component } from "react";
import OlMap from "./map/map_component/Map";
import MapHeader from "./map_header/MapHeader";
import Loader from "../../components/loader/Loader";
import { withRouter } from "react-router-dom";
import SnackBar from "../snackbar";
import Toolbar from "./map_header/navigation/toolbar/Toolbar";
import "./map_header/MapHeader.css";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import MapHeaderResponsive from "./map_header/MapHeaderResponsive";

class MapViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLayerControl: false,
      showLegendControl: false,
      showStyleControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showSettingsControl: false,
      isDesktop: false,
      menuIsOpened: false,
      showMapHeader: true,
    };
  }

  handleToggle = (toggle) => {
    this.setState({ menuIsOpened: true });
  };

  handleShowLayerControl = () => {
    this.setState({
      showLegendControl: false,
      showBaseMapControl: false,
      showStyleControl: false,
      showOpacityControl: false,
      showSettingsControl: false,
      showLayerControl: !this.state.showLayerControl,
    });
  };
  handleShowStyleControl = () => {
    this.setState({
      showLayerControl: false,
      showLegendControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showSettingsControl: false,
      showStyleControl: !this.state.showStyleControl,
    });
  };
  handleShowLegendControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showSettingsControl: false,
      showLegendControl: !this.state.showLegendControl,
    });
  };
  handleShowBaseMapControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showLegendControl: false,
      showOpacityControl: false,
      showSettingsControl: false,
      showBaseMapControl: !this.state.showBaseMapControl,
    });
  };
  handleShowOpacityControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showLegendControl: false,
      showBaseMapControl: false,
      showSettingsControl: false,
      showOpacityControl: !this.state.showOpacityControl,
    });
  };
  handleShowSettingsControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showLegendControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showSettingsControl: !this.state.showSettingsControl,
    });
  };

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }
  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 768 });
  };

  render() {
    const isDesktop = this.state.isDesktop;

    return (
      <React.Fragment>
        {isDesktop ? (
          <React.Fragment>
            <MapHeader showMapHeader={this.state.showMapHeader} />
            <Toolbar
              handleShowLegendControl={this.handleShowLegendControl}
              handleShowLayerControl={this.handleShowLayerControl}
              handleShowStyleControl={this.handleShowStyleControl}
              handleShowBaseMapControl={this.handleShowBaseMapControl}
              handleShowOpacityControl={this.handleShowOpacityControl}
              handleShowSettingsControl={this.handleShowSettingsControl}
            />
          </React.Fragment>
        ) : (
          <div className="nav-bar">
            <Navbar open={this.state.menuIsOpened} onToggle={this.handleToggle}>
              <Navbar.Header>
                <Navbar.Brand>DRISHTI</Navbar.Brand>
                <Navbar.Toggle />
              </Navbar.Header>
              <Navbar.Collapse className="collapseResponsive">
                <Nav>
                  <NavItem>
                    <MapHeaderResponsive />
                  </NavItem>
                  <NavItem className="toolbarResponsive">
                    <Toolbar
                      handleShowLegendControl={this.handleShowLegendControl}
                      handleShowLayerControl={this.handleShowLayerControl}
                      handleShowStyleControl={this.handleShowStyleControl}
                      handleShowBaseMapControl={this.handleShowBaseMapControl}
                      handleShowOpacityControl={this.handleShowOpacityControl}
                      handleShowSettingsControl={this.handleShowSettingsControl}
                    />
                  </NavItem>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        )}

        <Loader />
        <SnackBar />
        <OlMap
          showLayerControl={this.state.showLayerControl}
          showLegendControl={this.state.showLegendControl}
          showStyleControl={this.state.showStyleControl}
          showBaseMapControl={this.state.showBaseMapControl}
          showOpacityControl={this.state.showOpacityControl}
          showSettingsControl={this.state.showSettingsControl}
          handleShowLayerControl={this.handleShowLayerControl}
          handleShowLegendControl={this.handleShowLegendControl}
          handleShowStyleControl={this.handleShowStyleControl}
          handleShowBaseMapControl={this.handleShowBaseMapControl}
          handleShowOpacityControl={this.handleShowOpacityControl}
          handleShowSettingsControl={this.handleShowSettingsControl}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(MapViewer);
