export function dataFormat(data) {
  return data === false
    ? "NO"
    : data === true
    ? "YES"
    : data === "NR" ||
      (typeof data === "string" && data.substr(0, 2) === "-1") ||
      data === -1
    ? "NOT RECEIVED"
    : data === "NA" || (typeof data === "string" && data.substr(0, 2) === "-2")
    ? "NOT APPLICABLE"
    : data;
}

export function dataFormatAnd(data) {
  return (
    data === null &&
    data === "NA" &&
    data === "None" &&
    data === -2 &&
    typeof data === "String" &&
    data.substr(0, 2) === "-2"
  );
}

export function dataFormatOr(data) {
  return (
    data === null ||
    data === "NA" ||
    data === "None" ||
    data === -2 ||
    (typeof data === "String" && data.substr(0, 2) === "-2")
  );
}
export const updationStatus = (status) => {
  if (status === "0") {
    return <h7 style={{ color: "#5400db" }}>CREATED</h7>;
  }
  if (status === "1") {
    return <h7 style={{ color: "#039935" }}>SUBMITTED</h7>;
  }
  if (status === "2") {
    return <h7 style={{ color: "#11612c" }}>VERIFIED</h7>;
  }
  if (status === "3") {
    return <h7 style={{ color: "#059936" }}>APPROVED</h7>;
  }
  if (status === "4") {
    return <h7 style={{ color: "#0095ff" }}>EDITED</h7>;
  }
  if (status === "5") {
    return <h7 style={{ color: "#b80229" }}>REJECTED FROM VERIFICATION</h7>;
  }
  if (status === "6") {
    return <h7 style={{ color: "#f00537" }}>REJECTED FROM APPROVAL</h7>;
  } else {
    return <h7>{status}</h7>;
  }
};

const DataFormats = {
  dataFormat,
  dataFormatAnd,
  dataFormatOr,
  updationStatus,
};
export default DataFormats;
