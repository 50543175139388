import React, { Component } from "react";
import { Grid, Col, Panel } from "react-bootstrap";
import analysisimage from '../../../asset/png/data.png'
import "./Analysis.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DashboardLayout from "../DashboardLayout";




class Analysis extends Component {
  constructor(props) {
    super(props);
    
  }
  
roadAnalysis =(value)=>{
this.props.history.push(`/RoadAnalysis`,{model : value})
}

propertyAnalysis =(value)=>{
  this.props.history.push(`/PropertyAnalysis`,{model : value})
  }

 

render() {
  
    return (
      <DashboardLayout handleRedirect={this.handleRedirect}>
      <Grid style={{ width: "100%" }}>
        <Col xs={12} sm={6} md={3} lg={2}>
          <Panel className="count-panel">
            <Panel.Body>
              <h4>Road Analysis</h4>
              <button
                className="road-icon-button"
                onClick={() => {
                  this.roadAnalysis("Road");     
                }}
              >
                <img style={{ width: '75%' }} src={analysisimage} alt="Analysis" />
              </button>
            </Panel.Body>
          </Panel>
        </Col>
      </Grid>
      </DashboardLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    layers: state.advancedFilter.layers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLayers: () => dispatch(getLayers()),
  };
}

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Analysis));
