import React, { Children, Component } from "react";
import { Collapse, Button, Table } from "react-bootstrap";

class TableFormat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicDetailStatus: false,
    };
  }

 
  render() {
    const {data} = this.props

    return (
      <React.Fragment>
        <Button
          bsStyle="primary"
          block
          onClick={(e) => {
            this.setState({
              basicDetailStatus: !this.state.basicDetailStatus,
            });
          }}
        >
          {this.props.displayName}
        </Button>
        <Collapse
          in={this.state.basicDetailStatus}
          className="featureInfoPanel"
        >
          <Table
            striped
            bordered
            condensed
            hover
            className="detailedInfoContent"
            style={{ width: "320px" }}
          >
            {this.props.children}
          </Table>
        </Collapse>
      </React.Fragment>
    );
  }
}
export default TableFormat;
