import React, { Component } from "react";
import { transform } from "ol/proj.js";
import { connect } from "react-redux";
import mapDefaults from "../../helper/mapDefaults";
import "./DefaultExtend.css";
import Widget from "../../../../../containers/widget/widget";
import homered from "../../../../../asset/svg/home_white.svg";
class DefaultExtend extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  setToDefaultExtend = () => {
    const { mapComponent } = this.props;
    mapComponent
      .getView()
      .setCenter(
        transform(mapDefaults.getDefaultCenter(), "EPSG:4326", "EPSG:3857")
      );
    mapComponent.getView().setZoom(mapDefaults.getDefaultZoom());
  };
  render() {
    return (
      <Widget
        placement="right"
        tooltipText="Default Extend"
        handleClick={this.setToDefaultExtend}
        img={homered}
        class="extendButton"
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultExtend);
