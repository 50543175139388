export const USER_FETCHING = "USER_FETCHING";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_FAILED = "USER_FETCH_FAILED";

export const USER_ADDIND = "ADD_USER";
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
export const USER_ADD_FAILED = "USER_ADD_FAILED";

export const USER_FETCH_BY_ID_SUCCESS = "USER_FETCH_BY_ID_SUCCESS";
export const USER_FETCH_BY_ID_FAILED = "USER_FETCH_BY_ID_FAILED";

export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILED = "USER_DELETE_FAILED";
