import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SERVER_UNAVAILABLE,
  GET_PUBLIC_USERS,
} from "./constants";
import localStorageHandler from "../../../../util/storage/";
const initialState = {
  isAuthenticated: localStorageHandler.isAuthenticated(),
  userInfo: {},
  loginFailed: false,
  loggingIn: false,
  message: "",
  serverunavailable: false,
  publicUsers: [],
};
export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        loggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userInfo: action.response.data,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        loggingIn: false,
        loginFailed: true,
        serverunavailable: false,
        message: action.response.data.message,
      };

    case SERVER_UNAVAILABLE:
      return {
        ...state,
        isAuthenticated: false,
        loggingIn: false,
        loginFailed: true,
        serverunavailable: true,
        message: "Service Unavailable",
      };
    case GET_PUBLIC_USERS:
      return {
        ...state,
        publicUsers: action.response.data.publicusers,
      };
    case LOGOUT:
      return { isAuthenticated: false };
    default:
      return state;
  }
}
