import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Filter.css";
import { Modal, Panel, ButtonToolbar, Button, Row } from "react-bootstrap";
import { toggleAdvancedFilterWindow } from "../../map_viewer/map_header/navigation/navbar_form/data/action";
import FilterForm from "../../map_viewer/map_header/advanced_filter/components/FilterForm";
import closeButton from "../../../asset/svg/close_icon.svg";
import ConfirmBox from "../../confirmation_box";

var temp;
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temp: [],

      showDeleteModal: false,
    };
    this.handleClearFilter = this.handleClearFilter.bind(this);
  }
  handleClearFilter = () => {
    this.setState({ showFilterConfirmation: true });
  };
  handleClose = () => {
    this.setState({ showFilterConfirmation: false });
  };
  render() {
    return (
      <Modal show={this.props.show} className="reportFilterModal">
        <Panel bsStyle="primary" className="reportFilterPanel">
          <Panel.Heading>
            <Panel.Title componentClass="h3">Filter</Panel.Title>
            <ButtonToolbar className="pull-right button">
              <img
                src={closeButton}
                className="panelheadbtn"
                onClick={this.closeModal}
              />
            </ButtonToolbar>
          </Panel.Heading>
          <Panel.Body>
            <Row>
              {this.props.filterFormCount.map((element, index) => {
                if (index < 8)
                  return (
                    <FilterForm
                      data={this.props.data}
                      handleInputChange={this.props.handleInputChange}
                      handleInputChangeDropdown={
                        this.props.handleInputChangeDropdown
                      }
                      handleInputChangeAttribute={
                        this.props.handleInputChangeAttribute
                      }
                      handleToUpdate={this.props.handleToUpdate}
                      handleInputChangeOperation={
                        this.props.handleInputChangeOperation
                      }
                      index={index}
                      key={index}
                      temp={temp}
                      deleteField={this.props.deleteField}
                      handleClearValue={this.props.handleClearValue}
                    />
                  );
              })}
            </Row>
            <Row style={{ margin: "20px 10px 10px 10px" }}>
              <Button
                bsStyle="danger"
                className="pull-left clearButton"
                disabled={this.props.filterFormCount.length === 0}
                onClick={this.handleClearFilter}
              >
                Clear Filter
              </Button>
              <Button
                inline="true"
                bsStyle="success"
                className="pull-right clearButton"
                style={{ marginLeft: "3px" }}
                onClick={this.okClick}
                disabled={
                  this.props.data.layer === "" ||
                  this.props.filterFormCount.length == 0
                }
              >
                OK
              </Button>
              <Button
                inline="true"
                bsStyle="primary"
                className="pull-right clearButton"
                // style={{ padding: "3px" }}
                onClick={() => this.props.addFilterForm("AddMore")}
                disabled={
                  this.props.data.layer === "" ||
                  this.props.filterFormCount.length === 8
                }
              >
                <i className="glyphicon glyphicon-plus-sign" />
                Add Filter
              </Button>
            </Row>
            <ConfirmBox
              massage={"Are you sure you want to clear this Filters?"}
              show={
                this.state.showFilterConfirmation &&
                this.props.filterFormCount.length > 0
              }
              action={() => {
                this.props.clearFilter();
                this.setState({ showFilterConfirmation: false });
              }}
              onHide={this.handleClose}
            />

            <ConfirmBox
              massage={
                "Please make sure all fields are filled in correctly or delete the fields"
              }
              show={this.state.showDeleteModal}
              action={() => {
                this.setState({ showDeleteModal: false });
              }}
              onHide={() => this.setState({ showDeleteModal: false })}
              isNotSelected={true}
            />
          </Panel.Body>
        </Panel>
      </Modal>
    );
  }

  //function to find the null feild value

  feildValidation() {
    for (let index = 0; index < this.props.filterFormCount.length; index++) {
      if (
        this.props.data["operation".concat(index)] === "=" ||
        !this.props.data["column".concat(index)] ||
        !this.props.data["value".concat(index)]
      ) {
        return true;
      }
    }
  }

  okClick = () => {
    // if (this.feildValidation()) return;
    this.props.hide();
    this.props.filterCheck();
  };
  closeModal = () => {
    this.props.hide();
    this.props.filterCheck();

    // if (this.feildValidation()) {
    //   this.setState({
    //     showDeleteModal: true,
    //   });
    // } else {
    //   this.props.hide();
    //   this.props.filterCheck();
    // }
  };
}

function mapStateToProps(state) {
  return {
    show: state.mapSearch.showAdvancedFilterWindow,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hide: () => dispatch(toggleAdvancedFilterWindow()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter));
