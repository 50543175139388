import React, { Component } from "react";
import {
  Collapse,
  Row,
  Col,
  Grid,
  Panel,
  Button,
  ButtonToolbar,
} from "react-bootstrap";
import "./OverlayPanel.css";
import closeButton from "../../../../../asset/svg/close_icon.svg";

class OverlayPanel extends Component {
  state = {};
  render() {
    const { bodyClass, headClass } = this.props;
    return (
      <React.Fragment>
        <Collapse
          in={this.props.showControl}
          className={headClass ? headClass : "overlayPanel"}
        >
          <Grid>
            <Row className="show-grid">
              <Col>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">
                      {this.props.titleName}
                    </Panel.Title>

                    <ButtonToolbar className="pull-right button">
                      {this.props.layerCheck && (
                        <Button
                          className="uncheck_all"
                          onClick={this.props.handleUnCheckLayer}
                        >
                          UNCHECK ALL
                        </Button>
                      )}
                      <img
                        src={closeButton}
                        className="panelheadbtn"
                        onClick={this.props.handleShowControl}
                      />
                    </ButtonToolbar>
                  </Panel.Heading>
                  <Panel.Body
                    className={bodyClass ? bodyClass : "overlayPanelBody"}
                  >
                    {this.props.children}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default OverlayPanel;
