import React, { Component } from "react";
import "./MeasureAreaLength.css";
import measurementRed from "../../../../../asset/svg/measurement_red.svg";
import MeasurePanel from "./MeasurePanel";
import { updateWidgetEnable } from "../../map_component/data/actions";
import { connect } from "react-redux";
import Widget from "../../../../../containers/widget/widget";

class MeasureAreaLength extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMeasurePanelControl: false,
    };
  }
  handleShowMeasurePanelControl = () => {
    this.setState({
      showMeasurePanelControl: !this.state.showMeasurePanelControl,
    });
    this.props.updateWidgetEnable("2");
  };

  render() {
    return (
      <React.Fragment>
        <Widget
          placement="right"
          tooltipText="Measurement"
          handleClick={this.handleShowMeasurePanelControl}
          img={measurementRed}
          class="extendButtonMeasure"
        />
        <MeasurePanel
          showMeasurePanelControl={this.state.showMeasurePanelControl}
          handleShowMeasurePanelControl={this.handleShowMeasurePanelControl}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (showWidget) =>
      dispatch(updateWidgetEnable(showWidget)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasureAreaLength);
