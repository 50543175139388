import {
  FETCHING,
  FETCH_FAILED,
  FETCH_SUCCESS,
  VALUE_FETCHING,
  VALUE_FETCH_FAILED,
  VALUE_FETCH_SUCCESS,
  FETCHING_LAYER_SUCCESS,
  UPDATE_SEARCH_LAYER,
  TOGGLE_ADVANCED_FILTER_WINDOW,
} from "./constants";
import mapSearchService from "../../../../../../service/MapSearchService";
import { loadingActions } from "../../../../../../components/loader/data/action";
import axios from "axios";
import { AUTH_KEY } from "../../../../../../config";

export const fetchDataByType = (searchBy, query, localBody) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService
      .fetchDataByType(searchBy, query, localBody)
      .then((response) => {
        if (response) {
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        } else {
          dispatch(failure(response));
          dispatch(loadingActions.loadingComplete());
        }
      });
    function request() {
      return { type: FETCHING };
    }
    function success(response) {
      return { type: FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: FETCH_FAILED, error };
    }
  };
};

export const fetchPublicUserData = (userId) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.fetchPublicUserData(userId).then((response) => {
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request() {
      return { type: VALUE_FETCHING };
    }
    function success(response) {
      return { type: VALUE_FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: VALUE_FETCH_FAILED, error };
    }
  };
};

export const fetchDataByValue = (searchBy, query, localBody) => {
  console.log('searchBy',searchBy);
  console.log('query',query);
  console.log('localBody',localBody);
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService
      .fetchDataByValue(searchBy, query, localBody)
      .then((response) => {
        console.log('fetchDataByValue response',response);
        if (response) {
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        } else {
          dispatch(failure(response));
          dispatch(loadingActions.loadingComplete());
        }
      });
    function request() {
      return { type: VALUE_FETCHING };
    }
    function success(response) {
      return { type: VALUE_FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: VALUE_FETCH_FAILED, error };
    }
  };
};
export const fetchDataByFilter = (filterValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.fetchDataByFilter(filterValues).then((response) => {
      console.log("fetchDataByFilter",response);
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request() {
      return { type: VALUE_FETCHING };
    }
    function success(response) {
      return { type: VALUE_FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: VALUE_FETCH_FAILED, error };
    }
  };
};
export const requestLayer = (residentialLayer, sercuValue) => {
  console.log("residentialLayer: ", residentialLayer);
  console.log("residentialLayer: ", residentialLayer.url);
  return async (dispatch) => {
    dispatch(loadingActions.isloading());
    if (residentialLayer.url)
      await axios
        .get(residentialLayer.url.concat("wfs"), {
          params: {
            version: "1.3.0",
            outputFormat: "application/json",
            request: "GetFeature",
            service: "WFS",
            srsName: "EPSG:3857",
            typeName: residentialLayer.layer_name,
            featureID: sercuValue,
            authkey: AUTH_KEY,
          },
        })
        .then((response) => {
          
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        });
  };
  function success(response) {
    return { type: FETCHING_LAYER_SUCCESS, response };
  }
};

export const updateSearchResultLayer = (resultLayer) => {

  return (dispatch) => {
    dispatch(updateSearchResultLayer(resultLayer));
  };
  function updateSearchResultLayer(resultLayer) {
    return { type: UPDATE_SEARCH_LAYER, resultLayer };
  }
};

export const toggleAdvancedFilterWindow = () => {
  return (dispatch) => {
    dispatch(toggleAdvancedFilterWindow());
  };
  function toggleAdvancedFilterWindow() {
    return { type: TOGGLE_ADVANCED_FILTER_WINDOW };
  }
};
