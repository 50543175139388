import React, { Component } from "react";
import DataFormat from "../../helper/DataFormat";
import TableFormat from "./TableFormat";
import { Item } from "react-bootstrap/lib/Breadcrumb";

class RelatedTables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basicDetailStatus: false,
      
    };
  }
  render() {
    const { data } = this.props;
// console.log('related',data);

    return (
      <TableFormat displayName={data.display_name}>
      <tbody className="tablebody">
        {data.fields.map((item, index) => (
          <React.Fragment key={index}>
            {Object.entries(item).map(([key, value]) => {
              if (key === "Id") return null; // Skip rendering if key is "Id"
    
              // Special handling for "Owner post office" key
              if (key === "Owner post office") {
                return (
                  <tr key={key}>
                    <td style={{ width: "300px" }}>Postal code</td>
                    <td style={{ width: "300px" }}>{value}</td>
                  </tr>
                );
              }
    
              // General rendering for other keys
              return (
                <tr key={key}>
                  <td style={{ width: "300px" }}>{key}</td>
                  <td style={{ width: "300px" }}>
                    {!DataFormat.dataFormatOr(value) ? DataFormat.dataFormat(value) : ''}
                  </td>
                </tr>
              );
            })}
          </React.Fragment>
        ))}
      </tbody>
    </TableFormat>
    );
  }
}
export default RelatedTables;
