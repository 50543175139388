import React, { Component } from "react";
import { transform } from "ol/proj.js";
import { connect } from "react-redux";
import {
  OverlayTrigger,
  ButtonToolbar,
  Modal,
  Panel,
  Button,
  Tooltip,
} from "react-bootstrap";
import { Polygon } from "ol/geom.js";
import Draw from "ol/interaction/Draw";
import View from "ol/View";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer.js";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { GeoJSON } from "ol/format.js";
import { unByKey } from "ol/Observable.js";
import getLayerTree from "../../../map/helper/LayerTreeLoader";
import { snackbarActions } from "../../../../../components/snackbar/data/action";
import styleFunction from "../../../map/helper/vectorStyle";
import {
  mapQueryPolygon,
  requestLayer,
  updateSearchResultLayer,
} from "./data/action";
import selectRed from "../../../../../asset/svg/polygon_red.svg";
import ResultModel from "./components/ResultModel";
import Widget from "../../../../../containers/widget/widget";
import "./SelectByPolygon.css";
class SelectByPolygon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawType: null,
      vectorSource: null,
      draw: null,
      sketch: null,
      listener: null,
      layerlist: [],
      geometry: null,
      show: false,
      result: null,
      showResult: false,
      resultVectorSource: null,
      enabled: false,
    };
  }

  componentDidMount() {
    this.setState({
      vectorSource: new VectorSource(),
      resultVectorSource: new VectorSource(),
    });
  }

  handleDrawConrtol = () => {
    const { mapComponent } = this.props;
    const { vectorSource, draw, resultVectorSource } = this.state;
    const enabled = this.state.enabled;
    if (enabled) {
      vectorSource.clear();
      resultVectorSource.clear();
      this.setState({ enabled: false });
      return;
    }

    mapComponent.removeInteraction(draw);
    vectorSource.clear();
    resultVectorSource.clear();
    mapComponent.addLayer(this.getVectorLayer());

    let listener;
    this.setState(
      {
        output: 0.0,
        draw: this.addInteraction(),
        enabled: true,
      },
      () => {
        mapComponent.addInteraction(this.state.draw);
        const { draw } = this.state;
        draw.on("drawstart", (evt) => {
          vectorSource.clear();
          listener = this.handleDrawStart(evt);
        });
        draw.on("drawend", (listener) => {
          this.handleDrawEnd(listener);
        });
      }
    );
  };

  handleDrawStart = (evt) => {
    this.setState({
      sketch: evt.feature,
    });

    return this.state.sketch.getGeometry().on("change", (evt) => {
      let geom = evt.target;
      if (geom instanceof Polygon) {
        // this.setState({
        //   output: this.formatArea(geom),
        // });
      }
    });
  };

  handleDrawEnd = (listener) => {
    this.setState({
      sketch: null,
    });
    var feature = listener.feature;
    var coord = feature
      .getGeometry()
      .clone()
      .transform("EPSG:3857", "EPSG:4326");
    unByKey(listener);
    var res = [];
    var layerdetails = {};
    this.props.mapComponent.removeInteraction(this.state.draw);
    var layers = this.props.mapComponent.values_.layergroup.values_.layers
      .array_[1].values_.layers.array_;
    layers.forEach((element) => {
      if (element.values_.visible) {
        var obj = {};
        let layerTree = getLayerTree();
        layerTree.map((item) => {
          if (item.hasOwnProperty("children")) {
            let res = item.children.filter((layer) => {
              if (layer.label == element.values_.name) {
                obj["model_name"] = layer.model_name;
                obj["field"] = layer.category_field;
                obj["field_value"] = layer.category_id;
              }
            });
          }
        });
        obj["layer_name"] = element.values_.name;
        obj["service_name"] = element.values_.source.params_.LAYERS;
        res.push(obj);
      }
    });
    if (res.length > 0) {
      layerdetails["layer"] = res;
      layerdetails["flatCoordinates"] = coord.flatCoordinates;
      this.setState({
        layerlist: layerdetails,
        geometry: coord,
      });
      this.props.mapQueryPolygon(this.state.layerlist, this.state.geometry);
    } else {
      this.props.showSnackbar("Select Required Layers Before Drawing");
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.response_id != this.props.response_id) {
      const response_id = this.props.response_id;
      if (this.props.response_id.length > 0) {
        var i = 0,
          flag = 0;
        while (i < this.props.response_id.length) {
          response_id.forEach((element) => {
            if (element.count != 0) {
              flag = 1;
              return;
            }
          });
          i++;
        }
        if (flag == 1) {
          this.addSearchResultToMap();
          this.setState(
            {
              result: this.props.response_id,
            },
            () => {
              this.handleShowResult();
            }
          );
        } else {
          this.props.showSnackbar("No Features Found");
        }
      }
    }
  }

  handleShowResult = () => {
    this.setState({
      showResult: !this.state.showResult,
    });
  };

  getInfoAttributes(element) {
    let infoAttributes;
    let layerTree = getLayerTree();
    layerTree.map((item) => {
      if (item.hasOwnProperty("children")) {
        let res = item.children.filter((layer) => {
          if (layer.label == element.layer) {
            infoAttributes = {
              minimalInfoAttributes: layer.minimalInfoAttributes,
              detailedInfoAttributes: layer.detailedInfoAttributes,
            };
          }
        });
      }
    });
    return infoAttributes;
  }

  addSearchResultToMap() {
    const { mapComponent, response_id } = this.props;
    let resultVectorSource = this.state.resultVectorSource;
    response_id.forEach((element) => {
      let infoAttributes = this.getInfoAttributes(element);
      let resultLayer = new VectorLayer({
        name: element.layer,
        infoAttributes: infoAttributes,
        visible: true,
        source: resultVectorSource,
        style: (feature, resolution) => styleFunction(feature, resolution),
      });

      let features = new GeoJSON().readFeatures(element.result);
      resultVectorSource.addFeatures(features);
      mapComponent.addLayer(resultLayer);
    });

    mapComponent.getView().fit(resultVectorSource.getExtent());
  }

  getVectorLayer() {
    return new VectorLayer({
      source: this.state.vectorSource,
      projection: "EPSG:4326",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "red",
          width: 2,
        }),
      }),
    });
  }

  addInteraction() {
    return new Draw({
      source: this.state.vectorSource,
      type: "Polygon",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showResult && (
          <ResultModel
            show={this.state.showResult}
            handleShowResult={this.handleShowResult}
            result={this.state.result}
          />
        )}
        <Widget
          placement="top"
          tooltipText="Select By Polygon"
          handleClick={this.handleDrawConrtol}
          img={selectRed}
          class="selectButton"
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.mapQuery.fetching,
    data: state.mapQuery.data,
    mapComponent: state.mapReducer.OlMap,
    response_id: state.mapQuery.response_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mapQueryPolygon: (layerlist, geometry) =>
      dispatch(mapQueryPolygon(layerlist, geometry)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectByPolygon);
