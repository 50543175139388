import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Row, Col, Tab, Tabs } from "react-bootstrap";
import "../style.css";
import DashboardLayout from "../DashboardLayout";
import { getDashboardFilters, getWards } from "../data/action";
import MemberDetails from "./MemberDetails";
import LocalStorageHandler from "../../../util/storage";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.isFiltersLoaded) this.props.getDashboardFilters();
    if (!this.props.isWardsLoaded)
      this.props.getWards(LocalStorageHandler.getLocalBody().local_body_id);
  }

  handleRedirect = (link) => {
    this.props.history.push(link);
  };

  render() {
    return (
      <DashboardLayout handleRedirect={this.handleRedirect}>
        <Grid style={{ width: "100%" }}>
          <Row className="show-grid">
            <Col xs={12} md={12} lg={12}>
              <h2 className="dashboard-heading">Socio Economic</h2>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                <Tab eventKey={1} title="Member Details">
                  <MemberDetails downloadAction={this.props.downloadAction} />
                </Tab>
                {/* <Tab eventKey={2} title="Member Details">
                  Building details
                </Tab> */}
              </Tabs>
            </Col>
          </Row>
        </Grid>
      </DashboardLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    filters: state.dashboard.filters,
    isFiltersLoaded: state.dashboard.isFiltersLoaded,
    wards: state.dashboard.wards,
    isWardsLoaded: state.dashboard.isWardsLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWards: (localBodyId) => dispatch(getWards(localBodyId)),
    getDashboardFilters: () => dispatch(getDashboardFilters()),
    downloadAction: (data) => dispatch(downloadAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
