import React, { Component, useState } from "react";
import { connect } from "react-redux";
import "./MapHeader.css";
import { Jumbotron, Collapse } from "react-bootstrap";
import { doLogout } from "../../user/login/data/actions";
import { toggleAdvancedFilterWindow } from "../map_header/navigation/navbar_form/data/action";
import NavBarFormNew from "./navigation/navbar_form/NavBarForm";
import AdvancedFilter from "../map_header/advanced_filter/AdvancedFilter";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class MapHeaderNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMapHeader: true,
    };
  }

  render() {
    return (
      <div>
        <div>
          <React.Fragment>
            <Collapse in={this.state.showMapHeader}>
              <Jumbotron className="jumboBox">
                <div className="title">DRISHTI </div>
                <div>
                  <NavBarFormNew />
                </div>

                <AdvancedFilter />
              </Jumbotron>
            </Collapse>
          </React.Fragment>
        </div>
        <div
          onMouseOver={(e) => this.onHover(e, true)}
          onMouseOut={(e) => this.onHover(e, false)}
          onClick={(e) => this.onSearchClick(e, true)}
        >
          <React.Fragment>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="menu">
                  {this.state.showMapHeader ? "Hide search" : "Search"}
                </Tooltip>
              }
            >
              <Jumbotron
                className="view-search-button"
                style={{ background: this.state.hover ? "#ca2208" : "white" }}
              >
                <div className="left-right-svg-div">
                  <svg
                    viewBox="0 0 50 50"
                    fill={this.state.hover ? "white" : "#ca2208"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {this.state.showMapHeader ? (
                      <polygon points="33 0, 17 15, 33 30" />
                    ) : (
                      <polygon points="17 0, 33 15, 17 30" />
                    )}
                  </svg>
                </div>
              </Jumbotron>
            </OverlayTrigger>
          </React.Fragment>
        </div>
      </div>
    );
  }
  onSearchClick = (e, val) => {
    e.preventDefault();
    this.setState({ showMapHeader: !this.state.showMapHeader });
  };
  onHover = (e, val) => {
    e.preventDefault();
    this.setState({ hover: val });
  };
}

function mapStateToProps(state) {
  return {
    show: state.mapSearch.showAdvancedFilterWindow,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doLogout: () => dispatch(doLogout()),
    toggleAdvancedFilterWindow: () => dispatch(toggleAdvancedFilterWindow()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapHeaderNew);
