import React, { Component } from "react";
import { connect } from "react-redux";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { WFS, GeoJSON } from "ol/format.js";
import { Vector as VectorLayer } from "ol/layer.js";
import VectorSource from "ol/source/Vector.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./NavBarform.css";
import mapDefaults from "../../../map/helper/mapDefaults";
import filter from "../../images/filter.png";
import {
  fetchDataByType,
  fetchDataByValue,
  updateSearchResultLayer,
  requestLayer,
  toggleAdvancedFilterWindow,
} from "./data/action";
import { snackbarActions } from "../../../../snackbar/data/action";
import getLayerTree from "../../../map/helper/LayerTreeLoader";
import styleFunction from "../../../map/helper/vectorStyle";
import MenuItem from "../navbar_menus/MenuItem";
import LocalStorageHandler from "../../../../../util/storage";

class NavBarFormNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBy: null,
      searchValue: null,
      options: [],
      startsWith: [],
      isLoading: false,
      layerToSearch: {},
      isMember: null,
      wardValue: null,
      searchValueDisabled: null,
      showClose: false,
      vectorSource: null,
    };
    this.myRef = React.createRef();
  }
  componentDidMount() {
    this.setState({
      vectorSource: new VectorSource(),
    });
    if (LocalStorageHandler.getLocalBody().ward_no !== null) {
      this.setState({
        wardValue: LocalStorageHandler.getLocalBody().ward_no,
        layerToSearch: this.getLayerToSearch(),
        searchValue: null,
        isMember: true,
      });
    } else {
      this.setState({
        layerToSearch: this.getLayerToSearch(),
        searchValue: null,
        isMember: false,
      });
    }
  }

  onSearchBySelect = (evt) => {
    this.state.searchValueDisabled = false;
    this.state.vectorSource.clear();
    this.setState({
      searchValue: null,
      startsWith: [],
    });
    const instance = this._typeahead.getInstance();
    instance.clear();
    // instance.focus()
    if (!this.state.isMember || evt.target.text != "WARD") {
      this.props.fetchDataByType(
        mapDefaults.getSearchAttributes(evt.target.text),
        "d",
        this.props.isDistrictUser || this.props.isStateUser
          ? this.props.selectedLocalbody.id
          : "0"
      );
    } else {
      this.state.searchValueDisabled = true;

      this.props.fetchDataByValue(
        "ward",
        this.state.wardValue,
        (this.props.isDistrictUser || this.props.isStateUser) &&
          this.props.selectedLocalbody
          ? this.props.selectedLocalbody.id
          : "0"
      );
    }
    this.setState({
      searchBy: evt.target.text,
      layerToSearch: this.getLayerToSearch(evt.target.text),
      searchValue: null,
    });
  };

  handleSearchValueChange = (evt) => {
    this.setState({ searchValue: evt.length === 0 ? null : evt[0] }, () => {
      const { layerToSearch, searchValue } = this.state;
      let searchBy = mapDefaults.getSearchAttributes(this.state.searchBy);
      this.props.fetchDataByValue(searchBy, searchValue);
    });
  };
  getLayerToSearch = (searchBy) => {
    let layerToSearch = null;
    let layerTree = getLayerTree();
    layerTree.map((item) => {
      if (item.hasOwnProperty("children")) {
        let res = item.children.filter((layer) => {
          var layer_name = layer.layer_name.split(":");
          if (searchBy == "ROAD NAME") return layer_name[1] === "utility_road";
          else return layer_name[1] === "buildingasset_property";
        });
        if (res.length > 0) layerToSearch = res[0];
      }
    });
    //
    return layerToSearch;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data != this.props.data) {
      this.setState({
        isLoading: false,
        options: this.props.data,
      });
    }
    if (prevProps.feature_data != this.props.feature_data) {
      if (this.props.feature_data.features.length > 0) {
        this.addSearchResultToMap();
      }
    }

    if (prevProps.resultLayer != this.props.resultLayer) {
      this.props.mapComponent.removeLayer(prevProps.resultLayer);
    }
  }

  handleClose = (evt) => {
    this.state.vectorSource.clear();
    this.setState({
      searchValue: null,
      startsWith: [],
    });
    const instance = this._typeahead.getInstance();
    instance.clear();
  };

  handleOnInputChange(text) {
    let startsWith = [],
      sortedList = [],
      dict2 = {},
      finalList = [];
    const options = this.state.options;
    startsWith = options.filter((item) =>
      item.toLowerCase().startsWith(text.toLowerCase())
    );
    var collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });
    startsWith = startsWith.sort(collator.compare);
    startsWith.forEach(function(item) {
      dict2[item] = true;
    });
    var result = options.reduce(function(prev, current) {
      if (dict2.hasOwnProperty(current) === false) {
        prev.push(current);
      }
      return prev;
    }, []);
    finalList = startsWith.concat(result);
    this.setState({ startsWith: finalList });
  }

  addSearchResultToMap() {
    const { mapComponent, feature_data } = this.props;
    console.log('feature_data',feature_data);
    const layerToSearch = this.state.layerToSearch;
    let infoAttributes = {
      minimalInfoAttributes: layerToSearch.minimalInfoAttributes,
      detailedInfoAttributes: layerToSearch.detailedInfoAttributes,
    };
    const vectorSource = this.state.vectorSource;
    vectorSource.clear();
    let resultLayer = new VectorLayer({
      name: layerToSearch.value,
      infoAttributes: infoAttributes,
      visible: true,
      source: vectorSource,
      style: (feature, resolution) => styleFunction(feature, resolution),
    });

    let features = new GeoJSON().readFeatures(feature_data);
    vectorSource.addFeatures(features);
    this.props.updateSearchResultLayer(resultLayer);
    mapComponent.addLayer(resultLayer);
    mapComponent.getView().fit(vectorSource.getExtent());
    if (mapComponent.getView().getZoom() > 20)
      mapComponent.getView().setZoom(20);
  }

  render() {
    let { isMember } = this.state;

    const renderAdvanceSearch = () => {
      if (!isMember) {
        return (
          <div className="input-group-btn">
            <MenuItem
              img={filter}
              handleClick={this.props.toggleAdvancedFilterWindow}
              tooltipText="Advanced Search"
              key="Advanced Search"
            />
          </div>
        );
      } else {
        return <></>;
      }
    };

    return (
      <div className="qsearch">
        <div className="input-group input-group">
          <div className="input-group-btn">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="menu">Select one item for search</Tooltip>}
            >
              <button
                type="button"
                className="btn dropdown-toggle"
                id="navBarFormButton"
                data-toggle="dropdown"
                style={{
                  borderRadius: "20px 0px 0px 20px",
                  backgroundColor: "white",
                  color: "grey",
                  height: "35px",
                }}
              >
                {this.state.searchBy == null
                  ? "Search By"
                  : this.state.searchBy}
                <span
                  className="fa fa-caret-down"
                  style={{ paddingLeft: "10px" }}
                />
              </button>
            </OverlayTrigger>
            <ul className="dropdown-menu">
              {mapDefaults.searchFields().map((item, index) => {
                return (
                  <li key={index} onClick={this.onSearchBySelect}>
                    <a>{item}</a>
                  </li>
                );
              })}
            </ul>
          </div>
          <Typeahead
            id="basic-typeahead-single"
            labelKey="name"
            onChange={this.handleSearchValueChange}
            disabled={this.state.searchValueDisabled}
            ref={(ref) => (this._typeahead = ref)}
            options={this.state.startsWith}
            onInputChange={(text) => this.handleOnInputChange(text)}
            placeholder="Enter your search term here..."
            // selected={this.state.searchValue}
          />
          <div className="input-group-btn">
            <button
              className="btn navBarForm"
              id="navBarFormButton"
              onClick={() => this.handleClose()}
              style={{
                borderRadius: "0px 20px 20px 0px",
                backgroundColor: "white",
                color: "white",
                height: "35px",
              }}
            >
              <i
                className="glyphicon glyphicon-remove black"
                style={{ marginLeft: "10px" }}
              />
            </button>
          </div>
          {LocalStorageHandler.getUserDetails().user_type !== 6 &&
            renderAdvanceSearch()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.mapSearch.fetching,
    data: state.mapSearch.data,
    feature_data: state.mapSearch.feature_data,
    layerData: state.mapSearch.layerData,
    resultLayer: state.mapSearch.resultLayer,
    mapComponent: state.mapReducer.OlMap,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDataByType: (searchBy, query) =>
      dispatch(fetchDataByType(searchBy, query)),
    fetchDataByValue: (searchBy, query) =>
      dispatch(fetchDataByValue(searchBy, query)),
    requestLayer: (layerToSearch, featureRequest) =>
      dispatch(requestLayer(layerToSearch, featureRequest)),
    updateSearchResultLayer: (searchLayer) =>
      dispatch(updateSearchResultLayer(searchLayer)),
    toggleAdvancedFilterWindow: () => dispatch(toggleAdvancedFilterWindow()),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarFormNew);
