import React, { Component } from "react";
import "./GetCount.css";
import getCount from "../../../../../asset/svg/count_red.svg";
import GetCountPanel from "./GetCountPanel";
import localStorageHandler from "../../../../../util/storage";
import Widget from "../../../../../containers/widget/widget";

class GetCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGetCountPanelControl: false,
      visibleFlag: false,
    };
  }
  handleShowGetCountPanelControl = () => {
    this.setState({
      showGetCountPanelControl: !this.state.showGetCountPanelControl,
    });
  };

  isVisible = () => {
    const userMenu = localStorageHandler.getMenu();
    let visibleFlag = false;
    if (userMenu != null) {
      let currentItem = userMenu.filter((menu) => menu.menu_name == "Count");
      if (currentItem.length > 0) {
        visibleFlag = currentItem[0].permission[0].view_permission;
      }
    }
    return visibleFlag;
  };

  render() {
    const visibility = this.isVisible();
    return (
      <React.Fragment>
        {visibility > 0 && (
          <Widget
            placement="top"
            tooltipText="Get Count"
            handleClick={this.handleShowGetCountPanelControl}
            img={getCount}
            class="countButton"
          />
        )}
        <GetCountPanel
          showGetCountPanelControl={this.state.showGetCountPanelControl}
          handleShowGetCountPanelControl={this.handleShowGetCountPanelControl}
        />
      </React.Fragment>
    );
  }
}

export default GetCount;
