import {
    FETCHINGDATA,
    FETCHDATA_FAILED,
    FETCHDATA_SUCCESS,
    
  } from "./constants";
  import mapSearchService from "../../../../../../service/MapSearchService";
  import { loadingActions } from "../../../../../../components/loader/data/action";
  import axios from "axios";
  
  export const mapQueryPolygon = (layerlist, geometry) => {
    return (dispatch) => {
      dispatch(request());
      dispatch(loadingActions.isloading());
      mapSearchService.mapQueryPolygon(layerlist, geometry).then((response) => {
        if (response) {
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        } else {
          dispatch(failure(response));
          dispatch(loadingActions.loadingComplete());
        }
      });
      function request() {
        return { type: FETCHINGDATA };
      }
      function success(response) {
        return { type: FETCHDATA_SUCCESS, response };
      }
      function failure(error) {
        return { type: FETCHDATA_FAILED, error };
      }
    };
  };
  
  
  
  