import React, { Component } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "./styles.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoDataImg from "../../../asset/png/no-results.png";

export default class SavedTemplate extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Saved Templates
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.savedTemplate.length === 0 && !this.props.fetching && (
            <div>
              <img src={NoDataImg} width="100" className="center" />
              <p className="noDataTxt">No data found</p>
            </div>
          )}
          <Accordion allowMultipleExpanded={false}>
            {this.props.savedTemplate &&
              this.props.savedTemplate.map((el, i) => (
                <AccordionItem key={el.id}>
                  <div
                    className="card scale-up-center"
                    style={{ padding: "10px" }}
                  >
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton>
                            <div>
                              <p
                                style={{
                                  color: "	#71797E",
                                  fontWeight: "bold",
                                  fontSize: 16,
                                  textTransform: "capitalize",
                                }}
                              >
                                <i
                                  className="fa fa-file"
                                  aria-hidden="true"
                                ></i>{" "}
                                {el.file_name && el.file_name}
                              </p>
                            </div>
                            <div>
                              <p
                                style={{
                                  color: "	#71797E",
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  textTransform: "capitalize",
                                }}
                              >
                                {el.field_values &&
                                  el.field_values.layerLabel &&
                                  el.field_values.layerLabel}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <p
                                style={{
                                  color: "	#71797E",
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  textTransform: "capitalize",
                                }}
                              >
                                {el.field_values && el.field_values.layerName}
                              </p>

                              <p
                                style={{ marginLeft: "auto", marginRight: "0" }}
                              >
                                {" "}
                                {!expanded && "Show more ⌄"}
                              </p>
                            </div>

                            {!expanded && (
                              <div
                                style={{
                                  border: "2px solid #ca2208",
                                  opacity: "0.4",
                                }}
                              ></div>
                            )}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel region={true}>
                      {el.query_values &&
                        el.query_values.obj &&
                        el.query_values.obj.length > 0 && (
                          <table className="table table-striped">
                            <thead
                              style={{
                                backgroundColor: " #ca2208",
                                opacity: "1",
                              }}
                            >
                              <tr>
                                <th scope="col" style={{ color: "#FFFFFF" }}>
                                  #
                                </th>
                                <th scope="col" style={{ color: "#FFFFFF" }}>
                                  Attribute
                                </th>
                                <th scope="col" style={{ color: "#FFFFFF" }}>
                                  Operation
                                </th>
                                <th scope="col" style={{ color: "#FFFFFF" }}>
                                  Value
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {el.query_values &&
                                el.query_values.obj &&
                                el.query_values.obj.map((value, i) => (
                                  <React.Fragment key={value.join_op + i}>
                                    {value.join_op && (
                                      <tr>
                                        <td
                                          colSpan={4}
                                          style={{
                                            width: "100%",
                                            textAlign: "center",

                                            color: "	#71797E",
                                            fontWeight: "bold",
                                            fontSize: 14,
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {value.join_op}
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      <th scope="row">{i + 1}</th>
                                      <td>{value.display_name}</td>
                                      <td>{value.op}</td>
                                      <td>{value.value}</td>
                                    </tr>

                                    {/* <div>and</div> */}
                                  </React.Fragment>
                                ))}
                            </tbody>
                          </table>
                        )}

                      <div
                        style={{
                          border: "1px solid #ca2208",
                          opacity: "0.4",
                          marginTop: "10px",
                        }}
                      />

                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: " flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          onClick={() => this.props.deleteTemplate(i, el)}
                        >
                          Delete
                        </Button>

                        <Button
                          style={{
                            marginLeft: "15px",
                            backgroundColor: "#ca2208",
                            color: "#FFFFFF",
                          }}
                          onClick={() => this.props.getResult(el)}
                        >
                          Get Result
                        </Button>
                      </div>
                    </AccordionItemPanel>
                  </div>
                </AccordionItem>
              ))}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
