import React, { Component } from "react";
import { connect } from "react-redux";
import {
  NavItem,
  Navbar,
  NavDropdown,
  MenuItem,
  Nav,
  Panel,
} from "react-bootstrap";
import "./style.css";
import Loader from "../loader/Loader";
import Snackbar from "../snackbar";
import { doLogout } from "../user/login/data/actions";
import LocalStorageHandler from "../../util/storage";
import { ADMIN_URL } from "../../config";
import { NavLink } from "react-router-dom";
import {
  isConfigloading,
  configLoadingComplete,
} from "../dashboard/data/action";

class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleAdminModule = () => {
    const token = LocalStorageHandler.getToken();
    window.open(ADMIN_URL + `map/login/` + token, "_blank");
  };

  isLoadMap = () => {
    const { isConfigLoading } = this.props;
    const combinedDetails = JSON.parse(localStorage.getItem("basicDetails"));
    const layerCategory =
      combinedDetails?.basicDetails?.localBody?.layerCategory;
    return isConfigLoading || !layerCategory;
  };

  render() {
    return (
      <>
        <Loader />
        <Snackbar />
        <Navbar inverse collapseOnSelect className="navbar-class">
          <Navbar.Header>
            <Navbar.Brand>
              <a href="#brand">DRISHTI</a>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavItem eventKey={1}>
                <NavLink to="/dashboard" style={{ color: "#ffffff" }}>
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem eventKey={1}>
              <NavLink to="/analysis" style={{ color: "#ffffff" }}>
              Analysis
              </NavLink>
            </NavItem>
              <NavDropdown
                eventKey={3}
                title="More"
                id="basic-nav-dropdown"
                style={{ color: "#ffffff" }}
                className="nav-drop"
              >
                <MenuItem componentClass="span" eventKey={3.1}>
                  <NavLink to="/socioeconomic" style={{ color: "#2b2c2b" }}>
                    Socio-economic
                  </NavLink>
                </MenuItem>
              </NavDropdown>
              <NavItem
                componentClass="span"
                eventKey={2}
                disabled={this.isLoadMap()}
                className="nav-map"
              >
                <NavLink to="/map" style={{ color: "#ffffff" }}>
                  Map Viewer
                </NavLink>
              </NavItem>
              <NavItem eventKey={1}>
                <NavLink to="/report" style={{ color: "#ffffff" }}>
                  Report
                </NavLink>
              </NavItem>
              <NavItem
                eventKey={2}
                style={{ color: "#ffffff" }}
                onClick={() => this.handleAdminModule()}
              >
                Admin Module
              </NavItem>
            </Nav>
            <Nav pullRight>
              <NavItem eventKey={1} onClick={this.props.doLogout}>
                Logout
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Panel className="container-panel">
          <Panel.Body>{this.props.children}</Panel.Body>
        </Panel>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isConfigLoading: state.dashboard.isConfigLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doLogout: () => dispatch(doLogout()),
    isConfigloading: () => dispatch(isConfigloading()),
    configLoadingComplete: () => dispatch(configLoadingComplete()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
