import React, { Component } from "react";
// Resolved Super Expression issue with the enhanced package
import CheckboxTree from "react-checkbox-tree-enhanced";
import "react-checkbox-tree-enhanced/lib/react-checkbox-tree.css";
import OverlayPanel from "../overlay_panel/OverlayPanel";
import getLayerTree from "../../helper/LayerTreeLoader";
import { connect } from "react-redux";
import "./LayerTree.css";

class LayerTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      expanded: [],
    };
  }
  getMapLayerTree() {
    let layerTree = getLayerTree();
    // console.log('layertree',layerTree);
    let newLayerTree = [];
    layerTree.map((treeItem, i) => {
      let j = treeItem.layer_category_order - 1;
      newLayerTree[j] = layerTree[i];
    });
    newLayerTree.map((treeItem, i) => {
      let children = [];
      if (treeItem.hasOwnProperty("children")) {
        if (treeItem.children.length > 1) {
          treeItem.children.map((layer, i) => {
            let j = layer.layer_order - 1;
            children[j] = treeItem.children[i];
          });
          treeItem.children = children;
        }
      }
    });
    let mapLayerTree = [];
    newLayerTree.map((treeItem, i) => {
      let isBaseLayer = true;
      if (treeItem.hasOwnProperty("children")) {
        if (treeItem.children.length > 0) {
          treeItem.children.forEach((layer) => {
            if (layer.layer_type != "layer") {
              isBaseLayer = false;
            }
          });
          if (isBaseLayer) {
            mapLayerTree.push(newLayerTree[i]);
          }
        }
      }
    });
    // console.log('maplayer',mapLayerTree);
    return mapLayerTree;
  }

  showSelectedLayer(checkedLayerName) {
    const { mapLayers } = this.props;
    // console.log('checkedLayerName',mapLayers);
    for (let i = 0; i < mapLayers.length; i++) {
      let layer = mapLayers[i].getProperties().name;
      if (checkedLayerName.includes(layer)) {
        mapLayers[i].setVisible(true);
      } else {
        mapLayers[i].setVisible(false);
      }
    }
    this.setState({
      checked: checkedLayerName,
    });
    // console.log('checked',this.state.checked);
  }
  onExpand(expanded) {
    this.setState({ expanded });
  }
  handleUnCheckLayer = () => {
    const { mapLayers } = this.props;
    for (let i = 0; i < mapLayers.length; i++) {
      mapLayers[i].setVisible(false);
    }
    this.setState({
      checked: [],
    });
  };

  render() {
    return (
      <React.Fragment>
        <OverlayPanel
          showControl={this.props.showLayerControl}
          handleShowControl={this.props.handleShowControl}
          titleName="Layers"
          handleUnCheckLayer={this.handleUnCheckLayer}
          layerCheck={true}
        >
          <CheckboxTree
            className="layerTree"
            nodes={this.getMapLayerTree()}
            expandOnClick
            checked={this.state.checked}
            expanded={this.state.expanded}
            onCheck={(checked) => this.showSelectedLayer(checked)}
            onExpand={(expanded) => this.setState({ expanded })}
          />
        </OverlayPanel>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
  };
}

export default connect(mapStateToProps, {})(LayerTree);
